import React, { useEffect, useState, Fragment, useRef} from "react";
import Layout from "../core/Layout";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from 'axios';
import "./ListMedicine.css";
import Pagination from "react-js-pagination";
import firebase from 'firebase/compat/app';
import Loader from "../frontpage/Loader";

const ListMedicine = () => {
  
  const deleteHandler = async (medicineId) => {
    console.log(medicineId);
    if (window.confirm("Are you sure you want to delete this Medicine?")) {
      try {
        await axios.delete(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/delete-medicine/${medicineId}/${hospitalId}`);
       
        setMedicine(medicine.filter(med => med.medicineId !== medicineId));
  
      } catch (error) {
        console.error("Error deleting vendor:", error);
      }
    }
  };

  const [hospitalId, setHospitalId] = useState(null);
  const [hospitalName, setHospitalName] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const currentUser = firebase.auth().currentUser;

      if (currentUser) {
        const userId = currentUser.uid;

        try {
          const response = await axios.get('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getUserDataHospital', {
            params: { userId }
          });

          setHospitalId(response.data.HospitalId);
          setHospitalName(response.data.HospitalName);
        
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, []);


  const [medicine, setMedicine] = useState([]);

  useEffect(()=>{
    const fetchMedicine = async()=>{
      if(hospitalId) {
        console.log("hospitalId:", hospitalId);
      try{
        const response = await axios.get("https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/get-medicine",{
          params: { hospitalId }
        });
        setMedicine(response.data);
      } catch (error) {
        console.error('Error fetching medicine:',error);
      }
    }
    };
    fetchMedicine();
  },[hospitalId]);

  const formatDate = (dateStr) => {
    let date = moment(dateStr, ["DD/MM/YYYY", "MM/DD/YYYY", "YYYY-MM-DD"]);
    return date.isValid() ? date.format("DD-MM-YYYY") : "Invalid date";
  };

  // const handleTriggerEmail = async () => {
  //   try {
  //     const response = await axios.post('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/send-email', {
  //       defaultemail: 'karan@datadna.in',
  //       name: 'XXXX',
  //       date: new Date(),
  //     });
  //     alert('Email sent successfully');
  //   } catch (error) {
  //     console.error('Error sending email:', error.message);
  //     alert('Failed to send email');
  //   }
  // };
  const tblContentRef = useRef(null);
  const tblHeaderRef = useRef(null);
  const [scrollWidth, setScrollWidth] = useState(0);

  const updateScrollWidth = () => {
    if (tblContentRef.current && tblHeaderRef.current) {
      const contentWidth = tblContentRef.current.offsetWidth;
      const tableWidth = tblContentRef.current.querySelector('table').offsetWidth;
      setScrollWidth(contentWidth - tableWidth);
    }
  };

  useEffect(() => {
    window.addEventListener('load', updateScrollWidth);
    window.addEventListener('resize', updateScrollWidth);

    // Initial call to set padding right
    updateScrollWidth();

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('load', updateScrollWidth);
      window.removeEventListener('resize', updateScrollWidth);
    };
  }, []);

  useEffect(() => {
    if (tblHeaderRef.current) {
      tblHeaderRef.current.style.paddingRight = `${scrollWidth}px`;
    }
  }, [scrollWidth]);

  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 10;
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredMedicines = medicine.filter(med => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const idMatch = med.medicineId ? med.medicineId.toLowerCase().includes(lowercasedSearchTerm) : false;
    const nameMatch = med.MedicineName ? med.MedicineName.toLowerCase().includes(lowercasedSearchTerm) : false;
    const genericNameMatch = med.GenericName ? med.GenericName.toLowerCase().includes(lowercasedSearchTerm) : false;
    return idMatch || nameMatch || genericNameMatch;
  });


  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredMedicines.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <Layout
      title="Profile"
      description="List of medicines"
      className="container-fluid"
    >
      <Loader/>
      <h1 className="mt-4 color-bright">Medicine Management</h1>
     <div className="button-container">
      <h4>
        <Link to="/add-medicine" className="btn btn-outline-primary">
          Add Medicine
        </Link>
      </h4>

      {/* <button onClick={handleTriggerEmail} className="btn btn-outline-secondary">
        Trigger Email
      </button> */}
      </div>
      <div className="form-group col-md-4">
        <label htmlFor="searchTerm">Search by Medicine ID, Name, or Generic Name</label>
        <input
          type="text"
          id="searchTerm"
          className="form-control input-shadow"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>

      <section>
        
        <div class="tbl-header table-responsive">
          <table id="listPatients" cellpadding="0" cellspacing="0" border="0">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Name</th>
                <th scope="col">Generic Name</th>
                <th scope="col">Batch No</th>
                <th scope="col">Quantity</th>
                <th scope="col">Type</th>
                <th scope="col">Man Date</th>
                <th scope="col">Expiry Date</th>
                <th scope="col">Cost</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
           
           
            <tbody>
            {currentItems.length === 0 ? (
                <tr>
                  <td className="text-center" colSpan="11">
                    No Data
                  </td>
                </tr>
              ) : (
                currentItems.map((med, i) => (
                  <tr key={i}>
                    <Fragment>
                      <th scope="row">{med.medicineId}</th>
                      <td>{med.MedicineName}</td>
                      <td>{med.GenericName}</td>
                      <td>{med.BatchNo}</td>
                      <td>{med.Quantity}</td>
                      <td>{med.Type}</td>
                      <td>{formatDate(med.ManufactureDate)}</td>
                      <td>{formatDate(med.ExpiryDate)}</td>
                      <td>{med.Price}</td>
                      <td>
                        <Link to={`/update-medicine/${med.medicineId}`}>
                          <i className="bi bi-pencil-square" />
                        </Link>
                      </td>
                      <td>
                        <i
                          className="bi bi-trash"
                          onClick={() => deleteHandler(med.medicineId)}
                        />
                      </td>
                    </Fragment>
                  </tr>
                ))
              )}
            </tbody>
            
          </table>
          </div>
         
        
      
      </section>
      <Pagination
        activePage={activePage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={medicine.length}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
        itemClass="page-item"
        linkClass="page-link"
        />
    </Layout>
  );
};


export default ListMedicine;
