import React, { useState } from "react";
import Layout from "../core/Layout";
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import axios from "axios";
import Loader from "../frontpage/Loader";

const firebaseConfig = {
  apiKey: "AIzaSyCI59gnHA9AZAANjoy082vht3fCgatchEg",
  authDomain: "hospital-management-syst-7e35d.firebaseapp.com",
  databaseURL: "https://hospital-management-syst-7e35d-default-rtdb.firebaseio.com",
  projectId: "hospital-management-syst-7e35d",
  storageBucket: "hospital-management-syst-7e35d.firebasestorage.app",
  messagingSenderId: "59517358381",
  appId: "1:59517358381:web:96f502e3832ddf1a8fc3a4",
  measurementId: "G-EYLWMBT9SP"
};

firebase.initializeApp(firebaseConfig);

const AddVaccineCat = () => {
  const [Name, setName] = useState("");
  const [Type, setType] = useState("");
  const [Description, setDescription] = useState("");
  const [Vaccine, setVaccine] = useState("");
  const [Effects, setEffects] = useState("");
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const types = ["mRNA", "Inactivated", "Live-attenuated"];
  const medicines = [
    { _id: "1", name: "Pfizer" },
    { _id: "2", name: "Moderna" },
    { _id: "3", name: "AstraZeneca" },
  ];

  const submitHandler = async (e) => {
    e.preventDefault();
    
    const formData={
      Name,
      Type,
      Description,
      Vaccine,
      Effects
    };
    try{
      const response = await fetch("https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/Vaccine-submit",{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      if(response.ok) {
        setSuccessMessage(result.message);
        setErrorMessage('');
      }else{
        throw new Error(result.error);
      }
    }catch(error) {
      console.error('Error submitting form:',error);
      setSuccessMessage('');
      setErrorMessage('Error submitting form:');
    }
  };

  const AddVaccineCatForm = () => (
    <form onSubmit={submitHandler}>
      <div className="form-row">
        <div className="col-md-2">
          <div className="form-group ">
            <label htmlFor="inputAddress">Name</label>
            <input
              type="text"
              className="form-control input-shadow"
              placeholder="Name"
              value={Name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-2">
          <div className="form-group">
            <label htmlFor="exampleFormControlSelect1">Type</label>
            <select
              onChange={(e) => setType(e.target.value)}
              className="form-control styled-select"
              id="exampleFormControlSelect1"
            >
              <option>Select Type</option>
              {types.map((c, i) => (
                <option key={i} value={c}>
                  {c}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="exampleFormControlTextarea1">Description</label>
            <textarea
              className="form-control input-bg-light-grey input-shadow3"
              id="exampleFormControlTextarea1"
              value={Description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="write description"
              rows="3"
            />
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-2">
          <div className="form-group styled-select">
            <label className="text-muted">Vaccine</label>
            <select
              onChange={(e) => setVaccine(e.target.value)}
              className="form-control styled-select"
            >
              <option>Select Vaccine</option>
              {medicines.map((c, i) => (
                <option key={i} value={c._id}>
                  {c.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="exampleFormControlTextarea1">Effects</label>
            <textarea
              className="form-control input-bg-light-grey input-shadow3"
              id="exampleFormControlTextarea1"
              value={Effects}
              onChange={(e) => setEffects(e.target.value)}
              placeholder="write effects"
              rows="3"
            />
          </div>
        </div>
      </div>

      <div className="col-md-2">
        <div className="form-group mt-4 mb-0">
          <button className="btn btn-primary btn-block">
            Add Vaccine Category
          </button>
        </div>
      </div>
      {successMessage && (
      <div className="alert alert-success mt-3" role="alert">
        {successMessage}
      </div>
    )}
    {errorMessage && (
      <div className="alert alert-danger mt-3" role="alert">
        {errorMessage}
      </div>
    )}
    </form>
     
    
  );

  return (
    <Layout title="Category treatment Form">
    <Loader/>
      <>
        <h2 className="mb-4">Add Vaccine Category</h2>
        {AddVaccineCatForm()}
      </>
    </Layout>
  );
};

export default AddVaccineCat;