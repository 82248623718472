import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import { format } from 'date-fns';
import axios from 'axios';
import Select from 'react-select';
import Loader from "../frontpage/Loader";
import { isValid } from 'date-fns';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCI59gnHA9AZAANjoy082vht3fCgatchEg",
  authDomain: "hospital-management-syst-7e35d.firebaseapp.com",
  databaseURL: "https://hospital-management-syst-7e35d-default-rtdb.firebaseio.com",
  projectId: "hospital-management-syst-7e35d",
  storageBucket: "hospital-management-syst-7e35d.firebasestorage.app",
  messagingSenderId: "59517358381",
  appId: "1:59517358381:web:96f502e3832ddf1a8fc3a4",
  measurementId: "G-EYLWMBT9SP"
};

firebase.initializeApp(firebaseConfig);
const db = firebase.database();

const customStyles = {
  control: (base) => ({
    ...base,
    fontSize: '12px',
  }),
  option: (base) => ({
    ...base,
    fontSize: '12px',
    color: 'black',
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: '14px',
    color: 'black',
  }),
};

const Appointment = () => {
  const [timeOptions, setTimeOptions] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');

  const [formData, setFormData] = useState({
    Appointmenttab_id: 0,
    Appointmenttab_name: '',
    Appointmenttab_email: '',
    Appointmenttab_date: new Date(),
    Appointmenttab_time: '',
    Appointmenttab_doctorname: '',
    Appointmenttab_phone: ''
  });

  const [doctorName, setDoctorName] = useState(null);
  const [patientName, setPatientName] = useState(null);
  const [startDate, setStartDate] = useState(new Date());

  const handleDoctorNameChange = (selectedOption) => {
    setDoctorName(selectedOption);
    setFormData({ ...formData, Appointmenttab_doctorname: selectedOption.value });
  };

  const handlePatientNameChange = (selectedOption) => {
    setPatientName(selectedOption);
    const selectedPatient = patientData.find(patient => patient.Name === selectedOption.value);
    if (selectedPatient) {
      setFormData({ 
        ...formData, 
        Appointmenttab_name: selectedOption.value,
        Appointmenttab_email: selectedPatient.Email,
        Appointmenttab_phone: selectedPatient.PhoneNo 
      });
    }
  };

  const handleTimeChange = (e) => {
    setFormData({ ...formData, Appointmenttab_time: e.target.value });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    setFormData({ ...formData, Appointmenttab_date: date });
  };

  
  const[formData1, setFormData1]= useState({
    userId:"",
    HospitalName:"",
  })

  const[HospitalName,setHospitalName]=useState("");

  useEffect(()=>{
    const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;

        setFormData1((prevData) => ({
          ...prevData,
          userId: userId,
          HospitalName: HospitalName,
        }));

        try {
          const response = await axios.get(
            `https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getHospitalName/${userId}`
          );
          const addressData = response.data;
          setFormData1((prevData) => ({
            ...prevData,
            HospitalName: addressData.HospitalName || " ",
            HospitalId: addressData.HospitalId || " ",
          }));
        } catch (error) {
          console.error("Error fetching user data:", error);
         
        } finally {
          
        }
      } else {
       
      }
    });

    return () => unsubscribe();
  }, []);

  const hospitalId= formData1.HospitalId;

  useEffect(() => {
    const fetchTimeOptions = async () => {
      if (formData.Appointmenttab_doctorname && formData.Appointmenttab_date) {
        try {
          const formattedDate = formatDate(formData.Appointmenttab_date);
          const response = await axios.post('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getAvailableTimes', {
            doctorName: formData.Appointmenttab_doctorname,
            date: formattedDate,
            hospitalId: hospitalId
          });
          setTimeOptions([...response.data, ...defaultTimeOptions]);
        } catch (error) {
          console.error('Error fetching available times:', error);
        }
      }
    };
  
    if (hospitalId) {
      fetchTimeOptions();
    }
  }, [formData.Appointmenttab_doctorname, formData.Appointmenttab_date, hospitalId]);
  
  // Helper function for consistent date formatting
  const formatDate = (date) => {
    const d = new Date(date);
    return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`;
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.get('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/fetchLatestAppointmentId',{
        params: {hospitalId}
      });
      const latestAppointmentId = response.data.latestAppointmentId;
      const newAppointmentId = latestAppointmentId + 1;

      const formattedDate = format(formData.Appointmenttab_date, 'dd/MM/yyyy');

      const updatedFormData = {
        ...formData,
        Appointmenttab_id: newAppointmentId,
        Appointmenttab_date: formattedDate,
        hospitalId
      };

      await axios.post('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/storeAppointment', updatedFormData);

      setFormData({
        Appointmenttab_id: 0,
        Appointmenttab_name: '',
        Appointmenttab_email: '',
        Appointmenttab_date: new Date(),
        Appointmenttab_time: '',
        Appointmenttab_doctorname: '',
        Appointmenttab_phone: ''
      });
      setSuccessMessage('Appointment successfully scheduled!');
      setTimeout(() => {
        setSuccessMessage('');
      }, 2000);
    } catch (error) {
      console.error('Error storing form data:', error);
    }
  };

  const [doctorOptions, setDoctorOptions] = useState([]);
  const [patientOptions, setPatientOptions] = useState([]);
  const [patientData, setPatientData] = useState([]);

  useEffect(() => {
    const fetchDoctorDetails = async (hospitalId) => {
      try {
        const response = await axios.get('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/fetchDoctorNames',{
          params: {hospitalId}
        });
        const formattedDoctorOptions = response.data.map(doctor => ({
          value: doctor.Name,
          label: doctor.Name,
        }));
        setDoctorOptions(formattedDoctorOptions);
      } catch (error) {
        console.error('Error fetching doctor details:', error);
      }
    };

    if (hospitalId) {
      fetchDoctorDetails(hospitalId);
    }
  }, [hospitalId]);

  useEffect(() => {
    const fetchPatientDetails = async (hospitalId) => {
      try {
        const response = await axios.get('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/fetchPatientNames',{
          params: { hospitalId }
        });
        const formattedPatientOptions = response.data.map(patient => ({
          value: patient.Name,
          label: patient.Name,
        }));
        setPatientOptions(formattedPatientOptions);
        setPatientData(response.data);
      } catch (error) {
        console.error('Error fetching patient details:', error);
      }
    };

    if (hospitalId) {
      fetchPatientDetails(hospitalId);
    }
  }, [hospitalId]);

  const defaultTimeOptions = [
    "10:00 AM - 10:30 AM",
    "10:30 AM - 11:00 AM",
    "11:00 AM - 11:30 AM",
    "11:30 AM - 12:00 AM",
    "12:00 PM - 12:30 PM",
    "12:30 PM - 01:00 PM",
    "01:00 PM - 01:30 PM",
    "01:30 PM - 02:00 PM",
    "06:00 PM - 06:30 PM",
    "06:30 PM - 07:00 PM",
    "07:00 PM - 07:30 PM",
    "07:30 PM - 08:00 PM",
    "08:00 PM - 08:30 PM",
    "08:30 PM - 09:00 PM",
  ];
  

  return (
    <Layout title="Appointment Form"><br></br>
    <Loader/>
      <h1 style={{ color: "#007bff"}}>Appointment Form</h1><br />
      <form onSubmit={handleSubmit} className="appointment-form">
        <div className="Column">
          <div className="col-md-4" style={{width:"230px"}}>
            <div className="form-group">
              <div className="select-wrap">
                <div className="icon">
                  <span className="ion-ios-arrow-down" />
                </div>
                {doctorOptions.length > 0 && (
                  <Select
                    id="inputDoctorName"
                    options={doctorOptions}
                    value={doctorName}
                    onChange={handleDoctorNameChange}
                    isSearchable
                    styles={customStyles}
                    placeholder="Select Doctor Name"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="col-md-4" style={{width:"230px"}}>
            <div className="form-group">
              <div className="icon">
                <span className="icon-user" />
              </div>
              {patientOptions.length > 0 && (
                <Select
                  options={patientOptions}
                  value={patientName}
                  onChange={handlePatientNameChange}
                  isSearchable
                  styles={customStyles}
                  placeholder="Select Patient Name"
                />
              )}
            </div>
          </div>
        </div>
        <div className="Column">
          <div className="col-md-4" style={{width:"230px"}}>
            <div className="form-group">
              <div className="icon">
                <span className="icon-paper-plane" />
              </div>
              <input
                type="email"
                className="form-control"
                name="Appointmenttab_email"
                value={formData.Appointmenttab_email}
                onChange={handleInputChange}
                placeholder="Email"
              />
            </div>
          </div>
          <div className="col-md-4" style={{width:"230px"}}>
            <div className="form-group">
              <div className="icon">
                <span className="icon-phone2" />
              </div>
              <input
                type="tel"
                className="form-control"
                name="Appointmenttab_phone"
                value={formData.Appointmenttab_phone}
                onChange={handleInputChange}
                placeholder="Phone"
                required
              />
            </div>
          </div>
        </div>
        <div className="Column">
        <div className="col-md-6" >
            <div className="form-group foalign">
              <DatePicker
                id="inputDate"
                className="form-control"
                selected={startDate}
                onChange={handleDateChange}
                dateFormat="dd/MM/yyyy"
                minDate={new Date()}
                placeholderText="Select Date"
              />
            </div>
          </div>
          <div className="col-md-4" style={{width:"230px"}}>
            <div className="form-group">
              <select
                className="form-control"
                name="Appointmenttab_time"
                value={formData.Appointmenttab_time}
                onChange={handleTimeChange}
                placeholder="Select Time"
              >
                <option value="">Select Time</option>
                {timeOptions.map((time, index) => (
                  <option key={index} value={time} style={{ color: "black" }}>{time}</option>
                  
                ))}
              </select>
            </div>
          </div>
          
        </div>
        <div className="form-group d-flex" style={{ paddingLeft: "100px" }}>
          <button type="submit" className="btn btn-secondary">Submit</button>
        </div>
        {successMessage && <p className="text-success text-center" style={{ color: "#2118c9", textAlign: "center" }}>{successMessage}</p>}
      </form>
      
      

    </Layout>
  );
}

export default Appointment;
