import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import { BiPaperPlane, BiCloudDownload } from "react-icons/bi";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import axios from 'axios';
import firebase from 'firebase/compat/app';

const firebaseConfig = {
  apiKey: "AIzaSyCI59gnHA9AZAANjoy082vht3fCgatchEg",
  authDomain: "hospital-management-syst-7e35d.firebaseapp.com",
  databaseURL: "https://hospital-management-syst-7e35d-default-rtdb.firebaseio.com",
  projectId: "hospital-management-syst-7e35d",
  storageBucket: "hospital-management-syst-7e35d.firebasestorage.app",
  messagingSenderId: "59517358381",
  appId: "1:59517358381:web:96f502e3832ddf1a8fc3a4",
  measurementId: "G-EYLWMBT9SP"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const storage = firebase.storage();

async function GenerateInvoice(patientId, patientName, billTo, billToEmail, billToAddress, billToGender, billToPhone, billFrom, billFromEmail, billFromAddress, billFromPhone, billFromHospitalName, notes, additionalNotes, items, invoiceNumber, dateOfIssue, days, color1, color2, address, socialMedia, hospital) {
    const canvas = await html2canvas(document.querySelector("#invoiceCapture"));
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'pt',
      format: [612, 792]
    });
    pdf.internal.scaleFactor = 1;
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    const fileName = `${patientId}_${billFrom}.pdf`;
    pdf.save(fileName);
    const blob = pdf.output('blob');

    const storageRef = storage.ref();
    const pdfRef = storageRef.child(`pdfs/${fileName}`);
    let pdfURL = '';
    let uploadSuccessful = false;

    
      try {
        await pdfRef.put(blob);
        pdfURL = await pdfRef.getDownloadURL();
        uploadSuccessful = true;
      } catch (error) {
        console.error('Error uploading PDF:', error);
      }

      console.log("Hospitalinmodal:", hospital);
    const prescriptionData = {
      hospital,
      url: uploadSuccessful ? pdfURL : 'placeholder-url-or-error',
      patientId,
      patientName,
      billTo,
      billToEmail,
      billToAddress,
      billToGender,
      billToPhone,
      billFrom,
      billFromEmail,
      billFromAddress,
      billFromPhone,
      notes,
      additionalNotes: JSON.stringify(additionalNotes),
      items: JSON.stringify(items),
      invoiceNumber,
      dateOfIssue,
      days,
      color1,
      color2,
      address,
      socialMedia
    };

    try {
      const createResponse = await fetch('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/create-prescriptionmodal', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(prescriptionData),
      });

      const createData = await createResponse.json();
      console.log("createData:", createData);
      if (!createResponse.ok) {
        throw new Error('Failed to store prescription');
      }

      console.log(createData);
    } catch (error) {
      console.error('Error storing prescription:', error);
    }
}

class InvoiceModal1 extends React.Component {

  
  render() {
    const { showModal, closeModal, info, items, currency, total, subTotal, taxAmount, discountAmount, selectedMedicineNames = [], color1, color2, color3, color4, socialMedia, address={}, hospital } = this.props;
    console.log("hospitalModal:", hospital);
    return (
      <div>
        <Modal show={showModal} onHide={closeModal} size="xl" centered>
          <div id="invoiceCapture">
            <div className="d-flex flex-row justify-content-between align-items-start w-100 p-4" style={{ backgroundColor: color1 }}>
            <div>
              <h6>Address</h6>
              {address.streetName1 && <div>{address.streetName1},{address.streetName2}</div>}
              {address.area && <div>{address.area}</div>}
              {address.city && <div>{address.city}</div>}
            </div>
              <div className="w-100" style={{ marginLeft: "10px", textAlign: "center" }}>
                <h4 className="fw-bold my-2">{info.billFrom || 'John Uberbacher'}</h4>
                <h6 className="fw-bold text-secondary mb-1">
                  Prescription #: {info.invoiceNumber || ''}
                </h6>
                <h2 className="fw-bold my-2 text-center">{info.billFromHospitalName || ''}</h2>
              </div>
              <div className="text-end ms-4">
            
              </div>
            </div>
            <div className="p-4">
              <Row className="mb-4">
                <Col md={4}>
                  <div className="fw-bold">Patient Profile</div>
                  <div>Name:{info.billTo || ''}</div>
                  <div>Phone:{info.billToPhone || ''}</div>
                  <div>Email:{info.billToEmail || ''}</div>
                  <div>Gender:{info.billToGender || ''}</div>
                  <div>Address:{info.billToAddress || ''}</div>
                  
                </Col>
                <Col md={4}>
                  <div className="fw-bold">Doctor Profile</div>
                  <div>Name:{info.billFrom || ''}</div>
                  <div>Address:{info.billFromAddress || ''}</div>
                  <div>Email:{info.billFromEmail || ''}</div>
                </Col>
                <Col md={4}>
                  <div className="fw-bold mt-2">Date Of Issue:</div>
                  <div>{info.dateOfIssue || ''}</div>
                </Col>
              </Row>
              <Table className="mb-0">
                <thead>
                  <tr>
                  <th className="text-center">S.No</th>
                  <th className="text-center">Medicine Name</th>
                  <th className="text-center">Session</th>
                  <th className="text-center">Days</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, i) => (
                    <tr key={i}>
                      <td className="text-center">{i + 1}</td>
                      <td className="text-center">{selectedMedicineNames[i] || item.name}</td>
                    
                      <td className="text-center">
                      <table className="nested-table">
                        <thead>
                          <tr>
                            <th></th>
                            {['Mor', 'Noon', 'Eve', 'Nig'].map(time => (
                              <th key={time}>{time}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {['Before', 'After'].map(period => (
                            <tr key={period}>
                              <td>{period}</td>
                              {['morning', 'noon', 'evening', 'night'].map(time => (
                                <td key={`${time}${period}`}>
                                  {item.quantities[`${time}${period}`] || 0}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                  </td>
                  <td style={{textAlign:'center'}}>
                    {item.days || 0}
                  </td>
                     
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Table>
                <tbody>
                 
                </tbody>
              </Table>
              {info.notes &&
                <div className="bg-light py-3 px-4 rounded">
                  {info.notes}
                </div>}
                {info.additionalNotes.map((note,index)=>(
                  <div className='bg-light py-3 px-4 rounded' key={index}>
                  {note}
                </div>
                ))
                
                }
            </div>
            <div className="social-icons mt-3 d-flex justify-content-center" style={{backgroundColor: color2 || "lightgrey"}}>
                {socialMedia?.facebook && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-facebook fa-2x"></i>
                    <span className="ms-2">{socialMedia.facebook}</span>
                  </div>
                )}
                {socialMedia?.instagram && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-instagram fa-2x"></i>
                    <span className="ms-2">{socialMedia.instagram}</span>
                  </div>
                )}
                {socialMedia?.linkedin && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-linkedin fa-2x"></i>
                    <span className="ms-2">{socialMedia.linkedin}</span>
                  </div>
                )}
                {socialMedia?.youtube && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-youtube fa-2x"></i>
                    <span className="ms-2">{socialMedia.youtube}</span>
                  </div>
                )}
              </div>
          </div>
          <div className="pb-4 px-4">
            <Row>
              <Col md={6}>
               
              </Col>
              <Col md={6}>
                <Button variant="outline-primary" className="d-block w-100 mt-3 mt-md-0" onClick={() => GenerateInvoice(info.patientId, info.patientName, info.billTo, info.billToEmail, info.billToAddress, info.billToGender, info.billToPhone, info.billFrom, info.billFromEmail, info.billFromAddress, info.billFromPhone, info.billFromHospitalName, info.notes, info.additionalNotes, info.items, info.invoiceNumber, info.dateOfIssue, color1, color2, address, socialMedia,hospital )}>
                  <BiCloudDownload style={{ width: '16px', height: '16px', marginTop: '-3px' }} className="me-2" />
                  Download Copy
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>
        <hr className="mt-4 mb-3" />
      </div>
    );
  }
}

export default InvoiceModal1;
