import React, { useEffect, useState, Fragment, useRef } from "react";
import Layout from "../core/Layout";
import { Link } from "react-router-dom";
import moment from "moment";
import Pagination from "react-js-pagination";
import axios from 'axios';
import firebase from 'firebase/compat/app';
import Loader from "../frontpage/Loader";
import "./ListDoctors.css";

const ListDoctors = ({ history }) => {
  const [doctors, setDoctors] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const itemsPerPage = 10; // Set the number of items per page

  const [hospitalId, setHospitalId] = useState(null);
  const [hospitalName, setHospitalName] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const currentUser = firebase.auth().currentUser;

      if (currentUser) {
        const userId = currentUser.uid;

        try {
          const response = await axios.get('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getUserDataHospital', {
            params: { userId }
          });

          setHospitalId(response.data.HospitalId);
          setHospitalName(response.data.HospitalName);
        
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchDoctors = async () => {
      if(hospitalId) {
        console.log("hospitalId:", hospitalId);
      try {
        const response = await axios.get(' https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/get-doctors',{
          params: { hospitalId }
        });
        setDoctors(response.data);
      } catch (error) {
        console.error('Error fetching doctors:', error);
      }
    }
    };

    fetchDoctors();
  }, [hospitalId]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  
  // Handle search term input
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  
  // Filter doctors based on search term
  const filteredDoctors = doctors.filter((doctor) => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const doctorIdMatch = doctor.doctorId ? doctor.doctorId.toString().includes(lowercasedSearchTerm) : false;
    const nameMatch = doctor.Name ? doctor.Name.toLowerCase().includes(lowercasedSearchTerm) : false;
    const phoneMatch = doctor.PhoneNo ? doctor.PhoneNo.toString().includes(lowercasedSearchTerm) : false;
    return doctorIdMatch || nameMatch || phoneMatch;
  });

  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentDoctors = filteredDoctors.slice(indexOfFirstItem, indexOfLastItem);


  const deleteHandler = async(doctorId) => {
    console.log(doctorId);
    if (window.confirm("Are you sure you want to delete this doctor?")) {
      try {
        await axios.delete(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/delete-doctors`,{
          data:{
            doctorId,
            hospitalId
          }
        });
        setDoctors(doctors.filter( doctor => doctor.doctorId !== doctorId));
      }catch(error){
        console.error("Error deleting doctor:", error);
      }
    }
  };

  return (
    <Layout title="List Doctors" className="container-fluid">
    <Loader/>
    <h1 className="mt-4 color-bright">Doctor Management Portal</h1><br />
      <h4>
        <Link to="/add-doctor" className="btn btn-outline-primary">
          Add Doctor Profile
        </Link>
      </h4><br /><br />

      {/* Search Input */}
      <div className="form-group col-md-4">
        <label htmlFor="searchTerm">Search by  Name, or Phone No</label>
        <input
          type="text"
          id="searchTerm"
          className="form-control input-shadow"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>

      <Pagination
        activePage={activePage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={filteredDoctors.length}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
        itemClass="page-item"
        linkClass="page-link"
      />

      <div className="table-responsive row" ref={useRef(null)}>
        <table id="listPatients2" cellPadding="0" cellSpacing="0" border="0">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Doctor Name</th>
              <th scope="col">Speciality</th>
              <th scope="col">Email</th>
              <th scope="col">Phone No</th>
              <th scope="col">Fee</th>
              <th scope="col">Gender</th>
              <th scope="col">Edit</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            {currentDoctors.map((doctor, i) => (
              <tr key={i}>
                <Fragment>
                  <th scope="row">{doctor.doctorId}</th>
                  <td>{doctor.Name}</td>
                  <td>{doctor.speciality}</td>
                  <td>{doctor.Email}</td>
                  <td>
                  <a href={`https://wa.me/${doctor.PhoneNo}`} target="_blank" rel="noopener noreferrer">
                    {doctor.PhoneNo}
                  </a></td>
                  <td>{doctor.Fee}</td>
                  <td>{doctor.Gender}</td>
                  <td>
                    <Link to={`/update-doctor/${doctor.doctorId}`}>
                      <i className="bi bi-pencil-square"></i>
                    </Link>
                  </td>
                  <td>
                    <i
                      className="bi bi-trash"
                      onClick={() => deleteHandler(doctor.doctorId)}
                    ></i>
                  </td>
                </Fragment>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
  </Layout>
  );
};

export default ListDoctors;
