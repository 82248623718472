import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../core/Layout";
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import Loader from "../frontpage/Loader";

const firebaseConfig = {
  apiKey: "AIzaSyCI59gnHA9AZAANjoy082vht3fCgatchEg",
  authDomain: "hospital-management-syst-7e35d.firebaseapp.com",
  databaseURL: "https://hospital-management-syst-7e35d-default-rtdb.firebaseio.com",
  projectId: "hospital-management-syst-7e35d",
  storageBucket: "hospital-management-syst-7e35d.firebasestorage.app",
  messagingSenderId: "59517358381",
  appId: "1:59517358381:web:96f502e3832ddf1a8fc3a4",
  measurementId: "G-EYLWMBT9SP"
};

firebase.initializeApp(firebaseConfig);

const FileUpload = ({ history }) => {
  const [uploading, setUploading] = useState(false);
  const [VendorName,setVendorName] = useState("");
  const [VendorCity,setVendorCity] = useState("");
  const [VendorState,setVendorSate] = useState("");
  const [VendorEmail,setVendorEmail] = useState("");
  const [VendorPhoneNumber,setPhNumber] = useState("");
  const [VendorCompanyName,setCmyName] = useState("");
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  
  
  const[formData1, setFormData1]= useState({
    userId:"",
    HospitalName:"",
  })

  const[HospitalName,setHospitalName]=useState("");

  useEffect(()=>{
    const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;

        setFormData1((prevData) => ({
          ...prevData,
          userId: userId,
          HospitalName: HospitalName,
        }));

        try {
          const response = await axios.get(
            `https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getHospitalName/${userId}`
          );
          const addressData = response.data;
          setFormData1((prevData) => ({
            ...prevData,
            HospitalName: addressData.HospitalName || " ",
            HospitalId: addressData.HospitalId || " ",
          }));
        } catch (error) {
          console.error("Error fetching user data:", error);
         
        } finally {
          
        }
      } else {
       
      }
    });

    return () => unsubscribe();
  }, []);


  const submitHandler = async (e) =>{
    e.preventDefault();
    const formData ={
      VendorStatus: "Inactive",
      VendorName,
      VendorCity,
      VendorState,
      VendorEmail,
      VendorPhoneNumber,
      VendorCompanyName,
      HospitalId: formData1.HospitalId
    };
    try {
      const response = await fetch('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/Vendor-submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
    });
    const result = await response.json();
    if (response.ok) {
      setSuccessMessage(result.message);
      setErrorMessage('');
     
    } else {
      throw new Error(result.error);
    }
  } catch (error) {
    console.error('Error submitting form: ', error);
    setSuccessMessage('');
    setErrorMessage('Error submitting form.');
  }
};

  return (
    <Layout title="Category test Form">
    <Loader/>
  <h1 className="mt-4 color-bright text-center">Add Vendor Details</h1>
  <br />
  <div className="containe">
    <form onSubmit={submitHandler}>
      <div className="row">
        <div className="col-md-4" style={{marginLeft:"20%", marginTop:"20px"}}>
          <div className="form-group">
            <label htmlFor="inputAddress">Vendor Name</label>
            <input
              type="text"
              className="form-control input-shadow input-p"
              placeholder="Name"
              value={VendorName}
              onChange={(e) => setVendorName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="inputAddress">Vendor State</label>
            <input
              type="text"
              className="form-control input-shadow input-p"
              placeholder="Vendor state"
              value={VendorState}
              onChange={(e) => setVendorSate(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="inputAddress">Phone Number</label>
            <input
              type="text"
              className="form-control input-shadow input-p"
              placeholder="Number"
              value={VendorPhoneNumber}
              onChange={(e) => setPhNumber(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-4" style={{marginTop:"20px"}}>
          <div className="form-group">
            <label htmlFor="inputAddress">Vendor City</label>
            <input
              type="text"
              className="form-control input-shadow input-p"
              placeholder="City"
              value={VendorCity}
              onChange={(e) => setVendorCity(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="inputAddress">Email</label>
            <input
              type="email"
              className="form-control input-shadow input-p"
              placeholder="Email"
              value={VendorEmail}
              onChange={(e) => setVendorEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="inputAddress">Company Name</label>
            <input
              type="text"
              className="form-control input-shadow input-p"
              placeholder="Company Name"
              value={VendorCompanyName}
              onChange={(e) => setCmyName(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <button type="submit" className="btn btn-primary" style={{ width: "10%" }}>
          Save
        </button>
      </div>
    </form>
    {successMessage && (
      <div className="alert alert-success mt-3" role="alert">
        {successMessage}
      </div>
    )}
    {errorMessage && (
      <div className="alert alert-danger mt-3" role="alert">
        {errorMessage}
      </div>
    )}
  </div>
</Layout>

  );
};

export default FileUpload;