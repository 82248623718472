import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import { BiPaperPlane, BiCloudDownload } from "react-icons/bi";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import { FaTrash } from 'react-icons/fa';

const storage = firebase.storage();



async function GenerateInvoice(vendorId, vendorName, billTo, billToEmail, billToCompany, billToPhone, billFrom, billFromEmail, billFromAddress, billFromPhone, notes, additionalNotes, invoiceNumber, dateOfIssue, total, selectedMedicineNames, subTotal, discountAmount, taxAmount, items, hospital, color1, color2, color3, color4, socialMedia, totalPaid, rows, address) {
  const canvas = await html2canvas(document.querySelector("#invoiceCapture"));
  const imgData = canvas.toDataURL('image/png');
  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'pt',
    format: [612, 792]
  });
  pdf.internal.scaleFactor = 1;
  const imgProps = pdf.getImageProperties(imgData);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  const fileName = `${vendorId}_${billFrom}.pdf`;
  pdf.save(fileName);
  const blob = pdf.output('blob');

  const storageRef = storage.ref();
  const pdfRef = storageRef.child(`VendorbillModal/${fileName}`);
  let pdfURL = '';
  let uploadSuccessful = false;

  
    try {
      await pdfRef.put(blob);
      pdfURL = await pdfRef.getDownloadURL();
      uploadSuccessful = true;
    } catch (error) {
      console.error('Error uploading PDF:', error);
    }

    const currentDate = new Date().toLocaleDateString();

    const BillData = {
      hospital: hospital,
      url: uploadSuccessful ? pdfURL : 'placeholder-url-or-error',
      vendorId: vendorId || '',
      vendorName: vendorName || '',
      billTo: billTo || '',
      billToEmail: billToEmail || '',
      billToCompany: billToCompany || '',
      billToPhone: billToPhone || '',
      billFrom: billFrom || '',
      billFromEmail: billFromEmail || '',
      billFromAddress: billFromAddress || '',
      billFromPhone: billFromPhone || '',
      invoice: invoiceNumber || 0,
      total: total || '0.00',
      totalPaid: [totalPaid],
      amountDue: [total - totalPaid],
      color1: color1,
      color2: color2,
      color3: color3,
      color4: color4,
      address: address,
      socialMedia: socialMedia,
      BillDate: currentDate,
      paymentMode: rows.map((row)=>row.paymentMode),
      paymentDates: rows.map((row)=>row.date),
      paymentAmounts: rows.map((row)=>row.amount),
      paymentCategories: items.map((item)=>item.selectedCategory),
      paymentIds: rows.map((row)=>row.paymentId),
      allPaids: [totalPaid],
      allPaidsDate: [currentDate],
      allPaidsAmountDue: [total-totalPaid],
      status: (total - totalPaid) === 0 ? 'Paid': 'Pending',
      dateOfIssue: dateOfIssue || '',
      notes: notes || '',
      selectedMedicineNames: selectedMedicineNames || [],
      subTotal: subTotal || '0.00',
      taxAmount: taxAmount || '0.00',
      discountAmount: discountAmount || '0.00',
      additionalNotes: additionalNotes ? JSON.stringify(additionalNotes) : '[]',
      items: items ? JSON.stringify(items) : '[]',
    };
    

  console.log("Billdata:", BillData);

  try {
    const createResponse = await fetch('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/vendorsplitcreate-billModal', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(BillData),
    });

    const createData = await createResponse.json();
    console.log("createData:", createData);
    if (!createResponse.ok) {
      throw new Error('Failed to store prescription');
    }

    console.log(createData);
  } catch (error) {
    console.error('Error storing prescription:', error);
  }
}


class VendorSplitInvoiceModal extends React.Component {

  render() {
    const { showModal, closeModal, info, items, currency, socialMedia, total, subTotal, taxAmount, discountAmount, selectedMedicineNames = [], totalPaid, rows, selectedCategory, address={}, color1,color2, color3, color4, vendorId, vendorName, hospital} = this.props;
    console.log(subTotal);
    return (
      <div>
        <Modal show={showModal} onHide={closeModal} size="xl" centered>
          <div id="invoiceCapture">
            <div className="d-flex flex-row justify-content-between align-items-start  w-100 p-4"  style={{ backgroundColor: color1 }}>
              <div>
                <h6>Address</h6>
                {address.streetName1 && <div>{address.streetName1},{address.streetName2}</div>}
                {address.area && <div>{address.area}</div>}
                {address.city && <div>{address.city}</div>}
              </div>
              <div className="w-100" style={{ marginLeft: "10px", textAlign: "center" }}>
                <h4 className="fw-bold my-2">{info.billFrom || 'John Uberbacher'}</h4>
                <h6 className="fw-bold text-secondary mb-1">
                  Vendor Invoice #: {info.invoiceNumber || ''}
                </h6>
              </div>
              <div className="text-end ms-4">
                <h6 className="fw-bold mt-1 mb-2">Amount&nbsp;Due:</h6>
                <h5 className="fw-bold text-secondary">{currency} {total}</h5>
              </div>
            </div>
            <div className="p-4">
              <Row className="mb-4">
                <Col md={4}>
                  <div className="fw-bold">Billed to:</div>
                  <div>{info.billTo || ''}</div>
                  <div>{info.billToCompany || ''}</div>
                  <div>{info.billToEmail || ''}</div>
                </Col>
                <Col md={4}>
                  <div className="fw-bold">Billed From:</div>
                  <div>{info.billFrom || ''}</div>
                  <div>{info.billFromAddress || ''}</div>
                  <div>{info.billFromEmail || ''}</div>
                </Col>
                <Col md={4}>
                  <div className="fw-bold mt-2">Date Of Issue:</div>
                  <div>{info.dateOfIssue || ''}</div>
                </Col>
              </Row>
              <Table className="mb-0">
                <thead>
                  <tr>
                    <th className='text-center'>Categories</th>
                    <th className='text-center'>QTY</th>
                    <th className="text-center">PRICE</th>
                    <th className="text-center">AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, i) => (
                    <tr key={i}>
                      <td className="text-center">{info.selectedCategory || item.selectedCategory}</td>
                      <td className="text-center" style={{ width: '70px' }}>{item.quantity}</td>
                      <td className="text-center" style={{ width: '100px' }}>{currency} {item.price}</td>
                      <td className="text-center" style={{ width: '100px' }}>{currency} {(item.price * item.quantity).toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Table>
                <tbody>
                  <tr className="text-end">
                    <td></td>
                    <td className="fw-bold" style={{ width: '100px' }}>SUBTOTAL</td>
                    <td className="text-end" style={{ width: '100px' }}>{currency} {info.subTotal}</td>
                  </tr>
                  {info.taxAmount !== '0.00' &&
                    <tr className="text-end">
                      <td></td>
                      <td className="fw-bold" style={{ width: '100px' }}>TAX</td>
                      <td className="text-end" style={{ width: '100px' }}>{currency} {info.taxAmount}</td>
                    </tr>
                  }
                  {info.discountAmount !== '0.00' &&
                    <tr className="text-end">
                      <td></td>
                      <td className="fw-bold" style={{ width: '100px' }}>DISCOUNT</td>
                      <td className="text-end" style={{ width: '100px' }}>{currency} {info.discountAmount}</td>
                    </tr>
                  }
                  <tr className="text-end">
                    <td></td>
                    <td className="fw-bold" style={{ width: '100px' }}>TOTAL AMOUNT</td>
                    <td className="text-end" style={{ width: '100px' }}>{currency} {(parseFloat(info.total)|| 0).toFixed(2)}</td>
                  </tr>
                  <tr className="text-end">
                    <td></td>
                    <td className="fw-bold" style={{ width: '100px' }}>TOTAL PAID</td>
                    <td className="text-end" style={{ width: '100px' }}>{currency} {(parseFloat(info.totalPaid)||0).toFixed(2)}</td>
                  </tr>
                  </tbody>
                  </Table>
                  <Table>
                  <thead>
                <tr>
                  <th style={{textAlign:"center"}}>S.NO</th>
                  <th style={{textAlign:"center"}}>Payment Type</th>
                  <th style={{textAlign:"center"}}>Payment Id</th>
                  <th style={{textAlign:"center"}}>Date</th>
                  <th style={{textAlign:"center"}}>Amount</th>
                </tr>
                  </thead>
                  <tbody>
                  {rows.map((row, index) => (
                  <tr className="text-end" key={index}>
                    <td style={{textAlign:"center"}}>{index + 1}</td>
                    <td className="fw-bold" style={{ width: '100px', textAlign:"center" }}>
                      {`${row.paymentMode}`}
                    </td>
                    <td>
                    <div style={{ fontWeight: "normal", textAlign: "center" }}>
                        {`${row.paymentId || 'N/A'}`}
                      </div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {`${row.date}`}
                    </td>
                    <td className="text-center" style={{ width: '100px' }}>
                      {currency} {(parseFloat(row.amount) || 0).toFixed(2)}
                    </td>
                  </tr>
                ))}
                  </tbody>
                  </Table>
                  <Table>
                  <tbody>
                 <tr className="text-end">
                  <td></td>
                  <td className="fw-bold" style={{ width: '100px' }}>AMOUNT DUE</td>
                  <td className="text-end" style={{ width: '100px' }}>{currency}{(parseFloat(info.total) - parseFloat(info.totalPaid) || 0).toFixed(2)}</td>
                </tr>
                </tbody>
              </Table>
              {info.notes &&
                <div className="bg-light py-3 px-4 rounded">
                  {info.notes}
                </div>}
            </div>
            <div className="mt-4 text-center footerbill" style={{backgroundColor: color2 || "lightgrey"}}>
              {/* <p className="fw-bold">Thank you!</p>
              <p>Contact us: {this.state.billFromEmail || 'email@example.com'}</p>
              <p>{this.state.billFromAddress || '1234 Street, City, Country'}</p> */}
              <div className="social-icons mt-3 d-flex justify-content-center">
                {socialMedia?.facebook && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-facebook fa-2x"></i>
                    <span className="ms-2">{socialMedia.facebook}</span>
                  </div>
                )}
                {socialMedia?.instagram && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-instagram fa-2x"></i>
                    <span className="ms-2">{socialMedia.instagram}</span>
                  </div>
                )}
                {socialMedia?.linkedin && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-linkedin fa-2x"></i>
                    <span className="ms-2">{socialMedia.linkedin}</span>
                  </div>
                )}
                {socialMedia?.youtube && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-youtube fa-2x"></i>
                    <span className="ms-2">{socialMedia.youtube}</span>
                  </div>
                )}
              </div>



            </div>
          </div>
          <div className="pb-4 px-4">
            <Row>
              <Col md={6}>
                {/* <Button variant="primary" className="d-block w-100" onClick={this.handleSendInvoice}>
                  <BiPaperPlane style={{ width: '15px', height: '15px', marginTop: '-3px' }} className="me-2" />Send Invoice
                </Button> */}
              </Col>
              <Col md={6}>
                <Button variant="outline-primary" className="d-block w-100 mt-3 mt-md-0" onClick={() => GenerateInvoice(info.vendorId, info.vendorName, info.billTo, info.billToEmail, info.billToCompany, info.billToPhone, info.billFrom, info.billFromEmail, info.billFromAddress, info.billFromPhone, info.notes, info.additionalNotes, info.invoiceNumber, info.dateOfIssue, info.total, info.selectedMedicineNames, info.subTotal, info.discountAmount, info.taxAmount, info.items, info.hospital, info.color1, info.color2, info.color3, info.color4, info.socialMedia, info.totalPaid, info.rows, info.address)}>
                  <BiCloudDownload style={{ width: '16px', height: '16px', marginTop: '-3px' }} className="me-2" />
                  Download Copy
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>
        <hr className="mt-4 mb-3" />
      </div>
    );
  }
}

export default VendorSplitInvoiceModal;
