import React,{useState, useEffect} from "react";
import Layout from "../core/Layout";
import { Link , useParams, useNavigate} from "react-router-dom";
// import { Bar } from "react-chartjs-2";
import axios from 'axios';
import BarChart from './BarChart';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BarChart as RechartBarChart,Bar,Rectangle,XAxis,YAxis,CartesianGrid,Tooltip,Legend,ResponsiveContainer,PieChart,Pie,Cell} from 'recharts';
import { format, parse } from 'date-fns';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import Pagination from 'react-js-pagination';
import "./AdminDashboard.css";
import Loader from "../frontpage/Loader";

const AdminDashboard = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate(); 

  useEffect(() => {
    // Check if user is already logged in
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
        setTimeout(() => {
          navigate('/signin'); 
      }, 100);
      }
    });
  }, [navigate]);

  

  const expenseChartData = {
    labels: ["2021-03-31", "2021-01-31","2021-02-28"],
    datasets: [
      {
        backgroundColor: ["#007bff", "#dc3545"],
        data: [2500, 5000, 2400],
      },
    ],
  };


  const [data, setData] = useState({
    totalAmountSum: 0,
    paidSum: 0,
    balanceSum: 0,
  });
  
  
  const[formData1, setFormData1]= useState({
    userId:"",
    HospitalName:"",
  })

  const[HospitalName,setHospitalName]=useState("");

  useEffect(()=>{
    const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;

        setFormData1((prevData) => ({
          ...prevData,
          userId: userId,
          HospitalName: HospitalName,
        }));

        try {
          const response = await axios.get(
            `https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getHospitalName/${userId}`
          );
          const addressData = response.data;
          setFormData1((prevData) => ({
            ...prevData,
            HospitalName: addressData.HospitalName || " ",
            HospitalId: addressData.HospitalId || " ",
          }));
        } catch (error) {
          console.error("Error fetching user data:", error);
         
        } finally {
          
        }
      } else {
       
      }
    });

    return () => unsubscribe();
  }, []);

  
    const fetchData1 = async (from,to, hospitalId) => {
      console.log("from", from);
      console.log("to", to);
      console.log("hospitalId:", hospitalId);
      try {
        const response = await axios.get('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/filterData', {
          params: {
            from, to,hospitalId,
           },
        });
        console.log("Response:",response.data);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    const[paid1,setPaidValues1]=useState("")

    const fetchData2 = async (from,to, hospitalId) => {
      console.log("from chart date:", from);
      console.log("to chart date:", to);

      try{
        const response= await axios.get('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/filterchart',{
          params: {
            from, to, hospitalId
           },
          });
           const data= response.data;
            console.log("data value of charts:", data);

           setPaidValues(data.paidValues);
           setTotalAmountValues(data.totalAmountValues);
           setDates(data.dates);
      } catch (error) {
        console.error('Error fetching data', error);
    }
  }
    const [chartData, setChartData] = useState([]);

    const [paidValues, setPaidValues] = useState([]);
    const [totalAmountValues, setTotalAmountValues] = useState([]);
    const [dates, setDates] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

   

    useEffect(() => {
      const fetchData1 = async () => {
        const hospitalId = formData1.HospitalId;
        try {
          const response = await fetch('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/chartfetch',{
            params: {
              hospitalId,
            }
          }); // Replace with your backend URL
          const data = await response.json();
          console.log("data:",data);
  
         setPaidValues(data.paidValues || []);
          setTotalAmountValues(data.totalAmountValues || []);
          setDates(data.dates || []);
        } catch (error) {
          console.error('Error fetching data', error);
        }
      };
  
      fetchData1();
    }, [formData1.HospitalId]);

    const days = ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'];

    useEffect(() => {
      const fetchData = async () => {
        const hospitalId = formData1.HospitalId;
        try {
          const response = await axios.get('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/filterDataByDate1',{
            params: {
              hospitalId,
            }
          });
          console.log("Response:", response.data);
  
          if (!response.data || !response.data.totalAmountSum || !response.data.paidSum || !response.data.balanceSum) {
            throw new Error('Invalid data format received');
          }
  
          setData({
            totalAmountSum: response.data.totalAmountSum,
            paidSum: response.data.paidSum,
            balanceSum: response.data.balanceSum,
          });
        } catch (error) {
          console.error('Error fetching data', error);
        }
      };
  
      fetchData();
    }, [formData1.HospitalId]);


  const rechartData = [
    {
      name: '01/03/2021',
      totalbalance: 3000,
      expense: 1398,
      amt: 2210,
    },
    {
      name: '02/03/2021',
      totalbalance: 11000,
      expense: 5800,
      amt: 2290,
    },
    {
      name: '03/03/2021',
      totalbalance: 2780,
      expense: 3908,
      amt: 2000,
    },
    {
      name: '04/03/2021',
      totalbalance: 1890,
      expense: 4800,
      amt: 2181,
    },
    {
      name: '05/03/2021',
      totalbalance: 2390,
      expense: 3800,
      amt: 2500,
    },
    {
      name: '06/03/2021',
      totalbalance: 3490,
      expense: 4300,
      amt: 2100,
    },
  ];

  const initialStartDate = parse('01-02-2023', 'dd-MM-yyyy', new Date());
  const initialEndDate = parse('28-12-2024', 'dd-MM-yyyy', new Date());

  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  const handleSearch = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    // Implement search logic here
    if (startDate && endDate) {
      const from = format(startDate, 'dd-MM-yyyy');
      const to = format(endDate, 'dd-MM-yyyy');
      const hospitalId = formData1.HospitalId;
      fetchData1(from, to, hospitalId);
      fetchData2(from,to, hospitalId);
    }
  };

  useEffect(() => {
    const from = format(startDate, 'dd-MM-yyyy');
    const to = format(endDate, 'dd-MM-yyyy');
    const hospitalId = formData1.HospitalId;
    
    if (hospitalId) {
      fetchData1(from, to, hospitalId);
      fetchData2(from, to, hospitalId);
    }
  }, [startDate, endDate, formData1.HospitalId]);

  const handleChangeStart = (event) => {
    const date = parse(event.target.value, 'yyyy-MM-dd', new Date());
    setStartDate(date);
  };

  const handleChangeEnd = (event) => {
    const date = parse(event.target.value, 'yyyy-MM-dd', new Date());
    setEndDate(date);
  };

  const handleChange=(date)=>{
    setStartDate(date);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, dates.length);
  const currentData = dates.slice(startIndex, endIndex);

  
const data1 = paidValues.map((paidValue, index) => ({
  name: dates[index],
  Paid: paidValue,
  totalAmount: totalAmountValues[index],
  totalExpenses: (totalAmountValues[index] - paidValue) / 2,
  totalPending: totalAmountValues[index] - paidValue,
}));

  const pieData = [
    { name: 'Paid', value: data1.reduce((acc, cur) => acc + cur.Paid, 0) },
    { name: 'Total Amount', value: data1.reduce((acc, cur) => acc + cur.totalAmount, 0) },
    { name: 'Total Expenses', value: data1.reduce((acc, cur) => acc + cur.totalExpenses, 0) },
    { name: 'Total Pending', value: data1.reduce((acc, cur) => acc + cur.totalPending, 0) },
  ];


const totalValue = pieData.reduce((acc, cur) => acc + cur.value, 0);

const renderCustomLabel = ({ name, value }) => {
  const percentage = ((value / totalValue) * 100).toFixed(2);
  return `${name}: ${percentage}%`;
};
  
  const COLORS = ['#96e892', '#a98ee8', '#f7999c', '#fd9b63'];




const CustomTooltip = ({ payload, label, active }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};

  return (
    <Layout title="Dashboard">
    <Loader/>
    <h1 className="mt-4 color-bright">Revenue & Cost Analysis</h1>
    <div className="row mb-5">
      
      </div>
      <div className="row justify-content-center">
      <div className="col-md-2">
        <input
          type="date"
          value={startDate ? format(startDate, 'yyyy-MM-dd') : ''}
          onChange={handleChangeStart}
          className="form-control"
          placeholder="Start Date"
          max={format(new Date(), 'yyyy-MM-dd')}
        />
      </div>
      <div className="col-md-2 endateAdmind">
        <input
          type="date"
          value={endDate ? format(endDate, 'yyyy-MM-dd') : ''}
          onChange={handleChangeEnd}
          className="form-control"
          placeholder="End Date"
          min={startDate ? format(startDate, 'yyyy-MM-dd') : ''}
        />
      </div>
          <div className="col-md-2 d-flex justify-content-center align-items-center">
            <button className="btn btn-primary endabutton" onClick={(event) => handleSearch(event)}>
              Search
            </button>
          </div>
      </div><br></br>

    <div className="row">
      <div className="col-xl-3 col-md-6">
        <div className="card  text-white mb-4" style={{ backgroundColor:"#a98ee8"}}>
          <div className="card-body">Total Billed</div>
          <div className="card-footer d-flex align-items-center justify-content-between">
            <Link
              className="small text-white stretched-link"
              to={`/list/users`}
              style={{ textDecoration: 'none' }}
            >
              {data.totalAmountSum}
            </Link>
            {/* <div className="small text-white">
              <i className="fas fa-angle-right" />
            </div> */}
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-md-6">
        <div className="card text-white mb-4" style={{ backgroundColor:"#96e892"}}>
          <div className="card-body">Total Paid</div>
          <div className="card-footer d-flex align-items-center justify-content-between">
            <Link
              className="small text-white stretched-link"
              to={`/list/users`}
              style={{ textDecoration: 'none' }}
            >
              {data.paidSum}
            </Link>
            {/* <div className="small text-white">
              <i className="fas fa-angle-right" />
            </div> */}
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-md-6">
        <div className="card  text-white mb-4" style={{ backgroundColor:"#f7999c"}}>
          <div className="card-body">Total Expenses</div>
          <div className="card-footer d-flex align-items-center justify-content-between">
            <a className="small text-white stretched-link" style={{ textDecoration: 'none' }} href="#">
              {data.balanceSum / 2}
            </a>
            {/* <div className="small text-white">
              <i className="fas fa-angle-right" />
            </div> */}
          </div>
        </div>
      </div>
      <div className="col-xl-3 col-md-6">
        <div className="card text-white mb-4"  style={{ backgroundColor:" #fd9b63"}}>
          <div className="card-body">Total Pending</div>
          <div className="card-footer d-flex align-items-center justify-content-between">
            <a className="small text-white stretched-link" style={{ textDecoration: 'none' }} href="#">
              {data.totalAmountSum - data.paidSum}
            </a>
            {/* <div className="small text-white">
              <i className="fas fa-angle-right" />
            </div> */}
          </div>
        </div>
      </div>

      {/* <div className="col-lg-6">
        {chartData1 && (
          <div className="card mb-4">
            <div className="card-header">
              <i className="fas fa-chart-pie mr-1" />
              Total Billed vs Date
            </div>
            <div className="card-body">
              <BarChart data={chartData1} />
            </div>
            <div className="card-footer small text-muted">
              Updated yesterday at 11:59 PM
            </div>
          </div>
        )}
      </div> */}

      {/* <div className="col-lg-6">
        <div className="card mb-4">
          <div className="card-header">
            <i className="fas fa-chart-pie mr-1" />
            Expenses Types
          </div>
          <div className="card-body">
            <BarChart data={expenseChartData} />
          </div>
          <div className="card-footer small text-muted">
            Updated yesterday at 11:59 PM
          </div>
        </div>
      </div> */}

      <div className="col-lg-12">
        <div className="card mb-4">
          <div className="card-header">
            <i className="fas fa-chart-bar mr-1" />
             Revenue and Cost Analysis
          </div>
          <div className="card-body">
          
          <ResponsiveContainer width="100%" height={400}>
          
      <RechartBarChart
      
        data={
          paidValues.map((paidValue, index) => ({
            name: dates[index],  // Assuming you want to display day numbers
            Paid: paidValue,
            totalAmount: totalAmountValues[index],
            totalExpenses: (totalAmountValues[index]-paidValue)/2,
            totalPending: totalAmountValues[index]-paidValue
          }))
        }
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 40,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={{ angle: -45, textAnchor: 'end',style: { fontSize: '10px'} }}  interval={0}/>
        <YAxis />
        <Tooltip
          formatter={(value, name) => {
            // Customize tooltip content to display values with labels
            if (name === 'Paid') {
              return `${name}: ${value}`;
            }
            return value;
          }}
        />
        {/* <Legend/> */}
        {/* Display paid values as a single bar */}
        <Bar dataKey="Paid" fill="#96e892" label="Paid"/>
        <Bar dataKey="totalAmount" fill="#a98ee8" label="Total Amount" />
        <Bar dataKey="totalExpenses" fill="#f7999c" label="Total Expenses" />
        <Bar dataKey="totalPending" fill="#fd9b63" label="Total Pending" />
      </RechartBarChart>
      
    </ResponsiveContainer>
    
          </div>
          
          <div className="card-footer small text-muted">
            Updated yesterday at 11:59 PM
          </div>
        </div>
      </div>
      <div className="rowtablechart">
      <table className="tablechartmbile">
        <thead>
          <tr style={{ backgroundColor: '#007bff', color: 'white' }}>
            <th style={{ padding: '8px', border: '1px solid #ddd' }}>S.No</th>
            <th style={{ padding: '8px', border: '1px solid #ddd' }}>Date</th>
            <th style={{ padding: '8px', border: '1px solid #ddd' }}>Paid</th>
            <th style={{ padding: '8px', border: '1px solid #ddd' }}>Total Amount</th>
            <th style={{ padding: '8px', border: '1px solid #ddd' }}>Balance</th>
          </tr>
        </thead>
        <tbody>
          {currentData.map((date, index) => {
            const paid = paidValues[startIndex + index];
            const totalAmount = totalAmountValues[startIndex + index];
            const balance = totalAmount - paid;

            return (
              <tr key={index} style={{ backgroundColor: 'white', color: 'black' }}>
                <td style={{ padding: '8px', border: '1px solid #ddd' }}>{startIndex + index + 1}</td>
                <td style={{ padding: '8px', border: '1px solid #ddd' }}>{date}</td>
                <td style={{ padding: '8px', border: '1px solid #ddd' }}>{paid}</td>
                <td style={{ padding: '8px', border: '1px solid #ddd' }}>{totalAmount}</td>
                <td style={{ padding: '8px', border: '1px solid #ddd' }}>{balance}</td>
              </tr>
            );
          })}
        </tbody>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Pagination
          activePage={currentPage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={dates.length}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
      </table>
      <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={pieData}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={100}
          fill="#8884d8"
          label={renderCustomLabel}
        >
          {pieData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
    </div>
    </div>
  </Layout>
  );
};

export default AdminDashboard;
