import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../core/Layout";
import axios from 'axios';
import Pagination from "react-js-pagination";
import Loader from "../frontpage/Loader";
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

const ListVendors = ({ history }) => {
  const [getVendors, setVendors] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [vendorStatuses, setVendorStatuses] = useState([]);

  
  const[formData1, setFormData1]= useState({
    userId:"",
    HospitalName:"",
  })

  const[HospitalName,setHospitalName]=useState("");

  useEffect(()=>{
    const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;

        setFormData1((prevData) => ({
          ...prevData,
          userId: userId,
          HospitalName: HospitalName,
        }));

        try {
          const response = await axios.get(
            `https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getHospitalName/${userId}`
          );
          const addressData = response.data;
          setFormData1((prevData) => ({
            ...prevData,
            HospitalName: addressData.HospitalName || " ",
            HospitalId: addressData.HospitalId || " ",
          }));
        } catch (error) {
          console.error("Error fetching user data:", error);
         
        } finally {
          
        }
      } else {
       
      }
    });

    return () => unsubscribe();
  }, []);


  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await axios.get('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/get-vendors',{params: { HospitalId: formData1.HospitalId }});
        setVendors(response.data);
        const initialStatus = response.data.map(vendor => vendor.VendorStatus === 'Active');
        setVendorStatuses(initialStatus);
      } catch (error) {
        console.error('Error fetching expenses: ', error);
      }
    };

    if (formData1.HospitalId) {  // Only fetch if HospitalId is available
      fetchVendors();
    }
  }, [formData1.HospitalId]);

  const toggleStatus = async (index) => {
    try {
      const updatedStatus = vendorStatuses[index] ? 'Inactive' : 'Active';
  
      await axios.put('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/update-vendor-status', {
        vendorId: currentVendors[index].vendorId,
        newStatus: updatedStatus,
        HospitalId: formData1.HospitalId
      });
  
      // Update the local state with the new status
      const updatedStatuses = [...vendorStatuses];
      updatedStatuses[index] = updatedStatus === 'Active';
      setVendorStatuses(updatedStatuses);
    } catch (error) {
      console.error('Error updating vendor status: ', error);
    }
  };

  //   // Optionally, update the vendor status on the server
  //   // const updatedVendor = vendors[index];
  //   // updatedVendor.VendorStatus = !vendorStatuses[index] ? 'Active' : 'Inactive';
  //   // axios.put(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/update-vendor/${updatedVendor.vendorId}`, updatedVendor);
  // };

  // // State to manage the status of each vendor
  // const [vendorStatuses, setVendorStatuses] = useState("");

  // const toggleStatus = (index) => {
  //   setVendorStatuses(prevStatuses =>
  //     prevStatuses.map((status, i) =>
  //       i === index ? !status : status
  //     )
  //   );
  // };
  
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const deleteHandler = async (vendorId) => {
    console.log(vendorId);
    if (window.confirm("Are you sure you want to delete this vendor?")) {
      try {
        await axios.delete(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/delete-vendor`, {
          data: {
            vendorId,
            HospitalId: formData1.HospitalId
          }
        });
       
        setVendors(getVendors.filter(vendor => vendor.vendorId !== vendorId));
  
      } catch (error) {
        console.error("Error deleting vendor:", error);
      }
    }
  };


  const filteredVendors = !searchTerm
  ? getVendors
  : getVendors.filter((vendor) => {
      const lowercasedSearchTerm = searchTerm.toLowerCase();
      const vendorId = vendor.vendorId ? vendor.vendorId.toLowerCase() : '';
      const vendorName = vendor.VendorName ? vendor.VendorName.toLowerCase() : '';
      const vendorPhoneNumber = vendor.VendorPhoneNumber ? vendor.VendorPhoneNumber.toLowerCase() : '';

      // Debugging: Log search term and vendor details
      console.log('Search Term:', lowercasedSearchTerm);
      console.log('Vendor ID:', vendorId);
      console.log('Vendor Name:', vendorName);
      console.log('Vendor Phone Number:', vendorPhoneNumber);

      return (
        vendorId.includes(lowercasedSearchTerm) ||
        vendorName.includes(lowercasedSearchTerm) ||
        vendorPhoneNumber.includes(lowercasedSearchTerm)
      );
    });

  const indexOfLastVendor = activePage * itemPerPage;
  const indexOfFirstVendor = indexOfLastVendor - itemPerPage;
  const currentVendors = filteredVendors.slice(indexOfFirstVendor, indexOfLastVendor);

  return (
    <Layout title="Profile" className="container-fluid">
    <Loader/>
     <h1 className="mt-4 color-bright">Vendor Management Portal</h1><br></br><br></br>
      <h4>
        <Link to="/file-upload" className="btn btn-outline-primary">
          Add Vendors
        </Link>
      </h4><br></br><br></br>
      
      <div className="row">
        <div className="col-lg-8">
          <form>
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                value={searchTerm}
                onChange={handleChange}
                placeholder="Search for..."
                aria-label="Search"
                aria-describedby="basic-addon2"
              />
              <div className="input-group-append">
                <button className="btn btn-primary" type="button">
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>

        <div class="tbl-header">
          <table cellpadding="0" cellspacing="0" border="0">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Name</th>
                <th scope="col">City</th>
                <th scope="col">Phone</th>
                <th scope="col">Status</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            </table>
            </div>
           
            <div class="tbl-content">
            <table cellpadding="0" cellspacing="0" border="0">
            <tbody>
              {currentVendors.map((vendor, i) => (
                <tr key={i}>
                  <Fragment>
                    <th scope="row">{vendor.vendorId}</th>
                    <td>{vendor.VendorName}</td>
                    <td>{vendor.VendorCity}</td>
                    <td>
                    <a href={`https://wa.me/${vendor.VendorPhoneNumber}`} target="_blank" rel="noopener noreferrer">
                      {vendor.VendorPhoneNumber}
                    </a></td>
                    <td>
                  <button
                    className={`btn btn-${vendorStatuses[i] ? 'success' : 'danger'}`}
                    onClick={() => toggleStatus(i)}
                  >
                    {vendorStatuses[i] ? 'Active' : 'Inactive'}
                  </button>
                </td>
                    <td>
                        <Link to={`/UpdateVendor/${vendor.vendorId}`}>
                          <i className="bi bi-pencil-square" />
                        </Link>
                      </td>
                    <td>
                      <i
                        className="bi bi-trash"
                        onClick={() => deleteHandler(vendor.vendorId)}
                      ></i>
                    </td>
                  </Fragment>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Pagination
        activePage={activePage}
        itemsCountPerPage={itemPerPage}
        totalItemsCount={filteredVendors.length}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
        itemClass="page-item"
        linkClass="page-link"
      />
    </Layout>
  );
};

export default ListVendors;