import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Layout from '../core/Layout';
import Loader from '../frontpage/Loader';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

const UpdateVendor = () => {
  const { vendorId } = useParams();
  
  const [vendor, setVendor] = useState({
    VendorName: '',
    VendorCity: '',
    VendorState: '',
    VendorEmail: '',
    VendorPhoneNumber: '',
    VendorCompanyName: ''
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

   
  const[formData1, setFormData1]= useState({
    userId:"",
    HospitalName:"",
  })

  const[HospitalName,setHospitalName]=useState("");

  useEffect(()=>{
    const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;

        setFormData1((prevData) => ({
          ...prevData,
          userId: userId,
          HospitalName: HospitalName,
        }));

        try {
          const response = await axios.get(
            `https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getHospitalName/${userId}`
          );
          const addressData = response.data;
          setFormData1((prevData) => ({
            ...prevData,
            HospitalName: addressData.HospitalName || " ",
            HospitalId: addressData.HospitalId || " ",
          }));
        } catch (error) {
          console.error("Error fetching user data:", error);
         
        } finally {
          
        }
      } else {
       
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchVendorDetails = async () => {
      try {
        const response = await axios.get(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/vendors/${vendorId}`, {
          params: {
            HospitalId: formData1.HospitalId 
          }
        });
        console.log(response.data);
        setVendor(response.data);
      } catch (error) {
        console.error('Error fetching vendor details:', error);
      }
    };

    if (formData1.HospitalId) { 
      fetchVendorDetails();
    }
  }, [vendorId, formData1.HospitalId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVendor({
      ...vendor,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/updatevendors/${vendorId}`, {
        ...vendor,
        HospitalId: formData1.HospitalId // Include HospitalId in the request payload
      });
      setSuccessMessage('Vendor updated successfully');
      setErrorMessage('');
     
    } catch (error) {
      console.error('Error updating vendor:', error);
      setSuccessMessage('');
      setErrorMessage('Error updating vendor');
    }
  };

  return (
    <Layout title="Update Vendor"><br></br>
    <Loader/>
    <div className="containe">
      <h1 className="mt-4 color-bright text-center">Update Vendor Details</h1><br></br>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-4" style={{marginLeft:"20%",marginTop:"30px"}}>
            <div className="form-group">
              <label>Vendor Name</label>
              <input type="text" className="form-control input-p" name="VendorName" value={vendor.VendorName} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Vendor State</label>
              <input type="text" className="form-control input-p" name="VendorState" value={vendor.VendorState} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Vendor Phone Number</label>
              <input type="text" className="form-control input-p" name="VendorPhoneNumber" value={vendor.VendorPhoneNumber} onChange={handleChange} />
            </div>
          </div>
          <div className="col-md-4" style={{marginTop:"30px"}}>
            <div className="form-group">
              <label>Vendor City</label>
              <input type="text" className="form-control input-p" name="VendorCity" value={vendor.VendorCity} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Vendor Email</label>
              <input type="email" className="form-control input-p" name="VendorEmail" value={vendor.VendorEmail} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Vendor Company Name</label>
              <input type="text" className="form-control input-p" name="VendorCompanyName" value={vendor.VendorCompanyName} onChange={handleChange} />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <button type="submit" className="btn btn-primary">Update Vendor</button>
        </div>
      </form>
      {successMessage && (
        <div className="alert alert-success mt-3">
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="alert alert-danger mt-3">
          {errorMessage}
        </div>
      )}
    </div>
  </Layout>
  
  );
};

export default UpdateVendor;
