import React, { useEffect, useState, useRef } from "react";
import Layout from "../core/Layout";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from 'axios';
import Pagination from "react-js-pagination";
import firebase from 'firebase/compat/app';
import Loader from "../frontpage/Loader";

const ListPatients = () => {
  const [getpatients, setPatients] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const itemsPerPage = 10;

  const [hospitalId, setHospitalId] = useState(null);
  const [hospitalName, setHospitalName] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const currentUser = firebase.auth().currentUser;

      if (currentUser) {
        const userId = currentUser.uid;

        try {
          const response = await axios.get('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getUserDataHospital', {
            params: { userId }
          });

          setHospitalId(response.data.HospitalId);
          setHospitalName(response.data.HospitalName);
        
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchPatients = async () => {
      if (hospitalId) {
        console.log("hospitalId:", hospitalId);
        try {
          const response = await axios.get('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/get-patients', {
            params: { hospitalId }
          });
          setPatients(response.data);
        } catch (error) {
          console.error('Error fetching patients: ', error);
        }
      }
    };

    fetchPatients();
  }, [hospitalId]);

  const componentRef = useRef();

  const deleteHandler = async (patientId) => {
    if (window.confirm("Are you sure you want to delete this patient?")) {
      try {
        await axios.delete(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/delete-patient/${hospitalId}/${patientId}`);
        setPatients(getpatients.filter(patient => patient.patientId !== patientId));
      } catch (error) {
        console.error("Error deleting patient:", error);
      }
    }
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredPatients = getpatients.filter(patient => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const nameMatch = patient.Name ? patient.Name.toLowerCase().includes(lowercasedSearchTerm) : false;
    const idMatch = patient.patientId ? patient.patientId.toString().includes(lowercasedSearchTerm) : false;
    const phoneMatch = patient.PhoneNo ? patient.PhoneNo.toString().includes(lowercasedSearchTerm) : false;
    return nameMatch || idMatch || phoneMatch;
  });

  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredPatients.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <Layout title="List Patients" className="container-fluid">
    <Loader/>
      <h1 className="mt-4 color-bright">Patient Management Page</h1>
      <Link to="/add-patient-details" className="btn btn-outline-primary">
        Add Patient Details
      </Link>
      <br /><br />
      <div className="form-group col-md-4">
        <label htmlFor="searchTerm">Search by Name,Patient ID or Phone Number</label>
        <input
          type="text"
          id="searchTerm"
          className="form-control input-shadow"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <div className="table-responsive row" ref={componentRef}>
       
          <table id="listPatients1" cellPadding="0" cellSpacing="0" border="0">
            <thead>
              <tr>
                <th scope="col">Reg Date</th>
                <th scope="col">Id</th>
                <th scope="col">Patient Name</th>
              
                <th scope="col">Cell</th>
                <th scope="col">Gender</th>
                <th scope="col">Patient Status</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
         
            <tbody>
              {currentItems.map((patient, i) => (
                <tr key={i}>
                  <td>{patient.DateofAdmission
                    ? moment(patient.DateofAdmission, ["YYYY-MM-DD", "DD-MM-YYYY"]).format("DD-MM-YYYY")
                    : ''}</td>
                  <td>{patient.idNumber ? patient.idNumber : ''} {patient.patientId ? patient.patientId : ''}</td>
                  <td>{patient.Name}</td>
                 
                  <td>
                    <a href={`https://wa.me/${patient.PhoneNo}`} target="_blank" rel="noopener noreferrer">
                      {patient.PhoneNo}
                    </a>
                  </td>
                  
                  <td>{patient.Gender}</td>
                  <td>{patient.TestResults ? patient.TestResults : ''} {patient.PatientStatus ? patient.PatientStatus : ''}</td>
                  <td>
                    <Link to={`/update-patient/${patient.patientId}`} className="btn btn-primary">
                      Edit
                    </Link>
                  </td>
                  <td>
                    <button className="btn btn-danger" onClick={() => deleteHandler(patient.patientId)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      
      <Pagination
        activePage={activePage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={filteredPatients.length}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
        itemClass="page-item"
        linkClass="page-link"
      />
    </Layout>
  );
};

export default ListPatients;
