import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import Loader from "../frontpage/Loader";

const Logo = () => {
  const [formData, setFormData] = useState({
    userId: "",
    logo: null,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [preview, setPreview] = useState(null);

  const [hospital,setHospital] =useState("");

  console.log("hospital:", hospital);

  const handleChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      logo: file,
    }));
    setPreview(URL.createObjectURL(file));
  };

 
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;
        fetch(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getUserData/${userId}`)
          .then(response => {
            if (!response.ok) {
              throw new Error('User data not found');
            }
            return response.json();
          })
          .then(userData => {
            setHospital(userData.hospital.HospitalId);
          })
          .catch(error => {
            console.error('Error fetching user data:', error);
            setError('Error fetching user data');
          });
      } else {
        setError('User not authenticated');
      }
    });
    return () => unsubscribe();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.logo) {
      setError("Please select a logo to upload");
      return;
    }

    setLoading(true);
    try {
      const storageRef = firebase.storage().ref();
      const logoRef = storageRef.child(`logos/${hospital}/${formData.logo.name}`);
      await logoRef.put(formData.logo);

      const logoUrl = await logoRef.getDownloadURL();


      const logovalue = await fetch(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/logoupdate`,{
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ hospital, url: logoUrl }),
      })

      const CreateData = await logovalue.json();
      console.log("response value:", CreateData);

      setLoading(false);
      alert("Logo updated successfully");
    } catch (error) {
      console.error("Error uploading logo:", error);
      setError("Error uploading logo");
      setLoading(false);
    }
  };

  const [logoUrl, setLogoUrl] = useState("");

  useEffect(() => {
    if (hospital) {
      fetch(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getLogoUrl/${hospital}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.logoUrl) {
            setPreview(data.logoUrl);
          } else {
            setError("Logo URL not found");
          }
        })
        .catch((error) => {
          console.error("Error fetching logo URL:", error);
          setError("Error fetching logo URL");
        });
    }
  }, [hospital]);

  return (
    <div className="row">
      <Layout>
      <Loader/>
        <h1 style={{ color: "#007bff", marginTop: "5px" }}>Upload Logo</h1>
        <form onSubmit={handleSubmit} className="logosubmit" >
          <div className="col-md-4">
            <input
              className="form-control"
              type="file"
              name="logo"
              accept="image/*"
              onChange={handleChange}
            />
          </div>
          {preview && (
            <div className="col-md-4" style={{ marginTop: "10px" }}>
              <img src={preview} alt="Logo Preview" style={{ width: "100%", height: "auto" }} />
            </div>
          )}
          <button type="submit" className="btn btn-primary text-center logobutton" style={{ marginTop: "10px", marginLeft: "80px" }}>
            Update
          </button>
        </form>
      </Layout>
    </div>
  );
};

export default Logo;
