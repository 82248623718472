import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import TagInput from "./Taginput.js"; // Import the TagInput component
import axios from "axios";
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';


const AdminExtraFields = () => {

    
    const[formData1, setFormData1]= useState({
        userId:"",
        HospitalName:"",
      })
  
      const[HospitalName,setHospitalName]=useState("");
  
      useEffect(()=>{
        const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
          if (currentUser) {
            const userId = currentUser.uid;
    
            setFormData1((prevData) => ({
              ...prevData,
              userId: userId,
              HospitalName: HospitalName,
            }));
    
            try {
              const response = await axios.get(
                `https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getHospitalName/${userId}`
              );
              const addressData = response.data;
              setFormData1((prevData) => ({
                ...prevData,
                HospitalName: addressData.HospitalName || " ",
                HospitalId: addressData.HospitalId || " ",
              }));

              const extraFieldsResponse = await axios.get(
                `https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getAdminExtraFields/${addressData.HospitalId}`
            );
            const extraFieldsData = extraFieldsResponse.data;

            // Set the fetched data to state
            setOccupationTags(extraFieldsData.Occupation || []);
            setSpecializationTags(extraFieldsData.Specialization || []);
            setClinicExpenseTags(extraFieldsData.ClinicExpenseCategory || []);
            setInpatientOutpatientTags(extraFieldsData.InpatientOutpatient || []);
            setPatientStatusTags(extraFieldsData.PatientStatus || []);
            setAppointmentMessage(extraFieldsData.AppointmentMessage || "");
            setPaymentTypeTags(extraFieldsData.PaymentType || []);
            setTableData(extraFieldsData.BillCategory || [{ name: "", quantity: 0, price: 0 }]);
            setTableData1(extraFieldsData.VendorCategory || [{ name: "", quantity: 0, price: 0 }]);
            } catch (error) {
              console.error("Error fetching user data:", error);
             
            } finally {
              
            }
          } else {
           
          }
        });
    
        return () => unsubscribe();
      }, []);
  
  
  const [occupationTags, setOccupationTags] = useState([]);
  const [specializationTags, setSpecializationTags] = useState([]);
  const [clinicExpenseTags, setClinicExpenseTags] = useState([]);
  const [inputValueOccupation, setInputValueOccupation] = useState("");
  const [inputValueSpecialization, setInputValueSpecialization] = useState("");
  const [inputValueClinicExpense, setInputValueClinicExpense] = useState("");

  const [successMessage, setSuccessMessage] = useState(""); 

  const [inpatientOutpatientTags, setInpatientOutpatientTags] = useState([]);
  const [patientStatusTags, setPatientStatusTags] = useState([]);
  const [inputValueInpatientOutpatient, setInputValueInpatientOutpatient] = useState("");
  const [inputValuePatientStatus, setInputValuePatientStatus] = useState("");

  const [appointmentMessage, setAppointmentMessage] = useState("");

  const [paymentTypeTags, setPaymentTypeTags] = useState([]);
  const [inputValuePaymentType, setInputValuePaymentType] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      Occupation: occupationTags,
      Specialization: specializationTags,
      ClinicExpenseCategory: clinicExpenseTags,
      InpatientOutpatient: inpatientOutpatientTags,
      PatientStatus: patientStatusTags,
      AppointmentMessage: appointmentMessage,
      TableData: tableData,
      TableData1: tableData1,
      PaymentType: paymentTypeTags,
      HospitalId: formData1.HospitalId
    };

    try {
      const response = await axios.post('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/addAdminExtraFields', formData);
      setSuccessMessage("Admin extra fields saved successfully!");
      console.log('Admin extra fields saved:', response.data);
    } catch (error) {
      setSuccessMessage("");
      console.error('Error saving admin extra fields:', error);
    }
  };

  const [tableData, setTableData] = useState([
    { name: "", quantity: 0, price: 0 }
  ]);

  const [tableData1, setTableData1] = useState([
    { name: "", quantity: 0, price: 0}
  ]);

  const handleTableChange = (index, field, value) => {
    const updatedTableData = [...tableData];
    updatedTableData[index][field] = value;
    setTableData(updatedTableData);
  };

  const handleTableChange1 = (index, field, value) => {
    const updatedTableData = [...tableData1];
    updatedTableData[index][field] = value;
    setTableData1(updatedTableData);
  };

  const addRow = () => {
    setTableData([...tableData, { name: "", quantity: 0, price: 0 }]);
  };

  const removeRow = (index) => {
    const updatedTableData = tableData.filter((_, i) => i !== index);
    setTableData(updatedTableData);
  };

  const addRow1 = () => {
    setTableData1([...tableData1, { name: "", quantity: 0, price: 0 }]);
  };

  const removeRow1 = (index) => {
    const updatedTableData = tableData1.filter((_, i) => i !== index);
    setTableData1(updatedTableData);
  };

  return (
    <Layout>
      <form onSubmit={handleSubmit}>
        <h1 style={{ color: "#007bff" }} className="mb-4">Admin Extra Fields Title</h1>

        <div className="form-group col-md-6">
          <TagInput
            label="Occupation"
            tags={occupationTags}
            setTags={setOccupationTags}
            inputValue={inputValueOccupation}
            setInputValue={setInputValueOccupation}
          />
        </div>

        <div className="form-group col-md-6">
          <TagInput
            label="Specialization"
            tags={specializationTags}
            setTags={setSpecializationTags}
            inputValue={inputValueSpecialization}
            setInputValue={setInputValueSpecialization}
          />
        </div>

        <div className="form-group col-md-6">
          <TagInput
            label="Clinic Expense Category"
            tags={clinicExpenseTags}
            setTags={setClinicExpenseTags}
            inputValue={inputValueClinicExpense}
            setInputValue={setInputValueClinicExpense}
          />
        </div>
        <div className="form-group col-md-6">
            <TagInput
              label="Inpatient/Outpatient"
              tags={inpatientOutpatientTags}
              setTags={setInpatientOutpatientTags}
              inputValue={inputValueInpatientOutpatient}
              setInputValue={setInputValueInpatientOutpatient}
            />
          </div>

          <div className="form-group col-md-6">
            <TagInput
              label="Patient Status"
              tags={patientStatusTags}
              setTags={setPatientStatusTags}
              inputValue={inputValuePatientStatus}
              setInputValue={setInputValuePatientStatus}
            />
          </div>

          <div className="form-group col-md-6">
            <TagInput
              label="Payment Type"
              tags={paymentTypeTags}
              setTags={setPaymentTypeTags}
              inputValue={inputValuePaymentType}
              setInputValue={setInputValuePaymentType}
            />
          </div>

          <div className="form-group col-md-6">
            <label><span style={{fontWeight:"bold"}}>Appointment Message</span> - Name,Date,Time,Doctor Name each value add like this "$-flower Bracket"</label>
            <textarea
              className="form-control"
              placeholder="Give like this ${name},${date},${time},${doctorname}"
              value={appointmentMessage}
              onChange={(e) => setAppointmentMessage(e.target.value)}
              rows={4} // You can adjust the number of rows as needed
            />
          </div>
          <h5>Split Bill Category</h5>
                <table className="table1">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((row, index) => (
                            <tr key={index}>
                                <td>
                                    <input
                                        type="text"
                                        value={row.name}
                                        onChange={(e) => handleTableChange(index, 'name', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        value={row.quantity}
                                        onChange={(e) => handleTableChange(index, 'quantity', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        value={row.price}
                                        onChange={(e) => handleTableChange(index, 'price', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <button type="button" onClick={() => removeRow(index)}>Remove</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button type="button" onClick={addRow} className="btn btn-secondary">Add Row</button>

                <br/><br/>
                <h5>Vendor Bill Category</h5>
                <table className="table1">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData1.map((row, index) => (
                            <tr key={index}>
                                <td>
                                    <input
                                        type="text"
                                        value={row.name}
                                        onChange={(e) => handleTableChange1(index, 'name', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        value={row.quantity}
                                        onChange={(e) => handleTableChange1(index, 'quantity', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        value={row.price}
                                        onChange={(e) => handleTableChange1(index, 'price', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <button type="button" onClick={() => removeRow1(index)}>Remove</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button type="button" onClick={addRow1} className="btn btn-secondary">Add Row</button>

                <br/><br/>

        <button type="submit" className="btn btn-primary buttoncenter">Submit</button>
      </form><br></br>
      {successMessage && (
          <div className="alert alert-success">
            {successMessage}
          </div>
        )}
    </Layout>
  );
};

export default AdminExtraFields;
