import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { BiTrash } from "react-icons/bi";
import Select from 'react-select';
import "./Invoice.css";

class InvoiceItem1 extends React.Component {
  handleCheckboxChange = (itemId, updatedSessions) => {
    const updatedItems = this.props.items.map(item => {
      if (item.id === itemId) {
        return { ...item, sessions: updatedSessions };
      }
      return item;
    });
    this.props.onItemizedItemEdit({ target: { id: itemId, name: 'sessions', value: updatedSessions } });
  }
  
  render() {
    const { onItemizedItemEdit, currency, onRowDel, medicineOptions, items, onRowAdd } = this.props;
    
    if (!Array.isArray(items)) {
      console.error('Expected items to be an array, but got:', typeof items, items);
      return null;
    }

    const itemTable = items.map((item, index) => (
      <ItemRow
        index={index}
        onItemizedItemEdit={onItemizedItemEdit}
        item={item}
        onDelEvent={onRowDel}
        key={item.id}
        currency={currency}
        medicineOptions={medicineOptions}
        handleCalculateTotal={this.props.handleCalculateTotal}
        onCheckboxChange={this.handleCheckboxChange}
      />
    ));
    
    return (
      <div>
        <Table>
          <thead>
            <tr>
              <th className='text-left select-cell1'>S.No</th>
              <th className='text-center select-cell2'>ITEM</th>
             
              <th className='text-center quantity-cell'>Quantity</th>
              <th className='text-center select-cell3'>Days</th>
              <th className="text-center action-cell">ACTION</th>
            </tr>
          </thead>
          <tbody>
            {itemTable}
          </tbody>
        </Table>
        <Button className="fw-bold" onClick={onRowAdd}>Add Item</Button>
      </div>
    );
  }
}

class ItemRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      optionFrom: '',
      optionPrice: '',
      optionValue: '',
      availableQuantity: Infinity,
      quantities: {
        morningBefore: 0,
        morningAfter: 0,
        noonBefore: 0,
        noonAfter: 0,
        eveningBefore: 0,
        eveningAfter: 0,
        nightBefore: 0,
        nightAfter: 0
      },
      days: 0 
    };
  }

  handleDelEvent = () => {
    this.props.onDelEvent(this.props.item);
  }

  handleMedicineSelect = (selectedOption) => {
    const { item, onItemizedItemEdit, handleCalculateTotal } = this.props;
    const { value, label, price, availableQuantity } = selectedOption;

    this.setState({
      optionFrom: label,
      optionPrice: price,
      optionValue: value,
      availableQuantity: availableQuantity,
    });
  
    const event = {
      target: {
        id: item.id,
        name: 'name',
        value: label
      }
    };
    onItemizedItemEdit(event);

    const priceEvent = {
      target: {
        id: item.id,
        name: 'price',
        value: price
      }
    };
    onItemizedItemEdit(priceEvent);

    const medicineIdEvent = {
      target: {
        id: item.id,
        name: 'medicineId',
        value: value
      }
    };
    onItemizedItemEdit(medicineIdEvent);

    handleCalculateTotal();
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    const { onItemizedItemEdit } = this.props;
    const { availableQuantity, quantities, days } = this.state;
    
    let inputValue = parseInt(value) || 0;
    
    if (name === "days") {
      // When changing the number of days, calculate the total amount
      const calculatedQuantity = Object.values(quantities).reduce((sum, qty) => sum + qty * inputValue, 0);
      
      // Limit to available quantity if the calculated total exceeds availableQuantity
      const adjustedQuantity = calculatedQuantity > availableQuantity ? availableQuantity : calculatedQuantity;
      
      this.setState({ days: inputValue });
  
      // Trigger onItemizedItemEdit to update `days` in parent component
      onItemizedItemEdit({
        target: {
          id: this.props.item.id,
          name: "days",
          value: inputValue
        }
      });
  
      // Optionally update calculated quantity for display or other needs
      onItemizedItemEdit({
        target: {
          id: this.props.item.id,
          name: "totalCalculatedQuantity",
          value: adjustedQuantity
        }
      });
  
    } else {
      // Handle individual time-period quantity changes
      this.setState(prevState => {
        const updatedQuantities = { ...prevState.quantities, [name]: inputValue };
        const totalQuantity = Object.values(updatedQuantities).reduce((sum, qty) => sum + qty, 0);
  
        // Limit total of quantities to availableQuantity if it exceeds it
        const adjustedQuantities = { ...updatedQuantities };
        if (totalQuantity > availableQuantity) {
          const excess = totalQuantity - availableQuantity;
          adjustedQuantities[name] = inputValue - excess;  // Adjust current input to fit limit
        }
  
        // Update state and inform parent of changes
        onItemizedItemEdit({
          target: {
            id: this.props.item.id,
            name: 'quantities',
            value: adjustedQuantities
          }
        });
  
        return { quantities: adjustedQuantities };
      });
    }
  };
  


  handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    this.setState(prevState => {
      const updatedSessions = { ...prevState.sessions, [name]: checked };
      this.props.onCheckboxChange(this.props.item.id, updatedSessions); // Call parent method
      return { sessions: updatedSessions };
    });
  }
  
  render() {
    const { medicineOptions, item, onItemizedItemEdit, currency, index } = this.props;
    const { optionFrom, optionPrice, optionValue, sessions, availableQuantity, quantities, days } = this.state;

    return (
      <tr className="table-row1">
        <td className=" select-cell2">{index + 1}</td>
        <td className="select-cell1">
          <Select
            options={medicineOptions}
            onChange={this.handleMedicineSelect}
            placeholder="Select a medicine..."
            formatOptionLabel={option => (
              <div>{option.value} - {option.label}(Available: {option.availableQuantity})</div>
            )}
            value={medicineOptions.find(option => option.value === item.medicineId) || null}
            className="my-2"
          />
        </td>
        <td className="session-quantity-cell text-center">
          <table className="nested-table">
            <thead>
              <tr>
                <th></th>
                {['Mor', 'Noon', 'Eve', 'Nig'].map(time => (
                  <th key={time}>{time}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {['Before', 'After'].map(period => (
                <tr key={period}>
                  <td>{period}</td>
                  {['morning', 'noon', 'evening', 'night'].map(time => (
                    <td key={`${time}${period}`}>
                      <input
                        type="number"
                        className="form-control"
                        name={`${time}${period}`}
                        value={quantities[`${time}${period}`]}
                        onChange={this.handleInputChange}
                        max={availableQuantity}
                        min="0"
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </td>
        <td className="select-cell3">
          <input type="number" className='form-control' style={{width:"80px"}}  name="days"
            value={days}
            onChange={this.handleInputChange} 
            min="0"></input>
        </td>
        <td className="action-cell">
          <BiTrash onClick={this.handleDelEvent} style={{ height: '33px', width: '33px', padding: '7.5px' }} className="text-white mt-1 btn btn-danger" />
        </td>
      </tr>
    );
  }
}

export default InvoiceItem1;
