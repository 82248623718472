import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Layout from "../core/Layout";
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import axios from "axios";
import Loader from "../frontpage/Loader";

const firebaseConfig = {
  apiKey: "AIzaSyCI59gnHA9AZAANjoy082vht3fCgatchEg",
  authDomain: "hospital-management-syst-7e35d.firebaseapp.com",
  databaseURL: "https://hospital-management-syst-7e35d-default-rtdb.firebaseio.com",
  projectId: "hospital-management-syst-7e35d",
  storageBucket: "hospital-management-syst-7e35d.firebasestorage.app",
  messagingSenderId: "59517358381",
  appId: "1:59517358381:web:96f502e3832ddf1a8fc3a4",
  measurementId: "G-EYLWMBT9SP"
};

firebase.initializeApp(firebaseConfig);

const DoctorDetails = ({ users, genders, status, types }) => {
  const [Name, setName] = useState("");
  const [RegistrationDate, setRegDate] = useState(new Date());
  const [Address, setAddress] = useState("");
  const [PhoneNo, setPhoneNo] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [Residence, setResidence] = useState("");
  const [Email, setEmail] = useState("");
  const [Fee, setFee] = useState("");
  const [Gender, setGender] = useState("");
  const [UploadPhoto, setUploading] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [photoName, setPhotoName] = useState('Choose file');
  const [specializationOptions, setSpecializationOptions] = useState([]);


  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = {
      Name,
      RegistrationDate,
      Address,
      PhoneNo,
      speciality,
      Residence,
      Email,
      Fee,
      Gender,
      // UploadPhoto: UploadPhoto ? UploadPhoto.name : '' || null,
      HospitalId: formData1.HospitalId
    };

    try {

      const response = await fetch(
        `https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/PrescriptioncheckDoctorDuplicate`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ PhoneNo: formData.PhoneNo, Email: formData.Email, HospitalId: formData1.HospitalId })
        }
      );
  
      const duplicateCheck = await response.json();
      if (duplicateCheck.exists) {
        setErrorMessage("Already have same duplicate values of number and email.");
        setSuccessMessage("");
        return; // Stop further processing
      }

      let uploadPhotoUrl = null;
      if (UploadPhoto) {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(UploadPhoto.name);
        await fileRef.put(UploadPhoto);
        uploadPhotoUrl = await fileRef.getDownloadURL();
      }

      const formDataWithImage = uploadPhotoUrl ? { ...formData, UploadPhoto: uploadPhotoUrl } : formData;

      const submitResponse = await fetch('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/doctor-submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formDataWithImage),
      });

      const result = await submitResponse.json();
      if (submitResponse.ok) {
        setSuccessMessage(result.message);
        setErrorMessage('');
      } else {
        throw new Error(result.error);
      }
    } catch (error) {
      console.error('Error submitting form: ', error);
      setSuccessMessage('');
      setErrorMessage('Error submitting form.');
    }
  };

  const uploadFileHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploading(file);
      setPhotoName(file.name);
    }
  };

  const[formData1, setFormData1]= useState({
    userId:"",
    HospitalName:"",
  })

  const[HospitalName,setHospitalName]=useState("");

  useEffect(()=>{
    const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;

        setFormData1((prevData) => ({
          ...prevData,
          userId: userId,
          HospitalName: HospitalName,
        }));

        try {
          const response = await axios.get(
            `https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getHospitalName/${userId}`
          );
          const addressData = response.data;
          setFormData1((prevData) => ({
            ...prevData,
            HospitalName: addressData.HospitalName || " ",
            HospitalId: addressData.HospitalId || " ",
          }));

         const specializationResponse = await axios.get(
            `https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getSpecialization/${addressData.HospitalId}`
          );
          setSpecializationOptions(specializationResponse.data.Specialization || []);
        } catch (error) {
          console.error("Error fetching user data:", error);
         
        } finally {
          
        }
      } else {
       
      }
    });

    return () => unsubscribe();
  }, []);


  return (
    <Layout>
    <Loader/>
    <h1 className="mt-4 color-bright">Add Doctor Details</h1>
      <div className="containerAddPatient">
        <div className="row">
          <form className="form-card" onSubmit={submitHandler} >
            <div className="formtype">
            <div className="card Addpatientcard mb-4">
              <div className="card-header" style={{backgroundColor:"lightblue"}}>
                <h5>Personal Details</h5>
              </div>
              <div className="card-body">
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputName">Name</label>
                    <input type="text" className="form-control input-shadow input-p" id="inputName" placeholder="Name" value={Name} onChange={(e) => setName(e.target.value)} />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputAddress">Address</label>
                    <textarea className="form-control input-bg-light-grey input-shadow3" id="inputAddress" placeholder="Address" rows="3" value={Address} onChange={(e) => setAddress(e.target.value)} />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputPhone">Phone Number</label>
                    <input type="text" className="form-control input-shadow input-p" id="inputPhone" placeholder="Phone no" value={PhoneNo} onChange={(e) => setPhoneNo(e.target.value)} />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputEmail">Email</label>
                    <input type="email" className="form-control input-shadow input-p" id="inputEmail" placeholder="Email" value={Email} onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputDaughter">Hospital Name</label>
                    <input type="text" className="form-control input-shadow input-p" id="inputName" placeholder="Name" value={formData1.HospitalName} disabled/>
                </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputResidence">Residence</label>
                    <input type="text" className="form-control input-shadow input-p" id="inputResidence" placeholder="Residence" value={Residence} onChange={(e) => setResidence(e.target.value)} />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputGender">Gender</label>
                    <select className="form-control styled-select drop-down-patient-form" id="inputGender" value={Gender} onChange={(e) => setGender(e.target.value)}>
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputPhoto">Upload Photo</label>
                    <div className="custom-file">
                      <input type="file" className="custom-file-input label-blue" id="inputPhoto" onChange={uploadFileHandler} />
                      <label className="custom-file-label" htmlFor="inputPhoto">{photoName}</label>
                    </div>
                    {UploadPhoto && (
                      <div className="d-flex justify-content-center">
                        {/* <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-4" style={{marginLeft:"20px"}}>
              <div className="card-header" style={{backgroundColor:"lightblue"}}>
                <h5>Professional Details</h5>
              </div>
              <div className="card-body">
                <div className="form-row">
                  <div className="form-group col-md-7">
                    <label className="font-weight-bold" htmlFor="inputRegDate">Registration Date</label>
                    <div className="input-container">
                      <DatePicker selected={RegistrationDate} onChange={(date) => setRegDate(date)} className="form-control input-shadow1 .date-p" id="inputRegDate" placeholderText="Select date" />
                      <div className="icon-container">
                        <i className="fa fa-calendar" style={{ color: "blue" }}></i>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputSpeciality">Specialization</label>
                    <select className="form-control styled-select drop-down-patient-form" id="inputSpeciality" value={speciality} onChange={(e) => setSpeciality(e.target.value)}>
                    <option>Select</option>
                    {specializationOptions.map((specialization, index) => (
                      <option key={index} value={specialization}>{specialization}</option>
                    ))}
                    </select>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputFee">Fee</label>
                    <input type="text" className="form-control input-shadow input-p" id="inputFee" placeholder="Fee" value={Fee} onChange={(e) => setFee(e.target.value)} />
                  </div>
                </div>
              </div>
            </div>
            </div>
            <button type="submit" className="btn btn-primary save-p" style={{ width: "20%", textAlign: "center", marginLeft:"40%" }}>Save</button>
          </form>
          {successMessage && (
            <div className="alert alert-success mt-3" role="alert">
              {successMessage}
            </div>
          )}
          {errorMessage && (
            <div className="alert alert-danger mt-3" role="alert">
              {errorMessage}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default DoctorDetails;
