import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { BiTrash } from "react-icons/bi";
import EditableField from './EditableField';
import Select from 'react-select';
import "./Invoice.css";

class VendorSplitInvoiceItem3 extends React.Component {
  render() {
    const { onItemizedItemEdit, currency, onRowDel, medicineOptions, billCategoryOptions, items, onRowAdd } = this.props;
    
    const itemTable = items.map(item => (
      <ItemRow
        onItemizedItemEdit={onItemizedItemEdit}
        item={item}
        onDelEvent={onRowDel}
        key={item.id}
        currency={currency}
        medicineOptions={medicineOptions}
        billCategoryOptions={billCategoryOptions}
        handleCalculateTotal={this.props.handleCalculateTotal}
      />
    ));
    
    return (
      <div>
        <Table>
          <thead>
            <tr>
              <th className='text-center'>CATEGORY</th>
              <th className='text-center'>QTY</th>
              <th className='text-right'>PRICE/RATE</th>
              <th className="text-right">AMOUNT</th>
              <th className="text-center">ACTION</th>
            </tr>
          </thead>
          <tbody>
            {itemTable}
          </tbody>
        </Table>
        <div className="d-flex justify-content-between">
          <Button className="fw-bold" onClick={onRowAdd}>Add Item</Button>
          {/* <Button className="fw-bold" onClick={this.props.handleAddSplitEvent}>Add Split</Button> */}
        </div>
      </div>
    );

  }

}
class ItemRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      optionFrom: '',
      optionPrice: '',
      optionValue: '',
      selectedCategory: null,
    };
  }

  handleDelEvent = () => {
    this.props.onDelEvent(this.props.item);
  }

  
  handleCategorySelect = (selectedOption) => {
    const { item, onItemizedItemEdit, handleCalculateTotal, billCategoryOptions } = this.props;
  
    // Update the selectedCategory in the item's state
    onItemizedItemEdit({
      target: {
        id: item.id,
        name: 'selectedCategory',
        value: selectedOption.value,
      },
    });
  
    this.setState({ selectedCategory: selectedOption.value });
  
    if (selectedOption.value !== 'Medicine') {
      const selectedCategory = billCategoryOptions.find(option => option.value === selectedOption.value);
  
      onItemizedItemEdit({
        target: {
          id: item.id,
          name: 'quantity',
          value: selectedCategory.availableQuantity || 1,
        },
      });
  
      onItemizedItemEdit({
        target: {
          id: item.id,
          name: 'price',
          value: selectedCategory.price || 0,
        },
      });
  
      handleCalculateTotal();
    } else {
      this.setState({
        optionFrom: '',
        optionPrice: '',
        optionValue: '',

      });
    }
  };
  


  handleMedicineSelect = (selectedOption) => {
    const { item, onItemizedItemEdit , handleCalculateTotal } = this.props;
    const { value, label, price, availableQuantity } = selectedOption;

    this.setState({
      optionFrom: label,
      optionPrice: price,
      optionValue: value,
      availableQuantity: availableQuantity,
    });
  
    const event = {
      target: {
        id: item.id,
        name: 'name',
        value: label
      }
    };
    onItemizedItemEdit(event);

    const priceEvent = {
      target: {
        id: item.id,
        name: 'price',
        value: price
      }
    };
    onItemizedItemEdit(priceEvent);

    const medicineIdEvent = {
      target: {
        id: item.id,
        name: 'medicineId',
        value: value
      }
    };
    onItemizedItemEdit(medicineIdEvent);

    handleCalculateTotal();
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    const { onItemizedItemEdit, handleCalculateTotal } = this.props;
    const { availableQuantity } = this.state;

    let inputValue = parseFloat(value);

    // If quantity exceeds availableQuantity, set it to availableQuantity
    if (name === 'quantity' && inputValue > availableQuantity) {
      inputValue = availableQuantity;
    }

    onItemizedItemEdit({
      target: {
        id: event.target.id,
        name: name,
        value: inputValue,
      },
    });

    handleCalculateTotal();
  }
  
  render() {
    const { medicineOptions, billCategoryOptions=[], item, onItemizedItemEdit, currency } = this.props;
    const { optionFrom, optionPrice, optionValue,  availableQuantity, selectedCategory} = this.state;

    const quantity = parseFloat(item.quantity) || 0;
    const price = parseFloat(item.price) || 0;
    const amount = quantity * price;

    const categoryOptions = [
      ...billCategoryOptions, 
    ];

    return (
      <tr class="table-row">
       {item.type === 'split' ? (
          <>
            {/* Medicine dropdown */}
            <td>

            </td>
            
            <td className="select-cell">
            {item.selectedCategory === 'Medicine' && (
              <Select
                options={medicineOptions}
                onChange={this.handleMedicineSelect}
                placeholder="Select a medicine..."
                formatOptionLabel={(option) => (
                  <div>
                    {option.value} - {option.label} (Available: {option.availableQuantity})
                  </div>
                )}
                value={medicineOptions.find(option => option.value === item.medicineId) || null}
                className="my-2"
              />
            )}
            </td>

            {/* Quantity */}
            <td className="quantity-cell">
              <input
                type="number"
                className="form-control"
                name="quantity"
                id={item.id}
                value={item.quantity}
                onChange={this.handleInputChange}
                max={availableQuantity} // Limit by available quantity
                min="0"
              />
            </td>

            {/* Price */}
            <td className="price-cell" style={{ paddingLeft: '80px' }}>
              <input
                type="number"
                className="form-control"
                name="price"
                id={item.id}
                value={item.price}
                onChange={this.handleInputChange}
                disabled
              />
            </td>

            {/* Amount */}
            <td className="amount-cell">
              <span>{currency} {amount.toFixed(2)}</span>
            </td>

            {/* Delete icon */}
            <td className="action-cell">
              <BiTrash
                onClick={this.handleDelEvent}
                style={{ height: '33px', width: '33px', padding: '7.5px' }}
                className="text-white mt-1 btn btn-danger"
              />
            </td>
          </>
        ) : (
          // Render the default row structure if not a split row
          <>
       <td className="select-cell">
          <Select
            options={categoryOptions}
            onChange={this.handleCategorySelect}
            placeholder="Select category..."
            value={categoryOptions.find(option => option.value === item.selectedCategory) || null}
            className="my-2"
          />
        </td>
        {/* {item.selectedCategory === 'Medicine' ? (
      <td class="select-cell">
        <Select
          options={medicineOptions}
          onChange={this.handleMedicineSelect}
          placeholder="Select a medicine..."
          formatOptionLabel={(option) => (
              <div>{option.value} - {option.label}(Available: {option.availableQuantity})</div>
            )}
          value={medicineOptions.find((option)=> option.value === item.medicineId) || null}
          className="my-2"
        />
      </td>
        ):(
          <td className="select-cell">
            
          </td>
        )} */}
      <td class="quantity-cell">
      <input
            type="number"
            className="form-control"
            name="quantity"
            id={item.id}
            value={item.quantity}
            onChange={this.handleInputChange}
            max={availableQuantity}
            min="0"
          />
      </td>
        
      <td className="price-cell" style={{paddingLeft:"50px"}}> 
          <input
            type="number"
            className="form-control"
            name="price"
            id={item.id}
            value={item.price || optionPrice}
            onChange={this.handleInputChange}
          />
        </td>
      <td class="amount-cell">
          <span>{currency} {amount.toFixed(2)}</span>
      </td>
      <td class="action-cell">
        <BiTrash onClick={this.handleDelEvent} style={{ height: '33px', width: '33px', padding: '7.5px' }} className="text-white mt-1 btn btn-danger" />
      </td>
      </>
        )}
    </tr>
  );

  }
}

export default VendorSplitInvoiceItem3;