import React,{useState, useEffect, Fragment} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminDashboard from "./user/AdminDashboard";
import Profile from "./user/Profile";
import ListUsers from "./admin/ListUsers";
import ListExpenses from "./admin/ListExpenses";
import AddExpense from "./admin/AddExpense";
import UpdateExpenses from "./admin/UpdateExpenses";
import ListPatients from "./admin/ListPatients";
import AddPatientDetails from "./admin/AddPatientDetails";
import UpdatePatientProfile from "./admin/UpdatePatientProfile";

import ListDoctors from "./admin/ListDoctors";
import AddDoctorDetails from "./admin/AddDoctorDetails";
import UpdateDoctorProfile from "./admin/UpdateDoctorProfile";
import ListMedicine from "./admin/ListMedicine";
import AddMedicine from "./admin/AddMedicine";
import UpdateMedicine from "./admin/UpdateMedicine";


import ListVaccineCat from "./admin/ListVaccineCat";
import AddVaccineCat from "./admin/AddVaccineCat";
import UpdateVaccineCat from "./admin/UpdateVaccineCat";

import ListAppVaccine from "./admin/ListAppVaccine";
import AddAppVaccine from "./admin/AddAppVaccine";
import UpdateVaccApp from "./admin/UpdateVaccineCat";
import { Link , useParams, useNavigate} from "react-router-dom";
import ListVendors from "./admin/ListVendors";
import FileUpload from "./admin/FileUpload";
import InvoiceForm from "./admin/CreateBill";
import UpdateVendor from "./admin/UpdateVendor";

import ListBill from "./admin/Listbill";
import InvoiceForm1 from "./admin/Updatebill";

import InvoiceForm2 from "./admin/QuickBill";
import LoginForm from './signin';
import SignUpForm from "./signup";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import AdminAppointmentForm from "./admin/AdminAppointment";
import Appointment from "./admin/Appointment";
import Reports from "./admin/Reports";
import PatientStatus from "./user/PatientStatus";
import Reviews from "./admin/Reviews";
import MedicineStatus from "./user/MedicineStatus";
import NormalUser from "./admin/NormalUser";
import UpdateUsers from "./admin/UpdateUsers";
import SocialMedia from "./admin/SocialMedia";
import SocialAddress from "./admin/SocialAddress";
import Logo from "./admin/Logo";
import AddHospital from "./admin/AddHospital";
import Prescription from "./admin/Prescription";
import ListPrescription from "./admin/ListPrescription";
import InvoiceForm3 from "./admin/UPrescription";
import ListHospitals from "./admin/ListHospitals";
import UpdateHospital from "./admin/UpdateHospital";
import Frontpage from "./frontpage/Frontpage";
import FeaturesPage from "./frontpage/Featurespage";

import Whypage from "./frontpage/WhyHMS";
import AdminSettings from "./admin/AdminSettings";
import AdminExtraFields from "./admin/AdminExtraFields";

import AddClinicExpense from "./admin/AddClinicExpenses";
import ListClinicExpenses from "./admin/ListClinicExpenses";
import UpdateClinicExpenses from "./admin/UpdateClinicExpenses";
import SplitInvoiceForm from "./admin/CreateSplitBill";
import ListSplitBill from "./admin/ListSplitbill";
import SplitInvoiceForm1 from "./admin/UpdateSplitbill";
import VendorInvoiceForm from "./admin/CreateVendorBill";
import ListVendorBill from "./admin/ListVendorbill";
import VendorSplitInvoiceForm1 from "./admin/UpdateVendorbill";

const App=()=> {
  
  const [status, setStatus] = useState('');
  const [roleId, setRoleId] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;
        fetch(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getUserData/${userId}`)
          .then(response => {
            if (!response.ok) {
              throw new Error('User data not found');
            }
            return response.json();
          })
          .then(userData => {
            setStatus(userData.status || '');
            setRoleId(userData.roleId || null);
          })
          .catch(error => {
            console.error('Error fetching user data:', error);
            setError('Error fetching user data');
          });
      } else {
        setError('User not authenticated');
      }
    });
    return () => unsubscribe();
  }, []);

  if( roleId === 1 && status==='Passed'){

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/sales&Revenue" element={<AdminDashboard />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/list/users" element={<ListUsers />} />
        <Route path="/list-expenses" element={<ListExpenses />} />
        <Route path="/add-expenses" element={<AddExpense />} />
        <Route path="/update-expenses/:expenseId" element={<UpdateExpenses />} />
        <Route path="/list-patients" element={<ListPatients />} />
        <Route path="/add-patient-details" element={<AddPatientDetails />} />
        <Route path="/update-patient/:patientId" element={<UpdatePatientProfile />} />

        <Route path="/list-doctors" element={<ListDoctors />} />
        <Route path="/add-doctor" element={<AddDoctorDetails />} />
        <Route path="/update-doctor/:doctorId" element={<UpdateDoctorProfile />} />
        <Route path="/list/medicine" element={<ListMedicine />} />
        <Route path="/add-medicine" element={<AddMedicine />} />
        <Route path="/update-medicine/:medicineId" element={<UpdateMedicine />} />

        <Route path="/list-vaccine-cat" element={<ListVaccineCat />} />
        <Route path="/add-vac-cat" element={<AddVaccineCat />} />
        <Route path="/update-vaccine-cat/:id" element={<UpdateVaccineCat />} />

        <Route path="/list-app-vaccine" element={<ListAppVaccine />} />
        <Route path="/add-vacc-app" element={<AddAppVaccine />} /> 
        <Route path="/update-vacc-app" element={<UpdateVaccApp />} />

        <Route path="/list-vendors" element={<ListVendors />} />
        <Route path="/file-upload" element={<FileUpload />} />
        <Route path="/UpdateVendor/:vendorId" element={<UpdateVendor/>}/>
        <Route path="/create-bill" element={<InvoiceForm />} />
        <Route path="/List-bill" element={<ListBill/>}/>
        <Route path="/Update-bill/:invoice" element={<InvoiceForm1/>}/>
        <Route path="/invoices/:id" component={InvoiceForm1} />

        <Route path="/quickbill" element={<InvoiceForm2/>}/>
        <Route path="/signin" element={<LoginForm/>}/>
        <Route path="/signup" element={<SignUpForm/>}/>
        <Route path="/settingsAdmin" element={<AdminAppointmentForm/>}/>
        <Route path="/appointment" element={<Appointment/>}/>
        <Route path="/reports" element={<Reports/>}/>
        <Route path="/patientadmin" element={<PatientStatus/>}/>
        <Route path="/reviews" element={<Reviews/>}/>
        <Route path="/medicinedash" element={<MedicineStatus/>}/>
        <Route path="/pending" element={<NormalUser/>}/>
        <Route path="/update-user/:hospitalId/:userId" element={<UpdateUsers/>}/>
        <Route path="/social-media" element={<SocialMedia/>}/>
        <Route path="/address-branding" element={<SocialAddress/>}/>
        <Route path="/logo" element={<Logo/>}/>
        <Route path="/add-Hospital" element={<AddHospital/>}/>
        <Route path="/prescription" element={<Prescription/>}/>
        <Route path="/list-prescription" element={<ListPrescription/>}/>
        <Route path="/update-prescription/:invoiceNumber" element={<InvoiceForm3/>}/>
        <Route path="/list-Hospital" element={<ListHospitals/>}/>
        <Route path="/update-Hospital/:HospitalId" element={<UpdateHospital/>}/>
        <Route path="/" element={<Frontpage/>}/>
        <Route path="/features" element={<FeaturesPage/>}/>
        <Route path="/whyHMS" element={<Whypage/>}/>
        <Route path="/AdminSettings" element={<AdminSettings/>}/>

        <Route path="/AdminExtraFields" element={<AdminExtraFields/>}/>

        <Route path="/AddClinicExpenses" element={<AddClinicExpense/>}/>
        <Route path="/ListClinicExpenses" element={<ListClinicExpenses/>}/>
        <Route path="/UpdateClinicExpenses/:expenseId" element={<UpdateClinicExpenses/>}/>
        <Route path="/split-bill" element={<SplitInvoiceForm/>}/>
        <Route path="/List-split" element={<ListSplitBill/>}/>
        <Route path="/update-split/:invoice" element={<SplitInvoiceForm1/>}/>
        <Route path="/vendorbill" element={<VendorInvoiceForm/>}/>
        <Route path="/List-vendorbill" element={<ListVendorBill/>}/>
        <Route path="/update-vendor/:invoice" element={<VendorSplitInvoiceForm1/>}/>
      </Routes>
      </BrowserRouter>
  );
}
else if(roleId===2 && status === 'Passed'){
  return(
    <BrowserRouter>
    <Routes>
      <Route path="/sales&Revenue" element={<AdminDashboard />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/list/users" element={<ListUsers />} />
      <Route path="/list-expenses" element={<ListExpenses />} />
      <Route path="/add-expenses" element={<AddExpense />} />
      <Route path="/update-expenses/:expenseId" element={<UpdateExpenses />} />
      <Route path="/list-patients" element={<ListPatients />} />
      <Route path="/add-patient-details" element={<AddPatientDetails />} />
      <Route path="/update-patient/:patientId" element={<UpdatePatientProfile />} />

      <Route path="/list-doctors" element={<ListDoctors />} />
      <Route path="/add-doctor" element={<AddDoctorDetails />} />
      <Route path="/update-doctor/:doctorId" element={<UpdateDoctorProfile />} />
      <Route path="/list/medicine" element={<ListMedicine />} />
      <Route path="/add-medicine" element={<AddMedicine />} />
      <Route path="/update-medicine/:medicineId" element={<UpdateMedicine />} />

      <Route path="/list-vaccine-cat" element={<ListVaccineCat />} />
      <Route path="/add-vac-cat" element={<AddVaccineCat />} />
      <Route path="/update-vaccine-cat/:id" element={<UpdateVaccineCat />} />

      <Route path="/list-app-vaccine" element={<ListAppVaccine />} />
      <Route path="/add-vacc-app" element={<AddAppVaccine />} /> 
      <Route path="/update-vacc-app" element={<UpdateVaccApp />} />

      <Route path="/list-vendors" element={<ListVendors />} />
      <Route path="/file-upload" element={<FileUpload />} />
      <Route path="/UpdateVendor/:vendorId" element={<UpdateVendor/>}/>
      <Route path="/create-bill" element={<InvoiceForm />} />
      <Route path="/List-bill" element={<ListBill/>}/>
      <Route path="/Update-bill/:invoice" element={<InvoiceForm1/>}/>
      <Route path="/invoices/:id" component={InvoiceForm1} />

      <Route path="/quickbill" element={<InvoiceForm2/>}/>
      <Route path="/signin" element={<LoginForm/>}/>
      <Route path="/signup" element={<SignUpForm/>}/>
      <Route path="/settingsAdmin" element={<AdminAppointmentForm/>}/>
      <Route path="/appointment" element={<Appointment/>}/>
      <Route path="/reports" element={<Reports/>}/>
      <Route path="/patientadmin" element={<PatientStatus/>}/>
      <Route path="/reviews" element={<Reviews/>}/>
      <Route path="/medicinedash" element={<MedicineStatus/>}/>
      <Route path="/pending" element={<NormalUser/>}/>
      <Route path="/update-user/:hospitalId/:userId" element={<UpdateUsers/>}/>
      <Route path="/social-media" element={<SocialMedia/>}/>
      <Route path="/address-branding" element={<SocialAddress/>}/>
      <Route path="/logo" element={<Logo/>}/>
      <Route path="/prescription" element={<Prescription/>}/>
      <Route path="/list-prescription" element={<ListPrescription/>}/>
      <Route path="/update-prescription/:invoiceNumber" element={<InvoiceForm3/>}/>
      <Route path="/" element={<Frontpage/>}/>
      <Route path="/features" element={<FeaturesPage/>}/>
      <Route path="/whyHMS" element={<Whypage/>}/>
      <Route path="/AdminSettings" element={<AdminSettings/>}/>

      <Route path="/AdminExtraFields" element={<AdminExtraFields/>}/>
      <Route path="/AddClinicExpenses" element={<AddClinicExpense/>}/>
      <Route path="/ListClinicExpenses" element={<ListClinicExpenses/>}/>
      <Route path="/UpdateClinicExpenses/:expenseId" element={<UpdateClinicExpenses/>}/>
      <Route path="/split-bill" element={<SplitInvoiceForm/>}/>
      <Route path="/List-split" element={<ListSplitBill/>}/>
      <Route path="/update-split/:invoice" element={<SplitInvoiceForm1/>}/>
      <Route path="/vendorbill" element={<VendorInvoiceForm/>}/>
      <Route path="/List-vendorbill" element={<ListVendorBill/>}/>
    </Routes>
    </BrowserRouter>
  );
}
else if(roleId === 3 && status === 'Passed'){
  return(
    <BrowserRouter>
    <Routes>
    <Route path="/" element={<Frontpage/>}/>
        <Route path="/features" element={<FeaturesPage/>}/>
        <Route path="/whyHMS" element={<Whypage/>}/>
    <Route path="/profile" element={<Profile />} />
    <Route path="/sales&Revenue" element={<AdminDashboard />} />
  <Route path="/signin" element={<LoginForm/>}/>
      <Route path="/signup" element={<SignUpForm/>}/>
      <Route path="/appointment" element={<Appointment/>}/>
      <Route path="/reports" element={<Reports/>}/>
      <Route path="/prescription" element={<Prescription/>}/>
      <Route path="/list-prescription" element={<ListPrescription/>}/>
      <Route path="/update-prescription/:invoiceNumber" element={<InvoiceForm3/>}/>
      </Routes>
    </BrowserRouter>
  )
}
else if(roleId === 4 && status === 'Passed'){
  return(
    <BrowserRouter>
    <Routes>
    <Route path="/" element={<Frontpage/>}/>
    <Route path="/features" element={<FeaturesPage/>}/>
    <Route path="/whyHMS" element={<Whypage/>}/>
    <Route path="/profile" element={<Profile />} />
    <Route path="/sales&Revenue" element={<AdminDashboard />} />
    <Route path="/signin" element={<LoginForm/>}/>
    <Route path="/signup" element={<SignUpForm/>}/>
    <Route path="/patientadmin" element={<PatientStatus/>}/>
    <Route path="/medicinedash" element={<MedicineStatus/>}/>
    <Route path="/reviews" element={<Reviews/>}/>
    <Route path="/list-vendors" element={<ListVendors />} />
    <Route path="/list/medicine" element={<ListMedicine />} />
    <Route path="/add-medicine" element={<AddMedicine />} />
    <Route path="/update-medicine/:medicineId" element={<UpdateMedicine />} />
    <Route path="/file-upload" element={<FileUpload />} />
    <Route path="/list-expenses" element={<ListExpenses />} />
    <Route path="/add-expenses" element={<AddExpense />} />
    <Route path="/update-expenses/:expenseId" element={<UpdateExpenses />} />
    <Route path="/create-bill" element={<InvoiceForm />} />
    <Route path="/List-bill" element={<ListBill/>}/>
    <Route path="/Update-bill/:invoice" element={<InvoiceForm1/>}/>
    </Routes>
    </BrowserRouter>
  );
}
else if(roleId === 5 && status === 'Passed'){
  return(
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Frontpage/>}/>
      <Route path="/features" element={<FeaturesPage/>}/>
      <Route path="/whyHMS" element={<Whypage/>}/>
      <Route path="/appointment" element={<Appointment/>}/>
      <Route path="/reports" element={<Reports/>}/>
      <Route path="/sales&Revenue" element={<AdminDashboard />} />
    <Route path="/signin" element={<LoginForm/>}/>
        <Route path="/signup" element={<SignUpForm/>}/>
        <Route path="/profile" element={<Profile />} />
        <Route path="/reviews" element={<Reviews/>}/>
      </Routes>
    </BrowserRouter>
  )
}
else if (roleId === 6 && status === 'Passed'){
  return(
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Frontpage/>}/>
        <Route path="/features" element={<FeaturesPage/>}/>
        <Route path="/whyHMS" element={<Whypage/>}/>
      <Route path="/sales&Revenue" element={<AdminDashboard />} />
    <Route path="/signin" element={<LoginForm/>}/>
        <Route path="/signup" element={<SignUpForm/>}/>
        <Route path="/profile" element={<Profile />} />
        <Route path="/prescription" element={<Prescription/>}/>
        <Route path="/list-prescription" element={<ListPrescription/>}/>
        <Route path="/update-prescription/:invoiceNumber" element={<InvoiceForm3/>}/>
        <Route path="/patientadmin" element={<PatientStatus/>}/>
        <Route path="/medicinedash" element={<MedicineStatus/>}/>
        <Route path="/reviews" element={<Reviews/>}/>
      </Routes>
    </BrowserRouter>
  );
}
else if(roleId=== 7 && status === 'Passed'){
  return(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Frontpage/>}/>
        <Route path="/features" element={<FeaturesPage/>}/>
        <Route path="/whyHMS" element={<Whypage/>}/>
        <Route path="/sales&Revenue" element={<AdminDashboard />} />
        <Route path="/signin" element={<LoginForm/>}/>
        <Route path="/signup" element={<SignUpForm/>}/>
        <Route path="/profile" element={<Profile />} />
        <Route path="/appointment" element={<Appointment/>}/>
        <Route path="/reports" element={<Reports/>}/>
        <Route path="/prescription" element={<Prescription/>}/>
        <Route path="/list-prescription" element={<ListPrescription/>}/>
        <Route path="/update-prescription/:invoiceNumber" element={<InvoiceForm3/>}/>
        <Route path="/reviews" element={<Reviews/>}/>
      </Routes>
    </BrowserRouter>
  )
}
else {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/sales&Revenue" element={<AdminDashboard />} />
    <Route path="/signin" element={<LoginForm/>}/>
        <Route path="/signup" element={<SignUpForm/>}/>
        <Route path="/profile" element={<Profile />} />
        <Route path="/" element={<Frontpage/>}/>
        <Route path="/features" element={<FeaturesPage/>}/>
        <Route path="/whyHMS" element={<Whypage/>}/>
        </Routes>
      </BrowserRouter>
  );
}
}


export default App;
