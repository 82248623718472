import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { FaTrash, FaEye, FaCopy } from 'react-icons/fa';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { format, parse } from 'date-fns';
import Select from 'react-select';
import Loader from "../frontpage/Loader";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import AppointmentModal from './AppointmentModal';

const Reports = () => {
  const [data, setData] = useState([]);
  const [appointmentsData, setAppointmentsData] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState({ value: "All", label: "All" });
  const [doctorOptions1, setDoctorOptions1] = useState([])
   
  const[formData1, setFormData1]= useState({
    userId:"",
    HospitalName:"",
  })

  const[HospitalName,setHospitalName]=useState("");

  useEffect(()=>{
    const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;

        setFormData1((prevData) => ({
          ...prevData,
          userId: userId,
          HospitalName: HospitalName,
        }));

        try {
          const response = await axios.get(
            `https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getHospitalName/${userId}`
          );
          const addressData = response.data;
          setFormData1((prevData) => ({
            ...prevData,
            HospitalName: addressData.HospitalName || " ",
            HospitalId: addressData.HospitalId || " ",
          }));
        } catch (error) {
          console.error("Error fetching user data:", error);
         
        } finally {
          
        }
      } else {
       
      }
    });

    return () => unsubscribe();
  }, []);

  const hospitalId=formData1.HospitalId;

  const [appointmentMessageTemplate, setAppointmentMessageTemplate] = useState('');


  const fetchAdminExtraFields = async (hospitalId) => {
    try {
      const response = await axios.get(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getAdminExtraFields/${hospitalId}`);
      const extraFieldsData = response.data;

      setAppointmentMessageTemplate(extraFieldsData.AppointmentMessage || '');
      console.log('Fetched admin extra fields data:', extraFieldsData);
    } catch (error) {
      console.error('Error fetching admin extra fields:', error);
    }
  };

  const generateMessage = (template, appointment) => {
    if (!template) return '';
  
    return template
      .replace('${name}', appointment.Appointmenttab_name || 'N/A')
      .replace('${date}', formatDate(appointment.Appointmenttab_date) || 'N/A')
      .replace('${time}', appointment.Appointmenttab_time || 'N/A')
      .replace('${doctorname}', appointment.Appointmenttab_doctorname || 'N/A');
  };
  
  
  useEffect(() => {
    if (hospitalId) {
      fetchAdminExtraFields(hospitalId);
    }
  }, [hospitalId]);

  useEffect(() => {
    // Fetch the initial data from the backend
    const fetchData = async (hospitalId) => {
      try {
        const response = await axios.get(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getDoctorData?hospitalId=${hospitalId}`);
        console.log('Fetched doctor data:', response.data); 
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data from backend:', error);
      }
    };

    
    

    const fetchAppointments = async (hospitalId) => {
      try {
        // Ensure hospitalId is available before making the request
        if (hospitalId) {
          const response = await axios.get(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getAppointments?hospitalId=${hospitalId}`);
          setAppointmentsData(response.data);
        }
      } catch (error) {
        console.error('Error fetching appointments from backend:', error);
      }
    };

    const fetchDoctorDetails = async (hospitalId) => {
      try {
        const response = await axios.get(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/fetchDoctorNames1?hospitalId=${hospitalId}`);
        const formattedDoctorOptions = response.data.map(doctor => ({
          value: doctor.Name,
          label: doctor.Name,
        }));
        setDoctorOptions1([{ value: "All", label: "All" }, ...formattedDoctorOptions]);
      } catch (error) {
        console.error('Error fetching doctor details:', error);
      }
    };
    

    fetchData(hospitalId);
    fetchAppointments(hospitalId);
    fetchDoctorDetails(hospitalId);
  }, [hospitalId]);

  useEffect(() => {
    const filterAppointments = () => {
      let filtered = [...appointmentsData];

    
      if (fromDate) {
        const parsedFromDate = parse(fromDate, 'yyyy-MM-dd', new Date());
        filtered = filtered.filter((appointment) => {
          const appointmentDate = parse(appointment.Appointmenttab_date, 'dd/MM/yyyy', new Date());
          return !isNaN(appointmentDate) && appointmentDate >= parsedFromDate;
        });
      }
      if (toDate) {
        const parsedToDate = parse(toDate, 'yyyy-MM-dd', new Date());
        filtered = filtered.filter((appointment) => {
          const appointmentDate = parse(appointment.Appointmenttab_date, 'dd/MM/yyyy', new Date());
          return !isNaN(appointmentDate) && appointmentDate <= parsedToDate;
        });
      }
      if (selectedDoctor.value !== "All") {
        filtered = filtered.filter(appointment => appointment.Appointmenttab_doctorname === selectedDoctor.value);
      }


      filtered.sort((a, b) => parse(a.Appointmenttab_date, 'dd/MM/yyyy', new Date()) - parse(b.Appointmenttab_date, 'dd/MM/yyyy', new Date()));

      setFilteredAppointments(filtered);
    };

    filterAppointments();
  }, [fromDate, toDate,selectedDoctor, appointmentsData]);

  const formatDate = (dateString) => {
    const date = parse(dateString, 'dd/MM/yyyy', new Date());
    if (!isNaN(date)) {
      return format(date, 'dd/MM/yyyy');
    }
    return 'Invalid date';
  };


  const handleDelete = async (doctorName, date, hospitalId) => {
    try {
      await axios.post('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/deleteDoctorData', { doctorName, date, hospitalId });
      setData((prevData) => prevData.filter(item => item.doctorName !== doctorName || item.date !== date));
      console.log('Data deleted from backend');
    } catch (error) {
      console.error('Error deleting data from backend:', error);
    }
  };

  const handleDeleteAppointment = async (date, patientName, hospitalId) => {
    try {
      await axios.post('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/deleteAppointment', { date, patientName, hospitalId});
      setAppointmentsData((prevData) => prevData.filter(item => item.Appointmenttab_date !== date || item.Appointmenttab_name !== patientName));
      console.log('Appointment data deleted from backend');
    } catch (error) {
      console.error('Error deleting appointment data from backend:', error);
    }
  };

  const normalizeDate = (dateString) => {
    const parsedDate = parse(dateString, 'MM/dd/yyyy', new Date());
    if (isNaN(parsedDate)) {
        return parse(dateString, 'dd/MM/yyyy', new Date());
    }
    return parsedDate;
};

const [showModal, setShowModal] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const handleViewDetails = (appointment) => {
    setSelectedAppointment(appointment);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedAppointment(null);
  };

  return (
    <Layout title="Reports Schedule">
    <Loader/>
      <>
        <section className="admin_table_section layout_padding-bottom">
        <h2>Appointment Table</h2>
         
            <div className="table-container"></div>
           
            <div className="mb-3">
              <PDFDownloadLink
                document={<PDFDocument data={data} />} 
                fileName="appointment_table.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? 'Loading document...' : 'Export in pdf'
                }
              </PDFDownloadLink>
            </div>
            <div className="table-responsive">
    <table id="adminTable">
      <thead>
        <tr>
          <th style={{ color: "black" }}>Doctor Name</th>
          <th style={{ color: "black" }}>Date</th>
          <th style={{ color: "black" }}>Time</th>
          <th style={{ color: "black" }}>Delete</th>
        </tr>
      </thead>
      <tbody>
        {data.map((appointment, index) => (
      
          <tr key={index}>
            <td className="tddoctorname">{appointment.doctorName}</td>
            <td className="tddoctorname">{format(normalizeDate(appointment.date), 'MM/dd/yyyy')}</td>
            <td>{appointment.timeRange}</td>
            <td>
              <button className="btn btn-danger" onClick={() => handleDelete(appointment.doctorName, appointment.date, hospitalId)}>
                <FaTrash />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</section>

        <section className="appointments_table_section layout_padding-bottom">
         
            <div className="table-container"></div>
            <h2>Patient Appointments</h2>
            <div className="mb-3">
              <PDFDownloadLink
                document={<PDFDocument1 appointmentsData={filteredAppointments} />}
                fileName="patient_appointments.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? 'Loading document...' : 'Export in pdf'
                }
              </PDFDownloadLink>
            </div>
            <div className="date-filters">
              <div className="filter">
                <label>From:</label>
                <input
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  format="DD/MM/YYYY"
                />
              </div>
              <div className="filter">
                <label>To:</label>
                <input
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                   format="DD/MM/YYYY"
                />
              </div>
              <div className="filter">
             
              <Select
                value={selectedDoctor}
                onChange={(option) => setSelectedDoctor(option)}
                options={doctorOptions1}
                
              />
            </div>
            </div>
            <div className="table-responsive">
              <table id="appointmentsTable">
                <thead>
                  <tr>
                    <th style={{ color: 'black' }}>Date</th>
                    <th style={{ color: 'black' }}>Time</th>
                    <th style={{ color: 'black' }}>Name</th>
                    <th style={{ color: 'black' }}>Email</th>
                    <th style={{ color: 'black' }}>Doctor Name</th>
                    <th style={{ color: 'black' }}>View</th>
                    <th style={{ color: 'black' }}>Copy</th>
                    <th style={{ color: 'black' }}>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAppointments.map((item, index) => (
                    <tr key={index}>
                      <td>{formatDate(item.Appointmenttab_date)}</td>
                      <td>{item.Appointmenttab_time || 'N/A'}</td>
                      <td>{item.Appointmenttab_name}</td>
                      <td>{item.Appointmenttab_email}</td>
                      <td>{item.Appointmenttab_doctorname}</td>
                      <td>
                      <FaEye
                          style={{ cursor: 'pointer', color:"#006994" }}
                          onClick={() => handleViewDetails(item)}
                          title="View Details"
                        />
                    </td>
                    <td>
                    <FaCopy
                        style={{ cursor: 'pointer', color:"#32a852" }}
                        onClick={() => {
                          const message =generateMessage(appointmentMessageTemplate, item);
                          navigator.clipboard.writeText(message);
                        }}
                        title="Copy Details"
                      />
                    </td>
                      <td>
                        <FaTrash style={{color:"red"}}
                          onClick={(e) => {
                            e.preventDefault();
                            handleDeleteAppointment(item.Appointmenttab_date, item.Appointmenttab_name, hospitalId);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
                 {/* Modal */}
              <AppointmentModal
                show={showModal}
                handleClose={handleCloseModal}
                appointment={selectedAppointment}
              />
    
            </div>
          
        </section>
      </>
    </Layout>
  );
}

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row'
  },
  tableColHeader: {
    width: '20%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomColor: '#AAAAAA',
    backgroundColor: '#BAB86C',
    textAlign: 'center',
    padding: 5,
    fontSize: 15
  },
  tableCol: {
    width: '20%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomColor: '#AAAAAA',
    backgroundColor: '#FFFFFF',
    textAlign: 'center',
    padding: 5,
    fontSize: 8
  },
  '@media (max-width: 768px)': {
    tableCol: {
      width: '25%', // Adjust the width for smaller screens
      fontSize: 5 // Increase font size for better readability
    }
  }
});

const PDFDocument = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text>Doctor Name</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Date</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Time</Text>
            </View>
          </View>
          {data.map((item, index) => (
            <View key={index} style={index % 2 === 0 ? { ...styles.tableRow, backgroundColor: '#DDDDDD' } : styles.tableRow}>
              <View style={styles.tableCol}>
                <Text>{item.doctorName}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{format(new Date(item.date), 'dd/MM/yyyy')}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{item.timeRange}</Text>
              </View>
            </View>
          ))}
        </View>
      </View>
    </Page>
  </Document>
);

const PDFDocument1 = ({ appointmentsData }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text>Date</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Time</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Patient Name</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Email</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Doctor Name</Text>
            </View>
          </View>
          {appointmentsData.map((item, index) => (
            <View
              key={index}
              style={
                index % 2 === 0
                  ? { ...styles.tableRow, backgroundColor: '#DDDDDD' }
                  : styles.tableRow
              }
            >
              <View style={styles.tableCol}>
                <Text>{formatDates(item.Appointmenttab_date)}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{item.Appointmenttab_time}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{item.Appointmenttab_name}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{item.Appointmenttab_email}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{item.Appointmenttab_doctorname}</Text>
              </View>
            </View>
          ))}
        </View>
      </View>
    </Page>
  </Document>
);

const formatDates = (dateString) => {
  if (!dateString) return 'N/A'; // Handle empty or undefined dates

  const parsedDate = parse(dateString, 'dd/MM/yyyy', new Date()); // Parse using the correct format
  if (isNaN(parsedDate.getTime())) {
    return 'Invalid Date'; // Handle invalid dates
  }
  return format(parsedDate, 'dd/MM/yyyy'); 
};
export default Reports;
