import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import InvoiceItem from './InvoiceItem';
import InvoiceModal from './InvoiceModal';
import InputGroup from 'react-bootstrap/InputGroup';
import Layout from "../core/Layout";
import Select from 'react-select';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { BiPaperPlane, BiCloudDownload } from "react-icons/bi";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import firebase from 'firebase/compat/app';
import { FaTrash } from 'react-icons/fa';
import "./Createbill.css";
import Loader from '../frontpage/Loader';
import { DropdownButton, Dropdown } from 'react-bootstrap';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCI59gnHA9AZAANjoy082vht3fCgatchEg",
  authDomain: "hospital-management-syst-7e35d.firebaseapp.com",
  databaseURL: "https://hospital-management-syst-7e35d-default-rtdb.firebaseio.com",
  projectId: "hospital-management-syst-7e35d",
  storageBucket: "hospital-management-syst-7e35d.firebasestorage.app",
  messagingSenderId: "59517358381",
  appId: "1:59517358381:web:96f502e3832ddf1a8fc3a4",
  measurementId: "G-EYLWMBT9SP"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const storage = firebase.storage();

class InvoiceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      currency: '₹',
      currentDate: new Date().toLocaleDateString(),
      invoiceNumber: 1,
      dateOfIssue: '',
      billTo: '',
      billToEmail: '',
      billToAddress: '',
      billFrom: '',
      billFromEmail: '',
      billFromAddress: '',
      notes: '',
      total: '0.00',
      subTotal: '0.00',
      taxRate: '',  
      taxAmount: '0.00',
      discountRate: '',
      discountAmount: '0.00',
      items: [{
        id: 0,
        name: '',
        description: '',
        price: '',
        quantity: 1,
        medicineId: null
      }],
      patientOptions: [],
      hospitalOptions: [],
      medicineOptions: [],
      selectedMedicineNames: [],
      showInvoiceCapture: false,
      additionalNotes: [],
      hospital: "",
      logoUrl:"",
      error:"",
      color1:"lightgrey",
      color2:"",
      color3:"",
      color4:"",
      socialMedia: "",
      paymentTypes: ["UPI", "Online Banking","Cash"],
      paymentMode: 'UPI',
      selectedCategory: '',
      address: {},
      rows:[],
      configureDueDateOptions: [5, 15, 30, 90],
    };

    this.editField = this.editField.bind(this);
    this.handleAddEvent = this.handleAddEvent.bind(this);
    this.handleRowDel = this.handleRowDel.bind(this);
    this.onItemizedItemEdit = this.onItemizedItemEdit.bind(this);
    this.handleCalculateTotal = this.handleCalculateTotal.bind(this);
    this.onCurrencyChange = this.onCurrencyChange.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.fetchPatients = this.fetchPatients.bind(this);
    this.handlePatientSelect = this.handlePatientSelect.bind(this);
    this.handleHospitalSelect = this.handleHospitalSelect.bind(this);
    this.fetchMedicines = this.fetchMedicines.bind(this);
    this.printInvoice = this.printInvoice.bind(this);
    this.onRowAddNotes = this.onRowAddNotes.bind(this);
    this.handleDeleteNote = this.handleDeleteNote.bind(this);
    this.handleAdditionalNoteChange = this.handleAdditionalNoteChange.bind(this);
    this.handleDueDateSelection= this.handleDueDateSelection.bind(this);
    this.addRow = this.addRow.bind(this);
    this.handlePaymentModeChange = this.handlePaymentModeChange.bind(this);
  }

  componentDidMount() {
    this.handleCalculateTotal();
    this.fetchPatients();
    this.fetchHospitals();
    this.fetchMedicines();
    this.fetchLatestInvoiceNumber();
    this.fetchUserHospital();
  }

  async fetchPatients() {
    try {
      const hospitalId = this.state.hospital;

      if (hospitalId) {
      const response = await axios.get(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/createbill/get-patients?hospitalId=${hospitalId}`);
      const patientOptions = response.data.map(patient => ({
        value: patient.patientId,
        label: `${patient.patientId} - ${patient.Name}`,
        email: patient.Email,
        address: patient.Address,
        phone: patient.PhoneNo,
        data: patient
      }));
      this.setState({ patientOptions });
    } else {
      console.error("Hospital ID is not set.");
    }
    } catch (error) {
      console.error("There was an error fetching the patients!", error);
    }
  }

  async fetchHospitals() {
    try {
      const hospitalId = this.state.hospital;
      const response = await axios.get(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/createbill/get-hospitals?hospitalId=${hospitalId}`);
      const hospitalData = response.data; // Assuming response.data is an object as provided in your sample

      // Map the hospital data to the relevant fields in your state
      this.setState({
        billFrom: hospitalData.HospitalName,
        billFromEmail: hospitalData.Email,
        billFromPhone: hospitalData.PhoneNumber,
        billFromAddress: `${hospitalData.address.streetName1}, ${hospitalData.address.streetName2}, ${hospitalData.address.area}, ${hospitalData.address.city}, ${hospitalData.address.state}, ${hospitalData.address.country}`,
        // Additional fields can be set here if needed
      });
    } catch (error) {
      console.error("There was an error fetching the hospitals!", error);
    }
  }

  async fetchMedicines() {
    try {
      const hospitalId = this.state.hospital;
      const response = await axios.get(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/createbill/get-medicines?hospitalId=${hospitalId}`);
      const medicineOptions = response.data.map(medicine => ({
        value: medicine.medicineId,
        label: medicine.MedicineName,
        price: medicine.Price,
        data: medicine
      }));
      this.setState({ medicineOptions }); 
    } catch (error) {
      console.error("There was an error fetching the medicines!", error);
    }
  }

  formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  addRow() {
    this.setState(prevState => ({
      rows: [...prevState.rows, { id: Date.now(), paymentMode: "", date: this.formatDate(new Date()), paymentId: ""}]
    }));
  }

  // Remove a row
  removeRow(id) {
    this.setState(prevState => ({
      rows: prevState.rows.filter(row => row.id !== id)
    }));
  }

  handlePaymentModeChange(id, event) {
    const { value } = event.target;
    this.setState(prevState => ({
      rows: prevState.rows.map(row =>
        row.id === id ? { ...row, paymentMode: value } : row
      )
    }));
  }

  handlePaymentIdChange(id, e) {
    const updatedRows = this.state.rows.map(row => 
      row.id === id ? { ...row, paymentId: e.target.value } : row
    );
    this.setState({ rows: updatedRows });
  }


   
  handleAmountChange = (rowId, e) => {
    const value = parseFloat(e.target.value) || 0;
    
    // Calculate the current sum of all row amounts except for the current row being edited
    const sumWithoutCurrent = this.state.rows
      .filter(row => row.id !== rowId)
      .reduce((sum, row) => sum + (parseFloat(row.amount) || 0), 0);
  
    // Calculate the new total if this amount is included
    const newTotalWithCurrent = sumWithoutCurrent + value;
  
    // Check if the new total exceeds the maximum allowed total (this.state.total)
    if (newTotalWithCurrent > parseFloat(this.state.total)) {
      alert(`The total amount cannot exceed ${this.state.total}.`);
      return; // Stop here if the new total exceeds the maximum allowed value
    }
  
    // Update the state if validation passes
    const updatedRows = this.state.rows.map((row) => {
      if (row.id === rowId) {
        return { ...row, amount: value };
      }
      return row;
    });
  
    this.setState({ rows: updatedRows }, this.calculateTotalPaid);
  };
  

  fetchUserHospital = async () => {
    try {
      const user = firebase.auth().currentUser;
      if (user) {
        const userId = user.uid;
        const response = await axios.get(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getUserData/${userId}`);
        const hospitalId = response.data.hospital.HospitalId;
        this.setState({ hospital: hospitalId }, () => {
          this.fetchLogoUrl();
          this.fetchColorCode();
          this.fetchPatients();
          this.fetchLatestInvoiceNumber();
          this.fetchMedicines();
          this.fetchAddress();
          this.fetchHospitals();
          this.fetchPaymentTypes();
        });
      }
    } catch (error) {
      console.error("Error fetching user hospital:", error);
      this.setState({ error: "Failed to fetch user hospital" });
    }
  };

  fetchLogoUrl = () => {
    const { hospital } = this.state;
    if (hospital) {
      fetch(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getLogoUrl/${hospital}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.logoUrl) {
            this.setState({ logoUrl: data.logoUrl });
          } else {
            this.setState({ error: "Logo URL not found" });
          }
        })
        .catch((error) => {
          console.error("Error fetching logo URL:", error);
          this.setState({ error: "Error fetching logo URL" });
        });
    }
  };

  fetchPaymentTypes = () => {
    const { hospital } = this.state;
    if (hospital) {
        fetch(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getPaymentTypes/${hospital}`)
            .then((response) => response.json())
            .then((data) => {
                if (data.paymentTypes) {
                    this.setState({ paymentTypes: data.paymentTypes });
                } else {
                    this.setState({ error: "Payment types not found" });
                }
            })
            .catch((error) => {
                console.error("Error fetching payment types:", error);
                this.setState({ error: "Error fetching payment types" });
            });
    }
};

  fetchColorCode =()=>{
    const { hospital } = this.state;
    if (hospital) {
      fetch(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getHospitalColors/${hospital}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.pdfHeaderColor && data.pdfFooterColor && data.pdfHeaderTextColor && data.pdfFooterTextColor) {
            this.setState({ color1: data.pdfHeaderColor, color2: data.pdfFooterColor, color3: data.pdfHeaderTextColor, color4: data.pdfFooterTextColor, socialMedia: data.socialMedia || {}  });
          } else {
            this.setState({ error: "colors not found" });
          }
        })
        .catch((error) => {
          console.error("Error fetching hospital colors:", error);
          this.setState('Error fetching colors');
        });
    }
  }

  fetchAddress = () => {
    const { hospital } = this.state;
    if (hospital) {
      fetch(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getHospitalAddress/${hospital}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.address) {
            this.setState({ address: data.address });
          } else {
            this.setState({ error: "Address not found" });
          }
        })
        .catch((error) => {
          console.error("Error fetching hospital address:", error);
          this.setState({ error: 'Error fetching address' });
        });
    }
  }


  handlePatientSelect(selectedOption) {
    const { value , label, email, address, data, phone} = selectedOption;

    const billingAmount = parseFloat(data?.BillingAmount || 0);
    this.setState({
      billTo: label,
      billToEmail: email,
      billToAddress: address,
      billToPhone: phone,
      billToData: data,
      // subTotal: billingAmount.toFixed(2), // Update subtotal with billing amount
      patientId: value,
      patientName: data.Name,
    }, this.handleCalculateTotal); // Recalculate total
  }

  handleHospitalSelect(selectedOption) {
    const { label, email, address, phone} = selectedOption;

    this.setState({
      billFrom: label,
      billFromEmail: email,
      billFromAddress: address,
      billFromPhone: phone,
    });
  }

  handleRowDel(items) {
    const updatedItems = this.state.items.filter(item => item.id !== items.id);
    this.setState({ items: updatedItems }, this.handleCalculateTotal);
  }

  handleAddEvent() {
    const id = (this.state.items.length === 0) ? 0 : this.state.items[this.state.items.length - 1].id + 1;
    const item = {
      id: id,
      name: '',
      description: '',
      price: '',
      quantity: 1,
      medicineId: null
    };
    this.setState((prevState) => ({
      items: [...prevState.items, item]
    }));
  }

  onRowAddNotes(event) {
    event.preventDefault(); // Prevent the default button action
    this.setState(prevState => ({
      additionalNotes: [...prevState.additionalNotes, '']
    }));
  }

  handleAdditionalNoteChange(index, event) {
    const newNotes = [...this.state.additionalNotes];
    newNotes[index] = event.target.value;
    this.setState({ additionalNotes: newNotes });
  }

  handleDeleteNote(index) {
    this.setState(prevState => {
      const newNotes = prevState.additionalNotes.filter((note, i) => i !== index);
      return { additionalNotes: newNotes };
    });
  }


  handleCalculateTotal() {
    const items = this.state.items;

    let subTotal = 0;
    items.forEach(item => {
      subTotal += parseFloat(item.price) * parseFloat(item.quantity);
    });

    const discountAmount = (this.state.discountRate / 100) * subTotal;
    const taxAmount = (this.state.taxRate / 100) * (subTotal - discountAmount);
    const total = subTotal - discountAmount + taxAmount;

    this.setState({
      subTotal: subTotal.toFixed(2),
      discountAmount: discountAmount.toFixed(2),
      taxAmount: taxAmount.toFixed(2),
      total: total.toFixed(2)
    });
}

  onItemizedItemEdit(evt) {
    const { id, name, value } = evt.target;
    const items = [...this.state.items];
    const index = items.findIndex(item => item.id === parseInt(id));
    items[index][name] = value;

    this.setState({ items }, this.handleCalculateTotal);
  }

  editField(event) {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value }, this.handleCalculateTotal);
  }

  handleDueDateSelection = (days) => {
    const currentDate = new Date();
    const dueDate = new Date(currentDate.setDate(currentDate.getDate() + days)).toISOString().split('T')[0];
    this.setState({ dateOfIssue: dueDate });
  };

  onCurrencyChange(event) {
    this.setState({ currency: event.target.value });
  }

  openModal(event) {
    event.preventDefault();
    this.handleCalculateTotal();
    this.setState({ isOpen: true });
  }

  closeModal() {
    this.setState({ isOpen: false });
  }

  fetchLatestInvoiceNumber = async () => {
    const hospitalId = this.state.hospital;
    console.log("hospitalIdfetch:", hospitalId);
    try {
      const response = await axios.get(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/latestInvoiceNumber?hospitalId=${hospitalId}`);
      if (response.data.success) {
        console.log("Latest Invoice Number:", response.data.latestInvoiceNumber); 
        this.setState({
          invoiceNumber: response.data.latestInvoiceNumber + 1,
        });
      }
    } catch (error) {
      console.error("Error fetching latest invoice number:", error);
    }
  };

  async printInvoice(event,item) {
    event.preventDefault();
        const input = document.getElementById('invoiceCaptureprint');
        const canvas= await html2canvas(input);
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'pt',
          format: [612, 792],
        });
        pdf.internal.scaleFactor = 1;
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        const fileName = `${this.state.patientId}_${this.state.billFrom}.pdf`;
        pdf.save(fileName);

        // Convert the PDF data to a blob and send it to the backend
        const blob = pdf.output('blob');
       
        const storageRef= storage.ref();
        const pdfRef=storageRef.child(`createbill/${fileName}`);
        let pdfURL = '';
        let uploadSuccessful = false;

        try{
          await pdfRef.put(blob);
          pdfURL= await pdfRef.getDownloadURL();
          uploadSuccessful=true; 
        }  catch(error){
          console.error('Error uploading PDF:', error);
        }

        const currentDate = new Date().toLocaleDateString();

        const BillData ={
          hospitalId: this.state.hospital,
          url: uploadSuccessful ? pdfURL : 'placeholder-url-or-error',
          patientId: this.state.patientId,
          patientName: this.state.patientName,
          billTo: this.state.billTo,
          billToEmail: this.state.billToEmail || null,
          billToAddress: this.state.billToAddress,
          billToPhone: this.state.billToPhone,
          billFrom: this.state.billFrom,
          billFromEmail: this.state.billFromEmail,
          billFromAddress: this.state.billFromAddress,
          billFromPhone: this.state.billFromPhone,
          invoice: this.state.invoiceNumber,
          total: this.state.total,
          dateOfIssue: this.state.dateOfIssue,
          selectedMedicineNames: this.state.selectedMedicineNames,
          subTotal: this.state.subTotal,
          discountAmount: this.state.discountAmount,
          taxAmount: this.state.taxAmount,
          notes: this.state.notes,
          additionalNotes: JSON.stringify(this.state.additionalNotes),
          items: JSON.stringify(this.state.items),
          color1: this.state.color1,
          color2: this.state.color2,
          color3: this.state.color3,
          color4: this.state.color4,
          address: this.state.address,
          socialMedia: this.state.socialMedia,
          BillDate: currentDate,
          paymentMode: this.state.rows.map((row) => row.paymentMode),
          paymentDates: this.state.rows.map((row) => row.date),
          paymentAmounts: this.state.rows.map((row) => row.amount),
          paymentIds: this.state.rows.map((row) => row.paymentId),
          };
        

        try {
          const createResponse=await fetch('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/create-bill',{
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(BillData),
          });

          const createData = await createResponse.json();
          console.log('create bill response:', createData);
          if(!createResponse.ok){
            throw new Error(createResponse.statusText);
          }

          console.log(createData);

          const medicineUpdates = this.state.items.map(item => ({
            medicineId: item.medicineId,
            quantitySold: item.quantity,
          }));
          const medicineResponse = await fetch('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/update-billmedicinequantity', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ hospitalId: this.state.hospital, updates: medicineUpdates }),
          });
          const medicineData = await medicineResponse.json();
          console.log('Medicine update response:', medicineData);
          if (!medicineResponse.ok) {
              throw new Error(medicineResponse.statusText);
          }

        } catch(error) {
          console.error('Error creating bill:', error);
        }
        }


  render() {
    const { items, logoUrl, error,  color1, color2, color3, color4, socialMedia , address, hospital} = this.state;

    return (
        <Layout title="List Prescriptions" className="container-fluid">
        <Loader/>
         <h1 className="mt-4 color-bright">Generate New Bill</h1>
    <Form >
      <Row>
        <Col md={8} lg={9}>
        <div id="invoiceCapture12">
          <Card className="p-4 p-xl-5 my-3 my-xl-4">
            <div className="d-flex flex-row align-items-start justify-content-between mb-3">
              <div class="d-flex flex-column">
                <div className="d-flex flex-column">
                  <div class="mb-2">
                    <span className="fw-bold">Current&nbsp;Date:&nbsp;</span>
                    <span className="current-date">{new Date().toLocaleDateString()}</span>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center mb-3">
                  <span className="fw-bold d-block me-2">Configure Due Date:</span>
                  <DropdownButton id="dropdown-due-date" title="Select Days">
                    {this.state.configureDueDateOptions.map((days) => (
                      <Dropdown.Item key={days} onClick={() => this.handleDueDateSelection(days)}>
                        {days} Days
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <span className="fw-bold d-block me-2">Due&nbsp;Date:</span>
                  <Form.Control type="date" value={this.state.dateOfIssue} name={"dateOfIssue"} onChange={(event) => this.editField(event)} style={{
                      maxWidth: '150px'
                    }} required="required"/>
                </div>
              </div>
              <div className="d-flex flex-row align-items-center">
                <span className="fw-bold me-2">Invoice&nbsp;Number:&nbsp;</span>
                <Form.Control type="number" value={this.state.invoiceNumber} name={"invoiceNumber"} onChange={(event) => this.editField(event)} min="1" style={{
                    maxWidth: '70px'
                  }} required="required"/>
              </div>
            </div>
            <hr className="my-4"/>
            <Row className="mb-5">
              <Col>
                <Form.Label className="fw-bold">Bill to:</Form.Label>
                <Select
                      options={this.state.patientOptions}
                      onChange={this.handlePatientSelect}
                      placeholder="Search for a patient..."
                      className="my-2"
                    />
                <Form.Control placeholder={"Who is this invoice to?"} rows={3} value={this.state.billTo} type="text" name="billTo" className="my-2" onChange={(event) => this.editField(event)} autoComplete="name" required="required"/>
                <Form.Control placeholder={"Billing Phone number"} value={this.state.billToPhone} type="text" name="billToPhone" className="my-2" autoComplete="phone" onChange={(event) => this.editField(event)} required="required"/>
                <Form.Control placeholder={"Email address"} value={this.state.billToEmail} type="email" name="billToEmail" className="my-2" onChange={(event) => this.editField(event)} autoComplete="email" required="required"/>
                <Form.Control placeholder={"Billing address"} value={this.state.billToAddress} type="text" name="billToAddress" className="my-2" autoComplete="address" onChange={(event) => this.editField(event)} required="required"/>
               
              </Col>
              <Col>
                <Form.Label className="fw-bold">Bill from:</Form.Label>
                
                <Form.Control placeholder={"Who is this invoice from?"} rows={3} value={this.state.billFrom} type="text" name="billFrom" className="my-2" onChange={(event) => this.editField(event)} autoComplete="name" required="required"/>
                <Form.Control placeholder={"Phone Number"} value={this.state.billFromPhone} type="text" name="billFromPhone" className="my-2" onChange={(event) => this.editField(event)} autoComplete="phone" required="required"/>
                <Form.Control placeholder={"Email address"} value={this.state.billFromEmail} type="email" name="billFromEmail" className="my-2" onChange={(event) => this.editField(event)} autoComplete="email" required="required"/>
                <Form.Control placeholder={"Billing address"} value={this.state.billFromAddress} type="text" name="billFromAddress" className="my-2" autoComplete="address" onChange={(event) => this.editField(event)} required="required"/>
              </Col>
              
            </Row>
           
            <InvoiceItem onItemizedItemEdit={this.onItemizedItemEdit} onRowAdd={this.handleAddEvent} onRowDel={this.handleRowDel} currency={this.state.currency} items={this.state.items} medicineOptions={this.state.medicineOptions}  handleCalculateTotal={this.handleCalculateTotal} />
                        <Row className="mt-4 justify-content-end">
                  <Col lg={6}>
                      <div className="d-flex flex-row align-items-start justify-content-between">
                          <span className="fw-bold">Subtotal:</span>
                          <span>{this.state.currency}{this.state.subTotal}</span>
                      </div>
                      <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                          <span className="fw-bold">Discount:</span>
                          <span>
                              <span className="small">({this.state.discountRate || 0}%)</span>
                              {this.state.currency}{this.state.discountAmount || '0.00'}
                          </span>
                      </div>
                      <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                          <span className="fw-bold">Tax:</span>
                          <span>
                              <span className="small">({this.state.taxRate || 0}%)</span>
                              {this.state.currency}{this.state.taxAmount || '0.00'}
                          </span>
                      </div>
                      <hr/>
                      <div className="d-flex flex-row align-items-start justify-content-between" style={{ fontSize: '1.125rem' }}>
                          <span className="fw-bold">Total:</span>
                          <span className="fw-bold">{this.state.currency}{this.state.total || '0.00'}</span>
                      </div>
                  </Col>
                  <Col lg={12}>
                      <Table>
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Payment Mode</th>
                            <th>Payment Id</th>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.rows.map((row, index) => (
                            <tr key={row.id}>
                              <td>{index + 1}</td>
                              <td>
                                <Form.Select value={row.paymentMode} onChange={(e) => this.handlePaymentModeChange(row.id, e)}>
                                  <option value="">Select Payment Mode</option>
                                  {this.state.paymentTypes.map((type, idx) => (
                                    <option key={idx} value={type}>{type}</option>
                                  ))}
                                </Form.Select>
                              </td>
                              <td>
                              <Form.Control 
                                  type="text" 
                                  value={row.paymentId || ''} 
                                  onChange={(e) => this.handlePaymentIdChange(row.id, e)} 
                                  placeholder="Enter Payment Id" 
                                />
                              </td>
                              <td>
                                {row.date}
                                {/* If you want to allow date editing, add an input field here */}
                              </td>
                              <td>
                                <Form.Control 
                                  type="number" 
                                  value={row.amount || ''} 
                                  onChange={(e) => this.handleAmountChange(row.id, e)} 
                                  placeholder="Enter Amount" 
                                  max={parseFloat(this.state.total) - this.state.rows
                  .filter(r => r.id !== row.id)
                  .reduce((sum, r) => sum + (parseFloat(r.amount) || 0), 0)
                }
                                />
                              </td>
                              <td>
                                <Button variant="danger" onClick={() => this.removeRow(row.id)}>Delete</Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      </Col>
                      <Col lg={6}>
                      <Button className='mb-4' onClick={this.addRow}>Add Payment Type</Button>
                      </Col>
              </Row>
            <hr className="my-4"/>
            <Form.Label className="fw-bold">Notes:</Form.Label>
            <Form.Control placeholder="Happy Medicial" name="notes" value={this.state.notes} onChange={(event) => this.editField(event)} as="textarea" className="my-2" rows={1}/>
            {this.state.additionalNotes.map((note,index) => (
              <Form.Group key={index} className="d-flex align-items-center my-2">
                <Form.Control
                        as="textarea"
                        rows="4"
                        value={note}
                        className="flex-grow-1"
                        style={{ marginRight: '10px' }}
                        onChange={e => this.handleAdditionalNoteChange(index, e)}
                      />
                       <FaTrash
                        onClick={() => this.handleDeleteNote(index)}
                        style={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }}
                      />
              </Form.Group>
            ))}
            <Button variant="primary" type="add" className="fw-bold" onClick={this.onRowAddNotes} style={{width:"125px",height:"50px"}}>Add Notes</Button>
          </Card>
          </div>
        </Col>
        
        <Col md={4} lg={3}>
          <div className="sticky-top pt-md-3 pt-xl-4">
            {/* <Button variant="primary" type="submit" onClick={this.openModal} className="d-block w-100">Review Invoice</Button> */}
            
           {/* <InvoiceModal showModal={this.state.isOpen} closeModal={this.closeModal} info={this.state} items={this.state.items} currency={this.state.currency} subTotal={this.state.subTotal} taxAmount={this.state.taxAmount} discountAmount={this.state.discountAmount} total={this.state.total} patientId={this.state.patientId} patientName={this.state.patientName}  color1={this.state.color1} 
            color2={this.state.color2} 
            color3={this.state.color3} 
            color4={this.state.color4}
            address={this.state.address}
            socialMedia={this.state.socialMedia}
            hospital={this.state.hospital}  /> */}
           <Button variant="secondary" type="print" className="d-block w-100 mb-4" onClick={this.printInvoice} style={{marginTop:"40px"}}>Print</Button>
            <Form.Group className="mb-3">
              <Form.Label className="fw-bold">Currency:</Form.Label>
              <Form.Select onChange={event => this.onCurrencyChange({currency: event.target.value})} className="btn btn-light my-1" aria-label="Change Currency">
                <option value="₹">INR (Indian Rupee)</option>
                <option value="$">USD (United States Dollar)</option>
                <option value="£">GBP (British Pound Sterling)</option>
                <option value="¥">JPY (Japanese Yen)</option>
                <option value="$">CAD (Canadian Dollar)</option>
                <option value="$">AUD (Australian Dollar)</option>
                <option value="$">SGD (Signapore Dollar)</option>
                <option value="¥">CNY (Chinese Renminbi)</option>
                <option value="₿">BTC (Bitcoin)</option>
              </Form.Select>
            </Form.Group>
                        <Form.Group className="my-3">
                <Form.Label className="fw-bold">Tax rate:</Form.Label>
                <InputGroup className="my-1 flex-nowrap">
                    <Form.Control name="taxRate" type="number" value={this.state.taxRate} onChange={this.editField} className="bg-white border" placeholder="0.0" min="0.00" step="0.01" max="100.00" />
                    <InputGroup.Text className="bg-light fw-bold text-secondary small">%</InputGroup.Text>
                </InputGroup>
            </Form.Group>
            <Form.Group className="my-3">
                <Form.Label className="fw-bold">Discount rate:</Form.Label>
                <InputGroup className="my-1 flex-nowrap">
                    <Form.Control name="discountRate" type="number" value={this.state.discountRate} onChange={this.editField} className="bg-white border" placeholder="0.0" min="0.00" step="0.01" max="100.00" />
                    <InputGroup.Text className="bg-light fw-bold text-secondary small">%</InputGroup.Text>
                </InputGroup>
            </Form.Group>
            {/*  */}
          </div>
        </Col>
      </Row>
    </Form>
  
    <div id="invoiceCaptureprint" className="p-4">
          <div className="d-flex flex-row justify-content-between align-items-start w-100 p-4" style={{backgroundColor: color1}}>
          {/* {logoUrl && <img src={logoUrl} alt="Hospital Logo" className="hospital-logo" />} */}
            <div>
              <h6>Address</h6>
              {address.streetName1 && <div>{address.streetName1},{address.streetName2}</div>}
              {address.area && <div>{address.area}</div>}
              {address.city && <div>{address.city}</div>}
            </div>
            <div className="w-100" style={{marginLeft:"10px",textAlign:"center"}}>
           
              <h4 className="fw-bold my-2">{this.state.billFrom || 'John Uberbacher'}</h4>
              <h6 className="fw-bold text-secondary mb-1">
                Invoice #: {this.state.invoiceNumber || ''}
              </h6>
            </div>
            <div className="text-end ms-4">
              <h6 className="fw-bold mt-1 mb-2">Amount&nbsp;Due:</h6>
              <h5 className="fw-bold text-secondary">{this.state.currency} {this.state.total}</h5>
            </div>
          </div>
          <div className="p-4">
            <Row className="mb-4">
              <Col md={4}>
                <div className="fw-bold">Billed to:</div>
                <div>{this.state.billTo || ''}</div>
                <div>{this.state.billToAddress || ''}</div>
                <div>{this.state.billToEmail || ''}</div>
              </Col>
              <Col md={4}>
                <div className="fw-bold">Billed From:</div>
                <div>{this.state.billFrom || ''}</div>
                <div>{this.state.billFromAddress || ''}</div>
                <div>{this.state.billFromEmail || ''}</div>
              </Col>
              <Col md={4}>
                <div className="fw-bold mt-2">Date Of Issue:</div>
                <div>{this.state.dateOfIssue || ''}</div>
              </Col>
            </Row>
            <Table className="mb-0">
              <thead>
                <tr>
                  <th>Medicine Name</th>
                  <th>QTY</th>
                  <th className="text-end">PRICE</th>
                  <th className="text-end">AMOUNT</th>
                </tr>
              </thead>
              <tbody>
                {this.state.items.map((item, i) => {
                  return (
                    <tr id={i} key={i}>
                      <td>
                        {this.state.selectedMedicineNames[i] || item.name}
                      </td>
                      <td style={{ width: '70px' }}>
                        {item.quantity}
                      </td>
                      <td className="text-end" style={{ width: '100px' }}>{this.state.currency} {item.price}</td>
                      <td className="text-end" style={{ width: '100px' }}>{this.state.currency} {item.price * item.quantity}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Table>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr className="text-end">
                  <td></td>
                  <td className="fw-bold" style={{ width: '100px' }}>SUBTOTAL</td>
                  <td className="text-end" style={{ width: '100px' }}>{this.state.currency} {this.state.subTotal || '0.00'}</td>
                </tr>
                {parseFloat(this.state.taxAmount) !== 0.00 &&
                  <tr className="text-end">
                    <td></td>
                    <td className="fw-bold" style={{ width: '100px' }}>TAX</td>
                    <td className="text-end" style={{ width: '100px' }}>{this.state.currency} {this.state.taxAmount || '0.00'}</td>
                  </tr>
                }
                {parseFloat(this.state.discountAmount) !== 0.00 &&
                  <tr className="text-end">
                    <td></td>
                    <td className="fw-bold" style={{ width: '100px' }}>DISCOUNT</td>
                    <td className="text-end" style={{ width: '100px' }}>{this.state.currency} {this.state.discountAmount || '0.00'}</td>
                  </tr>
                }
                <tr className="text-end">
                  <td></td>
                  <td className="fw-bold" style={{ width: '100px' }}>TOTAL</td>
                  <td className="text-end" style={{ width: '100px' }}>{this.state.currency} {this.state.total || '0.00'}</td>
                </tr>
                <table style={{width: "300%"}}>
                  <thead>
                    <tr>
                      <th style={{textAlign:"center"}}>S.NO</th>
                      <th style={{textAlign:"center"}}>Payment Type</th>
                      <th style={{textAlign:"center"}}>Payment Id</th>
                      <th style={{textAlign:"center"}}>Date</th>
                      <th style={{textAlign:"center"}}>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Render payment type rows in the bill */}
                    {this.state.rows.map((row, index) => (
                      <tr className="text-end" key={index}>
                        <td style={{textAlign:"center"}}>{index + 1}</td>
                        <td className="fw-bold" style={{ width: '100px', textAlign:"center" }}>
      
                          <div style={{ fontWeight: "normal",  textAlign:"center" }}>
                            {`${row.paymentMode}`}
                          </div>
                        </td>
                        <td>
                        <div style={{ fontWeight: "normal", textAlign: "center" }}>
                          {`${row.paymentId || 'N/A'}`}
                        </div>
                        </td>
                        <td>
                        <div style={{ fontWeight: "normal",  textAlign:"center" }}>
                            {`${row.date}`}
                        </div>
                        </td>
                        <td className="text-center" style={{ width: '100px',  textAlign:"center" }}>
                          {this.state.currency} {(parseFloat(row.amount) || 0).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  </table>
              </tbody>
            </Table>
            <div className="mt-4">
              <div className="fw-bold">Notes:</div>
              <div>{this.state.notes}</div>
              {this.state.additionalNotes.map((note, index) => (
                <div key={index}>{note}</div>
              ))}
            </div>
            <div className="mt-4 text-center footerbill" style={{backgroundColor: color2 || "lightgrey"}}>
              {/* <p className="fw-bold">Thank you!</p>
              <p>Contact us: {this.state.billFromEmail || 'email@example.com'}</p>
              <p>{this.state.billFromAddress || '1234 Street, City, Country'}</p> */}
              <div className="social-icons mt-3 d-flex justify-content-center">
                {this.state.socialMedia?.facebook && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-facebook fa-2x"></i>
                    <span className="ms-2">{this.state.socialMedia.facebook}</span>
                  </div>
                )}
                {this.state.socialMedia?.instagram && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-instagram fa-2x"></i>
                    <span className="ms-2">{this.state.socialMedia.instagram}</span>
                  </div>
                )}
                {this.state.socialMedia?.linkedin && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-linkedin fa-2x"></i>
                    <span className="ms-2">{this.state.socialMedia.linkedin}</span>
                  </div>
                )}
                {this.state.socialMedia?.youtube && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-youtube fa-2x"></i>
                    <span className="ms-2">{this.state.socialMedia.youtube}</span>
                  </div>
                )}
              </div>



            </div>
          </div>
        </div>
        
    </Layout>)
  }
}




export default InvoiceForm;

