import React,{ useState } from "react";
import Layout from "../core/Layout";
import firebase from 'firebase/compat/app';
import {  uploadBytes, getDownloadURL} from 'firebase/compat/storage';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Select from 'react-select';
import InputGroup from 'react-bootstrap/InputGroup';
import InvoiceItem1 from "./PresInvoiceItem";
import InvoiceModal1 from "./PresInvoiceModal";
import axios from 'axios';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FaTrash } from 'react-icons/fa';
import "./Prescription.css";
import Loader from "../frontpage/Loader";
import Loader1 from "../Component/Loader";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCI59gnHA9AZAANjoy082vht3fCgatchEg",
  authDomain: "hospital-management-syst-7e35d.firebaseapp.com",
  databaseURL: "https://hospital-management-syst-7e35d-default-rtdb.firebaseio.com",
  projectId: "hospital-management-syst-7e35d",
  storageBucket: "hospital-management-syst-7e35d.firebasestorage.app",
  messagingSenderId: "59517358381",
  appId: "1:59517358381:web:96f502e3832ddf1a8fc3a4",
  measurementId: "G-EYLWMBT9SP"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const storage = firebase.storage();

class InvoiceForm1 extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: false, 
        isOpen: false,
        currency: '₹',
        currentDate: new Date().toLocaleDateString(),
        invoiceNumber: 1,
        dateOfIssue: '',
        billTo: '',
        billToEmail: '',
        billToAddress: '',
        billToGender: '',
        billFrom: '',
        billFromEmail: '',
        billFromAddress: '',
        billFromHospitalName: '',
        notes: '',
        total: '0.00',
        subTotal: '0.00',
        taxRate: '',  
        taxAmount: '0.00',
        discountRate: '',
        discountAmount: '0.00',
        items: [{
          id: 0,
          name: '',
          description: '',
          price: '',
          quantity: 1,
          medicineId: null,
          quantities: {
            morningBefore: 0,
            morningAfter: 0,
            noonBefore: 0,
            noonAfter: 0,
            eveningBefore: 0,
            eveningAfter: 0,
            nightBefore: 0,
            nightAfter: 0
          }
        }],
       
        patientOptions: [],
        hospitalOptions: [],
        medicineOptions: [],
        selectedMedicineNames: [],
        showInvoiceCapture: false,
        additionalNotes: [],
        hospital: "",
        logoUrl:"",
        error:"",
        color1:"lightgrey",
        color2:"",
        color3:"",
        color4:"",
        socialMedia: "",
        address: {},
        successMessage: "",
      };
  
      this.editField = this.editField.bind(this);
      this.handleAddEvent = this.handleAddEvent.bind(this);
      this.handleRowDel = this.handleRowDel.bind(this);
      this.onItemizedItemEdit = this.onItemizedItemEdit.bind(this);
      this.handleCalculateTotal = this.handleCalculateTotal.bind(this);
      this.onCurrencyChange = this.onCurrencyChange.bind(this);
      this.openModal = this.openModal.bind(this);
      this.closeModal = this.closeModal.bind(this);
      this.fetchPatients = this.fetchPatients.bind(this);
      this.handlePatientSelect = this.handlePatientSelect.bind(this);
      this.handleHospitalSelect = this.handleHospitalSelect.bind(this);
      this.fetchMedicines = this.fetchMedicines.bind(this);
      this.printInvoice = this.printInvoice.bind(this);
      this.onRowAddNotes = this.onRowAddNotes.bind(this);
      this.handleDeleteNote = this.handleDeleteNote.bind(this);
      this.handleAdditionalNoteChange = this.handleAdditionalNoteChange.bind(this);
    }
  
    componentDidMount() {
      this.handleCalculateTotal();
      this.fetchPatients();
      this.fetchDoctor();
      this.fetchMedicines();
      this.fetchLatestInvoiceNumber();
      this.fetchUserHospital();
    }
  
    async fetchPatients() {
      try {
        const hospitalId = this.state.hospital;

        if (hospitalId) {
        const response = await axios.get(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/createbill/get-patients?hospitalId=${hospitalId}`);
        const patientOptions = response.data.map(patient => ({
          value: patient.patientId,
          label: `${patient.patientId} - ${patient.Name}`,
          email: patient.Email,
          address: patient.Address,
          phone: patient.PhoneNo,
          gender: patient.Gender,
          data: patient
        }));
        this.setState({ patientOptions });
      } else {
        console.error("Hospital ID is not set.");
      }
      }catch (error) {
        console.error("There was an error fetching the patients!", error);
      }
    }
  
    async fetchDoctor() {
      try {
        const hospitalId = this.state.hospital;

        if (hospitalId) {
        const response = await axios.get(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/prescription/get-doctor?hospitalId=${hospitalId}`);
        console.log("response:", response);
        const doctorOptions = response.data.map(doctor => ({
          value: doctor.doctorId,
          label: `${doctor.Name}`,
          email: doctor.Email,
          phone: doctor.PhoneNo,
          data: doctor,
          hospitalName: doctor.hospitalName,
          Address: doctor.Address
        }));
        this.setState({ doctorOptions });
      } else {
        console.error("Hospital ID is not set.");
      }
      } catch (error) {
        console.error("There was an error fetching the hospitals!", error);
      }
    }
  
    async fetchMedicines() {
      try {
        const hospitalId = this.state.hospital;
        const response = await axios.get(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/createbill/get-medicines?hospitalId=${hospitalId}`);
        const medicineOptions = response.data.map(medicine => ({
          value: medicine.medicineId,
          label: medicine.MedicineName,
          price: medicine.Price,
          availableQuantity: medicine.AvailableQuantity || medicine.Quantity,
          data: medicine
        }));
        this.setState({ medicineOptions });
      } catch (error) {
        console.error("There was an error fetching the medicines!", error);
      }
    }
    
    fetchUserHospital = async () => {
      try {
        const user = firebase.auth().currentUser;
        if (user) {
          const userId = user.uid;
          const response = await axios.get(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getUserData/${userId}`);
          const hospitalId = response.data.hospital.HospitalId;
          this.setState({ hospital: hospitalId }, () => {
            this.fetchLogoUrl();
            this.fetchColorCode();
            this.fetchPatients();
            this.fetchDoctor();
            this.fetchMedicines();
            this.fetchLatestInvoiceNumber();
            this.fetchAddress();
          });
        }
      } catch (error) {
        console.error("Error fetching user hospital:", error);
        this.setState({ error: "Failed to fetch user hospital" });
      }
    };
  
    fetchLogoUrl = () => {
      const { hospital } = this.state;
      if (hospital) {
        fetch(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getLogoUrl/${hospital}`)
          .then((response) => response.json())
          .then((data) => {
            if (data.logoUrl) {
              this.setState({ logoUrl: data.logoUrl });
            } else {
              this.setState({ error: "Logo URL not found" });
            }
          })
          .catch((error) => {
            console.error("Error fetching logo URL:", error);
            this.setState({ error: "Error fetching logo URL" });
          });
      }
    };
  
    fetchColorCode =()=>{
      const { hospital } = this.state;
      if (hospital) {
        fetch(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getHospitalColors/${hospital}`)
          .then((response) => response.json())
          .then((data) => {
            if (data.pdfHeaderColor && data.pdfFooterColor && data.pdfHeaderTextColor && data.pdfFooterTextColor) {
              this.setState({ color1: data.pdfHeaderColor, color2: data.pdfFooterColor, color3: data.pdfHeaderTextColor, color4: data.pdfFooterTextColor, socialMedia: data.socialMedia || {}  });
            } else {
              this.setState({ error: "colors not found" });
            }
          })
          .catch((error) => {
            console.error("Error fetching hospital colors:", error);
            this.setState('Error fetching colors');
          });
      }
    }

    
  fetchAddress = () => {
    const { hospital } = this.state;
    if (hospital) {
      fetch(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getHospitalAddress/${hospital}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.address) {
            this.setState({ address: data.address });
          } else {
            this.setState({ error: "Address not found" });
          }
        })
        .catch((error) => {
          console.error("Error fetching hospital address:", error);
          this.setState({ error: 'Error fetching address' });
        });
    }
  }


    handlePatientSelect(selectedOption) {
      const { value , label, email, address, data, phone, gender} = selectedOption;
  
      const billingAmount = parseFloat(data?.BillingAmount || 0);
      this.setState({
        billTo: label,
        billToEmail: email,
        billToAddress: address,
        billToPhone: phone,
        billToData: data,
        billToGender: gender,
        // subTotal: billingAmount.toFixed(2), // Update subtotal with billing amount
        patientId: value,
        patientName: data.Name,
      }, this.handleCalculateTotal); // Recalculate total
    }
  
    handleHospitalSelect(selectedOption) {
      const { label, email, address, phone, hospitalName, Address} = selectedOption;
  
      this.setState({
        billFrom: label,
        billFromEmail: email,
        billFromAddress: address,
        billFromPhone: phone,
        billFromHospitalName: hospitalName,
        billFromAddress: Address
      });
    }
  
    handleRowDel(items) {
      const updatedItems = this.state.items.filter(item => item.id !== items.id);
      this.setState({ items: updatedItems }, this.handleCalculateTotal);
    }
  
    handleAddEvent() {
     
      const id = (this.state.items.length === 0) ? 0 : this.state.items[this.state.items.length - 1].id + 1;
      const item = {
        id: id,
        name: '',
        description: '',
        price: '',
        quantity: 1,
        medicineId: null,
        quantities: {
          morningBefore: 0,
          morningAfter: 0,
          noonBefore: 0,
          noonAfter: 0,
          eveningBefore: 0,
          eveningAfter: 0,
          nightBefore: 0,
          nightAfter: 0
        }
      };
      this.setState((prevState) => ({
        items: [...prevState.items, item]
      }));
    }

    onRowAddNotes(event) {
      event.preventDefault(); // Prevent the default button action
      this.setState(prevState => ({
        additionalNotes: [...prevState.additionalNotes, '']
      }));
    }

    handleAdditionalNoteChange(index, event) {
      const newNotes = [...this.state.additionalNotes];
      newNotes[index] = event.target.value;
      this.setState({ additionalNotes: newNotes });
    }

    handleDeleteNote(index) {
      this.setState(prevState => {
        const newNotes = prevState.additionalNotes.filter((note, i) => i !== index);
        return { additionalNotes: newNotes };
      });
    }
  
  
    handleCalculateTotal() {
     
      const items = this.state.items;
  
      let subTotal = 0;
      items.forEach(item => {
        subTotal += parseFloat(item.price) * parseFloat(item.quantity);
      });
  
      const discountAmount = (this.state.discountRate / 100) * subTotal;
      const taxAmount = (this.state.taxRate / 100) * (subTotal - discountAmount);
      const total = subTotal - discountAmount + taxAmount;
  
      this.setState({
        subTotal: subTotal.toFixed(2),
        discountAmount: discountAmount.toFixed(2),
        taxAmount: taxAmount.toFixed(2),
        total: total.toFixed(2)
      });
  }
  
  onItemizedItemEdit(event) {
    if (!event || !event.target) {
      console.error('Invalid event object:', event);
      return;
    }
    
    const { id, name, value } = event.target;
    
    this.setState(prevState => {
      const updatedItems = prevState.items.map(item => {
        if (item.id === parseInt(id)) {
          return { ...item, [name]: value };
        }
        return item;
      });
      console.log('Editing item:', updatedItems);
      return { items: updatedItems };
    });
  }
  
    editField(event) {
      event.preventDefault();
      console.log('Event:', event);
      console.log('Event Target:', event.target);
      console.log('Event Target Name:', event.target.name);
      console.log('Event Target Value:', event.target.value);
      this.setState({ [event.target.name]: event.target.value });
    }
  
  
    onCurrencyChange(event) {
      this.setState({ currency: event.target.value });
    }
  
    openModal(event) {
      event.preventDefault();
      this.handleCalculateTotal();
      this.setState({ isOpen: true });
    }
  
    closeModal() {
      this.setState({ isOpen: false });
    }
  
   
    fetchLatestInvoiceNumber = async () => {
      const hospitalId = this.state.hospital;
      console.log("hospitalIdfetch:", hospitalId);
      try {
        const response = await axios.get(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/prescription/latestInvoiceNumber?hospitalId=${hospitalId}`);
        if (response.data.success) {
          this.setState({
            invoiceNumber: response.data.latestInvoiceNumber + 1,
          });
        }
      } catch (error) {
        console.error("Error fetching latest invoice number:", error);
      }
    };
    
    async printInvoice(event) {
      event.preventDefault();
      this.setState({ loading: true });
      const input = document.getElementById('invoiceCaptureprint');
      const canvas = await html2canvas(input, { scale: 1 });
      const imgData = canvas.toDataURL('image/png', 0.8);
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'pt',
        format: [612, 792]
      });
      pdf.internal.scaleFactor = 1;
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      const fileName = `${this.state.patientId}_${this.state.billFrom}.pdf`;
      pdf.save(fileName);
      const pdfBlob = pdf.output('blob');
  
      // Upload PDF to Firebase Storage
  const storageRef = storage.ref();
  const pdfRef = storageRef.child(`pdfs/${fileName}`);
  let pdfURL = '';
  let uploadSuccessful = false;

  try {
    await pdfRef.put(pdfBlob);
    pdfURL = await pdfRef.getDownloadURL();
    uploadSuccessful = true;
  } catch (error) {
    console.error('Error uploading PDF:', error);
  }

  // Prepare prescription data
  const prescriptionData = {
    hospitalId: this.state.hospital,
    url: uploadSuccessful ? pdfURL : 'placeholder-url-or-error',
    patientId: this.state.patientId,
    patientName: this.state.patientName,
    billTo: this.state.billTo,
    billToEmail: this.state.billToEmail,
    billToAddress: this.state.billToAddress,
    billToGender: this.state.billToGender,
    billToPhone: this.state.billToPhone,
    billFrom: this.state.billFrom,
    billFromEmail: this.state.billFromEmail,
    billFromAddress: this.state.billFromAddress,
    billFromPhone: this.state.billFromPhone,
    billFromHospitalName: this.state.billFromHospitalName || '',
    notes: this.state.notes,
    additionalNotes: JSON.stringify(this.state.additionalNotes),
    items: JSON.stringify(this.state.items),
    invoiceNumber: this.state.invoiceNumber,
    dateOfIssue: this.state.dateOfIssue,
    days: this.state.days,
    color1: this.state.color1,
    color2: this.state.color2,
    color3: this.state.color3,
    color4: this.state.color4,
    address: this.state.address,
    socialMedia: this.state.socialMedia,
  };

  // Store prescription data
  try {
    const createResponse = await fetch('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/create-prescription', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(prescriptionData),
    });

    const createData = await createResponse.json();
    console.log("createData:", createData);
    if (!createResponse.ok) {
      throw new Error('Failed to store prescription');
    }

    console.log(createData);

    this.setState({
      successMessage: "Prescription successfully created and PDF uploaded!",
    });
  } catch (error) {
    console.error('Error storing prescription:', error);
  }finally {
    
    this.setState({ loading: false }); 
  }
}
    
    render() {
    
      const { items, color1, color2,color3, color4, logoUrl,  socialMedia , address, hospital} = this.state;
      
      return (
          <Layout title="List Prescriptions" className="container-fluid">
          <Loader/>
           <h1 className="mt-4 color-bright">Prescription</h1>
          
      <Form >
        <Row>
          <Col md={8} lg={9}>
          <div id="invoiceCapture12">
            <Card className="p-4 p-xl-5 my-3 my-xl-4">
              <div className="d-flex flex-row align-items-start justify-content-between mb-3">
                <div class="d-flex flex-column">
                  <div className="d-flex flex-column">
                    <div class="mb-2">
                      <span className="fw-bold">Current&nbsp;Date:&nbsp;</span>
                      <span className="current-date">{new Date().toLocaleDateString()}</span>
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <span className="fw-bold d-block me-2">Due&nbsp;Date:</span>
                    <Form.Control type="date" value={this.state.dateOfIssue} name={"dateOfIssue"} onChange={(event) => this.editField(event)} style={{
                        maxWidth: '150px'
                      }} required="required"/>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <span className="fw-bold me-2">Prescription&nbsp;Number:&nbsp;</span>
                  <Form.Control type="number" value={this.state.invoiceNumber} name={"invoiceNumber"} onChange={(event) => this.editField(event)} min="1" style={{
                      maxWidth: '70px'
                    }} required="required"/>
                </div>
              </div>
              <hr className="my-4"/>
              <Row className="mb-5">
                <Col>
                  <Form.Label className="fw-bold">Patient:</Form.Label>
                  <Select
                        options={this.state.patientOptions}
                        onChange={this.handlePatientSelect}
                        placeholder="Search for a patient..."
                        className="my-2"
                      />
                  <Form.Control placeholder={"Who is this invoice to?"} rows={3} value={this.state.billTo} type="text" name="billTo" className="my-2" onChange={(event) => this.editField(event)} autoComplete="name" required="required"/>
                  <Form.Control placeholder={"Billing Phone number"} value={this.state.billToPhone} type="text" name="billToPhone" className="my-2" autoComplete="phone" onChange={(event) => this.editField(event)} required="required"/>
                  <Form.Control placeholder={"Email address"} value={this.state.billToEmail} type="email" name="billToEmail" className="my-2" onChange={(event) => this.editField(event)} autoComplete="email" required="required"/>
                  <Form.Control placeholder={"Billing address"} value={this.state.billToAddress} type="text" name="billToAddress" className="my-2" autoComplete="address" onChange={(event) => this.editField(event)} required="required"/>
                  <Form.Control placeholder={"Gender"} value={this.state.billToGender} type="text" name="billToGender" className="my-2" autoComplete="gender" onChange={(event) => this.editField(event)} required="required"/>
                </Col>
                <Col>
                  <Form.Label className="fw-bold">Doctor:</Form.Label>
                  <Select
                        options={this.state.doctorOptions}
                        onChange={this.handleHospitalSelect}
                        placeholder="Search for a Doctor..."
                        className="my-2"
                      />
                  <Form.Control placeholder={"Who is this invoice from?"} rows={3} value={this.state.billFrom} type="text" name="billFrom" className="my-2" onChange={(event) => this.editField(event)} autoComplete="name" required="required"/>
                  <Form.Control placeholder={"Phone Number"} value={this.state.billFromPhone} type="text" name="billFromPhone" className="my-2" onChange={(event) => this.editField(event)} autoComplete="phone" required="required"/>
                  <Form.Control placeholder={"Email address"} value={this.state.billFromEmail} type="email" name="billFromEmail" className="my-2" onChange={(event) => this.editField(event)} autoComplete="email" required="required"/>
                  {/* <Form.Control placeholder={"Billing address"} value={this.state.billFromAddress} type="text" name="billFromAddress" className="my-2" autoComplete="address" onChange={(event) => this.editField(event)} required="required"/> */}
                </Col>
                
              </Row>
             
              <InvoiceItem1 onItemizedItemEdit={this.onItemizedItemEdit} onRowAdd={this.handleAddEvent} onRowDel={this.handleRowDel} currency={this.state.currency} items={this.state.items} medicineOptions={this.state.medicineOptions}  handleCalculateTotal={this.handleCalculateTotal} />
                       
              <hr className="my-4"/>
              <Form.Label className="fw-bold">Notes:</Form.Label>
              <Form.Control placeholder="Happy Medicial" name="notes" value={this.state.notes} onChange={(event) => this.editField(event)} as="textarea" className="my-2" rows={1}/>
              {this.state.additionalNotes.map((note, index) => (
                    <Form.Group key={index} className="d-flex align-items-center my-2">
                      <Form.Control
                        as="textarea"
                        rows="4"
                        value={note}
                        className="flex-grow-1"
                        style={{ marginRight: '10px' }}
                        onChange={e => this.handleAdditionalNoteChange(index, e)}
                      />
                         <FaTrash
                        onClick={() => this.handleDeleteNote(index)}
                        style={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }}
                      />
                    </Form.Group>
                  ))}
              <Button variant="primary" type="add" className="fw-bold" onClick={this.onRowAddNotes} style={{width:"125px",height:"50px"}}>Add Notes</Button>
            </Card>
            </div>
          </Col>
          
          <Col md={4} lg={3}>
            <div className="sticky-top pt-md-3 pt-xl-4">
              <Button variant="primary" type="submit" onClick={this.openModal} className="d-block w-100">Review Prescription</Button>
              
             <InvoiceModal1 showModal={this.state.isOpen} closeModal={this.closeModal} info={this.state} items={this.state.items} currency={this.state.currency} subTotal={this.state.subTotal} taxAmmount={this.state.taxAmmount} discountAmmount={this.state.discountAmmount} total={this.state.total} patientId={this.state.patientId} patientName={this.state.patientName}
              color1={this.state.color1}
              color2={this.state.color2} 
              color3={this.state.color3} 
              color4={this.state.color4}
              address={this.state.address}
              socialMedia={this.state.socialMedia}
              hospital={this.state.hospital}
             />
             <Button variant="secondary" type="print" className="d-block w-100 mb-4" onClick={this.printInvoice}>Print</Button>
             {this.state.loading && <Loader1 />}
             {this.state.successMessage && (
                <div className="mt-3 text-success">
                  {this.state.successMessage}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Form>
    
      <div id="invoiceCaptureprint" className="p-4">
      <div className="d-flex flex-row justify-content-between align-items-start w-100 p-4" style={{backgroundColor: color1}}>
      {/* {logoUrl && <img src={logoUrl} alt="Hospital Logo" className="hospital-logo" />} */}
      <div>
              <h6>Address</h6>
              {address.streetName1 && <div>{address.streetName1},{address.streetName2}</div>}
              {address.area && <div>{address.area}</div>}
              {address.city && <div>{address.city}</div>}
            </div>
        <div className="w-100" style={{marginLeft:"10px",textAlign:"center"}}>
          <h4 className="fw-bold my-2">{this.state.billFrom || 'John Uberbacher'}</h4>
          <h6 className="fw-bold text-secondary mb-1">
            Prescription #: {this.state.invoiceNumber || ''}
          </h6>
          <h2 className="fw-bold my-2 text-center">{this.state.billFromHospitalName || ''}</h2>
        </div>
      </div>
      <div className="p-4">
        <Row className="mb-4">
          <Col md={4}>
            <div className="fw-bold">Patient:</div>
            <div>Name:{this.state.billTo || ''}</div>
            <div>Phone:{this.state.billToPhone || ''}</div>
            <div>Email:{this.state.billToEmail || ''}</div>
            <div>Gender:{this.state.billToGender || ''}</div>
            <div>Address:{this.state.billToAddress || ''}</div>
          </Col>
          <Col md={4}>
            <div className="fw-bold">Doctor:</div>
            <div>Name:{this.state.billFrom || ''}</div>
            <div>Address:{this.state.billFromAddress || ''}</div>
            <div>Email:{this.state.billFromEmail || ''}</div>
          </Col>
          <Col md={4}>
            <div className="fw-bold mt-2">Date Of Issue:</div>
            <div>{this.state.dateOfIssue || ''}</div>
          </Col>
        </Row>
        <Table className="mb-0">
          <thead>
            <tr>
              <th className="text-center">S.No</th>
              <th className="text-center">Medicine Name</th>
              <th className="text-center">Session</th>
              <th className="text-center">Days</th>
            </tr>
          </thead>
          <tbody>
          {this.state.items.length > 0 ? (
              this.state.items.map((item, i) => (
                <tr id={i} key={i}>
                  <td className="text-center">{i + 1}</td>
                  <td className="text-center">{item.name}</td>
                  <td className="session-quantity-cell text-center">
                      <table className="nested-table">
                        <thead>
                          <tr>
                            <th></th>
                            {['Mor', 'Noon', 'Eve', 'Nig'].map(time => (
                              <th key={time}>{time}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {['Before', 'After'].map(period => (
                            <tr key={period}>
                              <td>{period}</td>
                              {['morning', 'noon', 'evening', 'night'].map(time => (
                                <td key={`${time}${period}`}>
                                  {item.quantities[`${time}${period}`] || 0}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                    <td style={{textAlign:"center"}}>
                    {item.days || 0}
                    </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">No items available</td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className="mt-4">
              <div className="fw-bold">Notes:</div>
              <div>{this.state.notes}</div>
              {this.state.additionalNotes.map((note, index) => (
                <div key={index}>{note}</div>
              ))}
            </div>
            <div className="mt-4 text-center" style={{backgroundColor: color2 || "lightgrey"}}>
            <div className="social-icons mt-3 d-flex justify-content-center">
                {this.state.socialMedia?.facebook && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-facebook fa-2x"></i>
                    <span className="ms-2">{this.state.socialMedia.facebook}</span>
                  </div>
                )}
                {this.state.socialMedia?.instagram && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-instagram fa-2x"></i>
                    <span className="ms-2">{this.state.socialMedia.instagram}</span>
                  </div>
                )}
                {this.state.socialMedia?.linkedin && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-linkedin fa-2x"></i>
                    <span className="ms-2">{this.state.socialMedia.linkedin}</span>
                  </div>
                )}
                {this.state.socialMedia?.youtube && (
                  <div className="d-flex align-items-center mx-2">
                    <i className="fab fa-youtube fa-2x"></i>
                    <span className="ms-2">{this.state.socialMedia.youtube}</span>
                  </div>
                )}
              </div>
            </div>
      </div>
    </div>
          
      </Layout>)
    }
  }
  
  export default InvoiceForm1;
  
  