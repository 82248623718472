import React, { Fragment, useState, useEffect } from "react";
import Layout from "../core/Layout";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Pagination from "react-js-pagination";
import Loader from "../frontpage/Loader";
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

const ListVendorBill = () => {
    const [expenses, setExpenses] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [activePage, setActivePage] = useState(1);
    const itemsPerPage = 10;

    const [formData1, setFormData1] = useState({
        userId: "",
        HospitalName: "",
    });

    const [HospitalName, setHospitalName] = useState("");

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
            if (currentUser) {
                const userId = currentUser.uid;

                setFormData1((prevData) => ({
                    ...prevData,
                    userId: userId,
                    HospitalName: HospitalName,
                }));

                try {
                    const response = await axios.get(
                        `https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getHospitalName/${userId}`
                    );
                    const addressData = response.data;
                    setFormData1((prevData) => ({
                        ...prevData,
                        HospitalName: addressData.HospitalName || " ",
                        HospitalId: addressData.HospitalId || " ",
                    }));
                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            }
        });

        return () => unsubscribe();
    }, []);

    const hospitalId = formData1.HospitalId;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/Vendorsplitbills/single", {
                    params: { hospitalId }
                });
                setExpenses(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        if (hospitalId) {
            fetchData();
        }
    }, [hospitalId]);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const filteredExpenses = expenses.filter(expense =>
        String(expense.patientId).toLowerCase().includes(searchQuery.toLowerCase()) ||
        String(expense.invoice).toLowerCase().includes(searchQuery.toLowerCase()) ||
        String(expense.patientName).toLowerCase().includes(searchQuery.toLowerCase())
    );

    filteredExpenses.sort((a, b) => a.invoice - b.invoice);

    const indexOfLastItem = activePage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredExpenses.slice(indexOfFirstItem, indexOfLastItem);

    const handleDelete = async (invoiceNumber) => {
        try {
            await axios.delete(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/delete-vendorbill/${invoiceNumber}`, {
                params: { hospitalId }
            });
            // After successful deletion, refresh the list
            setExpenses(expenses.filter(item => item.invoice !== invoiceNumber));
        } catch (error) {
            console.error("Error deleting data:", error);
        }
    };

    const [expandedRow, setExpandedRow] = useState(null);

    const toggleDetails = (rowIndex) => {
        if (expandedRow === rowIndex) {
            setExpandedRow(null); // Collapse the row if clicked again
        } else {
            setExpandedRow(rowIndex); // Expand the clicked row
        }
    };

    return (
        <Layout title="Profile" description="list treatment categories" className="container-fluid">
            <br />
            <Loader />
            <h1 className="mt-4 color-bright">List Vendor Bill</h1>
            <br /><br />
            <div className="input-group mb-4" style={{ width: "40%" }}>
                <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearch}
                    className="form-control"
                />
                <div className="input-group-append">
                    <span className="input-group-text">
                        <i className="fas fa-search"></i>
                    </span>
                </div>
            </div>
            {filteredExpenses.length === 0 ? (
                <div className="tbl-header">
                    <table>
                        <thead>
                            <tr>
                                <th>Invoice Number</th>
                                <th>Patient Id</th>
                                <th>Patient Name</th>
                                <th>Amount</th>
                                <th>View</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-center" colSpan="5">No Data</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={filteredExpenses.length}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                    />
                    <div className="tbl-header table-responsive">
                        <table id="listPatients">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Invoice Number</th>
                                    <th>Vendor Id</th>
                                    <th>Vendor Name</th>
                                    <th>Total Amount</th>
                                    <th>Total Paid</th>
                                    <th>Due Amount</th>
                                    <th>Status</th>
                                    <th>View</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                    <th>Full View</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((expense, i) => (
                                    <Fragment key={i}>
                                        <tr>
                                            <td>{expense.paymentDates[0] || 'N/A'}</td>
                                            <td>{expense.invoice}</td>
                                            <td>{expense.vendorId}</td>
                                            <td>{expense.vendorName}</td>
                                            <td>{expense.total}</td>
                                            <td>{expense.totalPaid?.[0] || 'N/A'}</td>
                                            <td>{expense.amountDue}</td>
                                            <td
                                                style={{
                                                    backgroundColor: expense.status === 'Pending' ? 'orange' : expense.status === 'Completed' ? 'green' : 'transparent',
                                                    color: expense.status === 'Pending' || expense.status === 'Completed' ? 'white' : 'inherit'
                                                }}
                                            >
                                                {expense.status}
                                            </td>

                                            <td>
                                                <a href={expense.url} target="_blank" rel="noopener noreferrer">
                                                    View Invoice
                                                </a>
                                            </td>
                                            <td>
                                                <Link to={`/update-vendor/${expense.invoice}`} className="btn btn-link">
                                                    <i className="fas fa-edit"></i>
                                                </Link>
                                            </td>
                                            <td>
                                                <button className="btn btn-link" onClick={() => handleDelete(expense.invoice)}>
                                                    <i className="fas fa-trash"></i>
                                                </button>
                                            </td>
                                            <td>
                                                <button onClick={() => toggleDetails(i)} style={{backgroundColor:"blue",border:"none",borderRadius:"5px"}}>
                                                    <i className={`text-white fas fa-chevron-${expandedRow === i ? 'up' : 'down'}`}></i>
                                                </button>
                                            </td>
                                        </tr>
                                        {expandedRow === i && (
                                            
                                            <tr>
                                                <td colSpan="10">
                                                <h3 className="text-center" style={{color:"blue"}}>Bill Details</h3>
                                                    <table className="table table-bordered mt-4">
                                                        <thead>
                                                            
                                                            <tr>
                                                                <th>S.No</th>
                                                                <th>Bill Date</th>
                                                                <th>Payment Amount</th>
                                                                
                                                                <th>Amount Due</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {expense.allPaids && expense.allPaids.map((paid, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{expense.allPaidsDate ? expense.allPaidsDate[index] : 'N/A'}</td>
                                                                    <td>{paid}</td>
                                                                   
                                                                    <td>{expense.allPaidsAmountDue ? expense.allPaidsAmountDue[index] : 'N/A'}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    <h3 className="text-center" style={{color:"blue"}}>Payment Details</h3>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>S.No</th>
                                                               
                                                                <th>Payment Id</th>
                                                                <th>Payment Type</th>
                                                                <th>Amount</th>
                                                                
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {expense.paymentMode.map((mode, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    
                                                                    <td>{expense.paymentIds ? expense.paymentIds[index] : 'N/A'}</td>
                                                                    <td>{mode}</td>
                                                                    <td>{expense.paymentAmounts ? expense.paymentAmounts[index] : 'N/A'}</td>
                                                                    
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        )}
                                    </Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </Layout>
    );
};

export default ListVendorBill;
