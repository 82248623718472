import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { BiTrash } from "react-icons/bi";
import EditableField from './EditableField';
import Select from 'react-select';
import "./Invoice.css";

class InvoiceItem extends React.Component {
  render() {
    const { onItemizedItemEdit, currency, onRowDel, medicineOptions, items, onRowAdd } = this.props;
    
    const itemTable = items.map(item => (
      <ItemRow
        onItemizedItemEdit={onItemizedItemEdit}
        item={item}
        onDelEvent={onRowDel}
        key={item.id}
        currency={currency}
        medicineOptions={medicineOptions}
        handleCalculateTotal={this.props.handleCalculateTotal}
      />
    ));
    
    return (
      <div>
        <Table>
          <thead>
            <tr>
              <th className='text-center'>ITEM</th>
              <th className='text-center'>QTY</th>
              <th className='text-right'>PRICE/RATE</th>
              <th className="text-right">AMOUNT</th>
              <th className="text-center">ACTION</th>
            </tr>
          </thead>
          <tbody>
            {itemTable}
          </tbody>
        </Table>
        <Button className="fw-bold" onClick={onRowAdd}>Add Item</Button>
      </div>
    );

  }

}
class ItemRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      optionFrom: '',
      optionPrice: '',
      optionValue: ''
    };
  }

  handleDelEvent = () => {
    this.props.onDelEvent(this.props.item);
  }

  handleMedicineSelect = (selectedOption) => {
    const { item, onItemizedItemEdit , handleCalculateTotal } = this.props;
    const { value, label, price } = selectedOption;

    this.setState({
      optionFrom: label,
      optionPrice: price,
      optionValue: value
    });
  
    const event = {
      target: {
        id: item.id,
        name: 'name',
        value: label
      }
    };
    onItemizedItemEdit(event);

    const priceEvent = {
      target: {
        id: item.id,
        name: 'price',
        value: price
      }
    };
    onItemizedItemEdit(priceEvent);

    const medicineIdEvent = {
      target: {
        id: item.id,
        name: 'medicineId',
        value: value
      }
    };
    onItemizedItemEdit(medicineIdEvent);

    handleCalculateTotal();
  }

  handleInputChange = (event) => {
    const { onItemizedItemEdit, handleCalculateTotal } = this.props;
    onItemizedItemEdit(event);
    handleCalculateTotal();
  }
  
  render() {
    const { medicineOptions, item, onItemizedItemEdit, currency } = this.props;
    const { optionFrom, optionPrice, optionValue } = this.state;

    const quantity = parseFloat(item.quantity) || 0;
    const price = parseFloat(item.price) || 0;
    const amount = quantity * price;


    return (
      <tr class="table-row">
      <td class="select-cell">
        <Select
          options={medicineOptions}
          onChange={this.handleMedicineSelect}
          placeholder="Select a medicine..."
          formatOptionLabel={option => (
              <div>{option.value} - {option.label}</div>
            )}
          value={medicineOptions.find(option => option.value === item.medicineId) || null}
          className="my-2"
        />
      </td>
      <td class="quantity-cell">
      <input
            type="number"
            className="form-control"
            name="quantity"
            id={item.id}
            value={item.quantity}
            onChange={this.handleInputChange}
          />
      </td>
      <td className="price-cell" style={{paddingLeft:"80px"}}> 
          <input
            type="number"
            className="form-control"
            name="price"
            id={item.id}
            value={optionPrice}
            onChange={this.handleInputChange}
            disabled
          />
        </td>
      <td class="amount-cell">
          <span>{currency} {amount.toFixed(2)}</span>
      </td>
      <td class="action-cell">
        <BiTrash onClick={this.handleDelEvent} style={{ height: '33px', width: '33px', padding: '7.5px' }} className="text-white mt-1 btn btn-danger" />
      </td>
    </tr>
  );
}
}

export default InvoiceItem;