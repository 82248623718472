import React, { useEffect, useState, useRef } from "react";
import Layout from "../core/Layout";
import axios from 'axios';
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import moment from "moment";
import Loader from "../frontpage/Loader";


const ListHospitals =()=>{
    const [getHospitals, setHospitals] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const itemsPerPage = 10;

    useEffect(() => {
        const fetchHospitals = async () => {
          try {
            const response = await axios.get('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/get-hospitals');
            setHospitals(response.data);
          } catch (error) {
            console.error('Error fetching patients: ', error);
          }
        };
    
        fetchHospitals();
      }, []);


      const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
      };
    
      const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
      };

      const handleToggleStatus = async (hospitalId, currentStatus) => {
        const newStatus = currentStatus === 'Yes' ? 'No' : 'Yes';
        try {
          await axios.put(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/update-hospital-status/${hospitalId}`, { status: newStatus });
          setHospitals(prevHospitals =>
            prevHospitals.map(hospital =>
              hospital.HospitalId === hospitalId ? { ...hospital, status: newStatus } : hospital
            )
          );
        } catch (error) {
          console.error('Error updating hospital status:', error);
        }
      };
    
    
      const filteredHospitals = getHospitals.filter(hospital => {
        const lowercasedSearchTerm = searchTerm.toLowerCase();
        const nameMatch = hospital.HospitalName ? hospital.HospitalName.toLowerCase().includes(lowercasedSearchTerm) : false;
        const idMatch = hospital.HospitalId ? hospital.HospitalId.toString().includes(lowercasedSearchTerm) : false;
        return nameMatch || idMatch;
      });
    
      const indexOfLastItem = activePage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const currentItems = filteredHospitals.slice(indexOfFirstItem, indexOfLastItem);

    return(
        <Layout title="List Hospitals" description="List of Hospitals">
        <Loader/>
      <h1 className="mt-4 color-bright">List Hospitals</h1>
      <div className="form-group col-md-4">
        <label htmlFor="searchTerm">Search by Name or Hospital ID</label>
        <input
          type="text"
          id="searchTerm"
          className="form-control input-shadow"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <div className="table-responsive row">
        <table id="listHospitals" cellPadding="0" cellSpacing="0" border="0">
          <thead>
            <tr>
              <th scope="col">Hospital Id</th>
              <th scope="col">Hospital Name</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Permission</th>
              <th scope="col">Edit</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((hospital, i) => (
              <tr key={i}>
                <td>{hospital.HospitalId}</td>
                <td>{hospital.HospitalName}</td>
                <td>{hospital.CEOName}</td>
                <td>{hospital.Email}</td>
                <td><button
                    className={`btn ${hospital.status === 'Yes' ? 'btn-success' : 'btn-danger'}`}
                    onClick={() => handleToggleStatus(hospital.HospitalId, hospital.status)}
                  >
                    {hospital.status}
                  </button></td>
                <td>
                  <Link to={`/update-hospital/${hospital.HospitalId}`} className="btn btn-primary">
                    Edit
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        activePage={activePage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={filteredHospitals.length}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
        itemClass="page-item"
        linkClass="page-link"
      />
    </Layout>
    );
}

export default ListHospitals;