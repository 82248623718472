import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import Loader from "../frontpage/Loader";

const AdminSettings = () => {
  const [formData, setFormData] = useState({
    logoPosition: "left",
    pdfHeaderColor: "",
    pdfFooterColor: "",
    pdfHeaderTextColor: "",
    pdfFooterTextColor: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [hospital, setHospital] = useState("");

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;
        fetch(
          `https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getUserData/${userId}`
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("User data not found");
            }
            return response.json();
          })
          .then((userData) => {
            setHospital(userData.hospital.HospitalId);
          })
          .catch((error) => {
            console.error("Error fetching user data:", error);
            setError("Error fetching user data");
          });
      } else {
        setError("User not authenticated");
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (hospital) {
      axios
        .get(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getSettings/${hospital}`)
        .then((response) => {
          const data = response.data;
          setFormData({
            logoPosition: data.logoPosition || "left",
            pdfHeaderColor: data.pdfHeaderColor || "#ffffff",
            pdfFooterColor: data.pdfFooterColor || "#ffffff",
            pdfHeaderTextColor: data.pdfHeaderTextColor || "#000000",
            pdfFooterTextColor: data.pdfFooterTextColor || "#000000",
          });
        })
        .catch((error) => {
          console.error("Error fetching settings data:", error);
          setError("Failed to load settings data.");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [hospital]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePositionChange = (e) => {
    const position = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      logoPosition: position,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.post(
        `https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/AdminupdateSettings/${hospital}`,
        formData
      );
      setSuccessMessage("Settings updated successfully");
    } catch (error) {
      console.error("Error updating settings:", error);
      setError("Error updating settings");
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="row">
      <Layout>
      <Loader/>
        <h1 style={{ color: "#007bff", marginTop: "5px" }}>Admin Settings</h1>
        <form onSubmit={handleSubmit} className="settingsForm">
          <div className="row">

            <div className="col-md-6">
            <div className="form-group">
            <h4>Logo Position</h4>
              <label>
                <input
                  type="radio"
                  value="left"
                  checked={formData.logoPosition === "left"}
                  onChange={handlePositionChange}
                />
                Left
              </label>
              <label style={{ marginLeft: "10px" }}>
                <input
                  type="radio"
                  value="right"
                  checked={formData.logoPosition === "right"}
                  onChange={handlePositionChange}
                />
                Right
              </label>
            </div>
              <div className="form-group">
                <label>Prescription / Bill Header Background Color:</label>
                <input
                  className="form-control"
                  type="color"
                  name="pdfHeaderColor"
                  value={formData.pdfHeaderColor}
                  onChange={handleChange}
                  style={{ width: "100px" }}
                />
              </div>
              <div className="form-group">
                <label>Prescription / Bill Footer Background Color:</label>
                <input
                  className="form-control"
                  type="color"
                  name="pdfFooterColor"
                  value={formData.pdfFooterColor}
                  onChange={handleChange}
                  style={{ width: "100px" }}
                />
              </div>
              <div className="form-group">
                <label>Prescription / Bill Header Text Color:</label>
                <input
                  className="form-control"
                  type="color"
                  name="pdfHeaderTextColor"
                  value={formData.pdfHeaderTextColor}
                  onChange={handleChange}
                  style={{ width: "100px" }}
                />
              </div>
              <div className="form-group">
                <label>Prescription / Bill Footer Text Color:</label>
                <input
                  className="form-control"
                  type="color"
                  name="pdfFooterTextColor"
                  value={formData.pdfFooterTextColor}
                  onChange={handleChange}
                  style={{ width: "100px" }}
                />
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary text-center"
            style={{ marginTop: "10px" }}
          >
            Update
          </button>
        </form>
        {successMessage && (
          <div className="alert alert-success mt-3">{successMessage}</div>
        )}
        {error && <div className="alert alert-danger mt-3">{error}</div>}
      </Layout>
    </div>
  );
};

export default AdminSettings;
