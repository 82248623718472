import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import InvoiceItem from './QuickInvoiceItem';
import InvoiceModal from './QuickInvoiceModel';
import InputGroup from 'react-bootstrap/InputGroup';
import Layout from "../core/Layout";
import Select from 'react-select';
import axios from 'axios';
import Loader from '../frontpage/Loader';

class InvoiceForm2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      currency: '₹',
      currentDate: new Date().toLocaleDateString(),
      invoiceNumber: 1,
      dateOfIssue: '',
      billTo: '',
      billToEmail: '',
      billToAddress: '',
      billFrom: '',
      billFromEmail: '',
      billFromAddress: '',
      notes: '',
      total: '0.00',
      subTotal: '0.00',
      taxRate: '',
      taxAmount: '0.00',
      discountRate: '',
      discountAmount: '0.00',
      items: [{
        id: 0,
        name: 'Rxtor 10 Tablet',
        description: '',
        price: '2000.00',
        quantity: 5
      },{id:1,name:'Rebozen-SL 75mg/20mg Capsule',description:'',price:'100.00',quantity: '5'},{id:3,name:'Rivamer 3 Capsule',description:'',price:'100.00',quantity: '2'}],
      patientOptions: [],
      hospitalOptions: []
    };

    this.editField = this.editField.bind(this);
    this.handleAddEvent = this.handleAddEvent.bind(this);
    this.handleRowDel = this.handleRowDel.bind(this);
    this.onItemizedItemEdit = this.onItemizedItemEdit.bind(this);
    this.handleCalculateTotal = this.handleCalculateTotal.bind(this);
    this.onCurrencyChange = this.onCurrencyChange.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.fetchPatients = this.fetchPatients.bind(this);
    this.handlePatientSelect = this.handlePatientSelect.bind(this);
    this.handleHospitalSelect = this.handleHospitalSelect.bind(this);
  }

  componentDidMount() {
    this.handleCalculateTotal();
    this.fetchPatients();
    this.fetchHospitals();
  }

  async fetchPatients() {
    try {
      const response = await axios.get('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/createbill/get-patients');
      const patientOptions = response.data.map(patient => ({
        value: patient.patientId,
        label: `${patient.patientId} - ${patient.Name}`,
        email: patient.Email,
        address: patient.Address,
        phone: patient.PhoneNo,
        data: patient
      }));
      this.setState({ patientOptions });
    } catch (error) {
      console.error("There was an error fetching the patients!", error);
    }
  }

  async fetchHospitals() {
    try {
      const response = await axios.get('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/createbill/get-hospitals');
      const hospitalOptions = response.data.map(hospital => ({
        value: hospital.hospitalId,
        label: `${hospital.hospitalName}`,
        email: hospital.emailAddress,
        address: hospital.billingAddress,
        phone: hospital.phoneNumber,
        data: hospital
      }));
      this.setState({ hospitalOptions });
    } catch (error) {
      console.error("There was an error fetching the hospitals!", error);
    }
  }

  handlePatientSelect(selectedOption) {
    const { value , label, email, address, data, phone} = selectedOption;

    const billingAmount = parseFloat(data?.BillingAmount || 0);
    this.setState({
      billTo: label,
      billToEmail: email,
      billToAddress: address,
      billToPhone: phone,
      billToData: data,
      subTotal: billingAmount.toFixed(2), // Update subtotal with billing amount
      patientId: value,
    }, this.handleCalculateTotal); // Recalculate total
  }

  handleHospitalSelect(selectedOption) {
    const { label, email, address, phone} = selectedOption;

    this.setState({
      billFrom: label,
      billFromEmail: email,
      billFromAddress: address,
      billFromPhone: phone,
    });
  }

  handleRowDel(items) {
    const updatedItems = this.state.items.filter(item => item.id !== items.id);
    this.setState({ items: updatedItems }, this.handleCalculateTotal);
  }

  handleAddEvent() {
    const id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
    const newItem = {
      id: id,
      name: '',
      price: '500.00',
      description: '',
      quantity: 2
    };
    this.setState(
      prevState => ({ items: [...prevState.items, newItem] }),
      this.handleCalculateTotal
    );
  }

  handleCalculateTotal() {
    const billingAmount = parseFloat(this.state.subTotal || 0);

    const taxRate = parseFloat(this.state.taxRate || 0);
    const discountRate = parseFloat(this.state.discountRate || 0);

    const taxAmount = parseFloat((billingAmount * (taxRate / 100)).toFixed(2));
    const discountAmount = parseFloat((billingAmount * (discountRate / 100)).toFixed(2));
    const total = parseFloat((billingAmount - discountAmount + taxAmount).toFixed(2));

    this.setState({
        taxAmount: taxAmount.toFixed(2),
        discountAmount: discountAmount.toFixed(2),
        total: total.toFixed(2)
    });
}

  onItemizedItemEdit(evt) {
    const { id, name, value } = evt.target;
    const updatedItems = this.state.items.map(item => {
      if (item.id === id) {
        return { ...item, [name]: value };
      }
      return item;
    });
    this.setState({ items: updatedItems }, this.handleCalculateTotal);
  }

  editField(event) {
    this.setState({ [event.target.name]: event.target.value }, this.handleCalculateTotal);
  }

  onCurrencyChange(event) {
    this.setState({ currency: event.target.value });
  }

  openModal(event) {
    event.preventDefault();
    this.handleCalculateTotal();
    this.setState({ isOpen: true });
  }

  closeModal() {
    this.setState({ isOpen: false });
  }
  render() {
    return (
        <Layout title="List Prescriptions" className="container-fluid">
        <Loader/>
         <h1 className="mt-4 color-bright">Generate Quick Bill</h1>
    <Form onSubmit={this.openModal}>
      <Row>
        <Col md={8} lg={9}>
          <Card className="p-4 p-xl-5 my-3 my-xl-4">
            <div className="d-flex flex-row align-items-start justify-content-between mb-3">
              <div class="d-flex flex-column">
                <div className="d-flex flex-column">
                  <div class="mb-2">
                    <span className="fw-bold">Current&nbsp;Date:&nbsp;</span>
                    <span className="current-date">{new Date().toLocaleDateString()}</span>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <span className="fw-bold d-block me-2">Due&nbsp;Date:</span>
                  <Form.Control type="date" value={this.state.dateOfIssue} name={"dateOfIssue"} onChange={(event) => this.editField(event)} style={{
                      maxWidth: '150px'
                    }} required="required"/>
                </div>
              </div>
              <div className="d-flex flex-row align-items-center">
                <span className="fw-bold me-2">Invoice&nbsp;Number:&nbsp;</span>
                <Form.Control type="number" value={this.state.invoiceNumber} name={"invoiceNumber"} onChange={(event) => this.editField(event)} min="1" style={{
                    maxWidth: '70px'
                  }} required="required"/>
              </div>
            </div>
            <hr className="my-4"/>
            <Row className="mb-5">
              <Col>
                <Form.Label className="fw-bold">Bill to:</Form.Label>
                {/* <Select
                      options={this.state.patientOptions}
                      onChange={this.handlePatientSelect}
                      placeholder="Search for a patient..."
                      className="my-2"
                    /> */}
                <Form.Control placeholder={"Who is this invoice to?"} rows={3} value={this.state.billTo} type="text" name="billTo" className="my-2" onChange={(event) => this.editField(event)} autoComplete="name" required="required"/>
                <Form.Control placeholder={"Billing Phone number"} value={this.state.billToPhone} type="text" name="billToPhone" className="my-2" autoComplete="phone" onChange={(event) => this.editField(event)} required="required"/>
                <Form.Control placeholder={"Email address"} value={this.state.billToEmail} type="email" name="billToEmail" className="my-2" onChange={(event) => this.editField(event)} autoComplete="email" required="required"/>
                <Form.Control placeholder={"Billing address"} value={this.state.billToAddress} type="text" name="billToAddress" className="my-2" autoComplete="address" onChange={(event) => this.editField(event)} required="required"/>
               
              </Col>
              <Col>
                <Form.Label className="fw-bold">Bill from:</Form.Label>
                <Select
                      options={this.state.hospitalOptions}
                      onChange={this.handleHospitalSelect}
                      placeholder="Search for a hospital..."
                      className="my-2"
                    />
                <Form.Control placeholder={"Who is this invoice from?"} rows={3} value={this.state.billFrom} type="text" name="billFrom" className="my-2" onChange={(event) => this.editField(event)} autoComplete="name" required="required"/>
                <Form.Control placeholder={"Phone Number"} value={this.state.billFromPhone} type="email" name="billFromPhone" className="my-2" onChange={(event) => this.editField(event)} autoComplete="phone" required="required"/>
                <Form.Control placeholder={"Email address"} value={this.state.billFromEmail} type="email" name="billFromEmail" className="my-2" onChange={(event) => this.editField(event)} autoComplete="email" required="required"/>
                <Form.Control placeholder={"Billing address"} value={this.state.billFromAddress} type="text" name="billFromAddress" className="my-2" autoComplete="address" onChange={(event) => this.editField(event)} required="required"/>
              </Col>
            </Row>
            <InvoiceItem onItemizedItemEdit={this.onItemizedItemEdit.bind(this)} onRowAdd={this.handleAddEvent.bind(this)} onRowDel={this.handleRowDel.bind(this)} currency={this.state.currency} items={this.state.items}/>
                        <Row className="mt-4 justify-content-end">
                  <Col lg={6}>
                      <div className="d-flex flex-row align-items-start justify-content-between">
                          <span className="fw-bold">Subtotal:</span>
                          <span>{this.state.currency}{this.state.subTotal}</span>
                      </div>
                      <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                          <span className="fw-bold">Discount:</span>
                          <span>
                              <span className="small">({this.state.discountRate || 0}%)</span>
                              {this.state.currency}{this.state.discountAmount || '0.00'}
                          </span>
                      </div>
                      <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                          <span className="fw-bold">Tax:</span>
                          <span>
                              <span className="small">({this.state.taxRate || 0}%)</span>
                              {this.state.currency}{this.state.taxAmount || '0.00'}
                          </span>
                      </div>
                      <hr/>
                      <div className="d-flex flex-row align-items-start justify-content-between" style={{ fontSize: '1.125rem' }}>
                          <span className="fw-bold">Total:</span>
                          <span className="fw-bold">{this.state.currency}{this.state.total || '0.00'}</span>
                      </div>
                  </Col>
              </Row>
            <hr className="my-4"/>
            <Form.Label className="fw-bold">Notes:</Form.Label>
            <Form.Control placeholder="Happy Medicial" name="notes" value={this.state.notes} onChange={(event) => this.editField(event)} as="textarea" className="my-2" rows={1}/>
          </Card>
        </Col>
        <Col md={4} lg={3}>
          <div className="sticky-top pt-md-3 pt-xl-4">
            <Button variant="primary" type="submit" className="d-block w-100">Review Invoice</Button>
           <InvoiceModal showModal={this.state.isOpen} closeModal={this.closeModal} info={this.state} items={this.state.items} currency={this.state.currency} subTotal={this.state.subTotal} taxAmmount={this.state.taxAmmount} discountAmmount={this.state.discountAmmount} total={this.state.total} patientId={this.state.patientId}/>
            <Form.Group className="mb-3">
              <Form.Label className="fw-bold">Currency:</Form.Label>
              <Form.Select onChange={event => this.onCurrencyChange({currency: event.target.value})} className="btn btn-light my-1" aria-label="Change Currency">
                <option value="₹">INR (Indian Rupee)</option>
                <option value="$">USD (United States Dollar)</option>
                <option value="£">GBP (British Pound Sterling)</option>
                <option value="¥">JPY (Japanese Yen)</option>
                <option value="$">CAD (Canadian Dollar)</option>
                <option value="$">AUD (Australian Dollar)</option>
                <option value="$">SGD (Signapore Dollar)</option>
                <option value="¥">CNY (Chinese Renminbi)</option>
                <option value="₿">BTC (Bitcoin)</option>
              </Form.Select>
            </Form.Group>
                        <Form.Group className="my-3">
                <Form.Label className="fw-bold">Tax rate:</Form.Label>
                <InputGroup className="my-1 flex-nowrap">
                    <Form.Control name="taxRate" type="number" value={this.state.taxRate} onChange={this.editField} className="bg-white border" placeholder="0.0" min="0.00" step="0.01" max="100.00" />
                    <InputGroup.Text className="bg-light fw-bold text-secondary small">%</InputGroup.Text>
                </InputGroup>
            </Form.Group>
            <Form.Group className="my-3">
                <Form.Label className="fw-bold">Discount rate:</Form.Label>
                <InputGroup className="my-1 flex-nowrap">
                    <Form.Control name="discountRate" type="number" value={this.state.discountRate} onChange={this.editField} className="bg-white border" placeholder="0.0" min="0.00" step="0.01" max="100.00" />
                    <InputGroup.Text className="bg-light fw-bold text-secondary small">%</InputGroup.Text>
                </InputGroup>
            </Form.Group>

          </div>
        </Col>
      </Row>
    </Form>
    </Layout>)
  }
}

export default InvoiceForm2;