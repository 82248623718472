import React, { useEffect, useState, Fragment } from "react";
import Layout from "../core/Layout";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from 'axios';
import Pagination from "react-js-pagination";
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import Loader from "../frontpage/Loader";

const ListExpenses = () => {
  const deleteHandler = async (expenseId) => {
    if(window.confirm("Are you sure you want to delete this expenses?")){
      try {
        await axios.delete(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/delete-expense`,{
          data: {
            expenseId,
            HospitalId: formData1.HospitalId
          }
        });
        // After successful deletion, update the expenses state by filtering out the deleted expense
        setExpenses(expenses.filter(expense => expense.expenseId !==expenseId));
      
      } catch (error) {
        console.error('Error deleting expense: ', error);
      }
    }
  };

  
  
  const[formData1, setFormData1]= useState({
    userId:"",
    HospitalName:"",
  })

  const[HospitalName,setHospitalName]=useState("");

  useEffect(()=>{
    const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;

        setFormData1((prevData) => ({
          ...prevData,
          userId: userId,
          HospitalName: HospitalName,
        }));

        try {
          const response = await axios.get(
            `https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getHospitalName/${userId}`
          );
          const addressData = response.data;
          setFormData1((prevData) => ({
            ...prevData,
            HospitalName: addressData.HospitalName || " ",
            HospitalId: addressData.HospitalId || " ",
          }));
        } catch (error) {
          console.error("Error fetching user data:", error);
         
        } finally {
          
        }
      } else {
       
      }
    });

    return () => unsubscribe();
  }, []);

  const [expenses, setExpenses] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const response = await axios.get('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/get-expenses',{params: { HospitalId: formData1.HospitalId }});
        setExpenses(response.data);
      } catch (error) {
        console.error('Error fetching expenses: ', error);
      }
    };
    if(formData1.HospitalId){
    fetchExpenses();
    }
  }, [formData1.HospitalId]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Handle search input changes
  };

  const filteredExpenses = expenses.filter((expense) => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const nameMatch = expense.PatientName.toLowerCase().includes(lowercasedSearchTerm);
    const idMatch = expense.patientId.toString().includes(lowercasedSearchTerm);
    const expenseIdMatch = expense.expenseId.toString().includes(lowercasedSearchTerm);
    const totalAmountMatch = expense.totalAmount.toString().includes(lowercasedSearchTerm);
    return nameMatch || idMatch || expenseIdMatch || totalAmountMatch;
  });

  // Calculate current items
  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredExpenses.slice(indexOfFirstItem, indexOfLastItem);
  
  return (
    <Layout
      title="Profile"
      description="list treatment categories"
      className="container-fluid"
    >
    <Loader/>
     <h1 className="mt-4 color-bright">Expense Management Details</h1>
      <h4>
        <Link to="/add-expenses" className="btn btn-outline-primary">
          Add Expense
        </Link>
      </h4><br></br>

      <div className="form-group col-md-4">
        <label htmlFor="searchTerm">Search by Name, Patient ID, Expense ID, or Total Amount</label>
        <input
          type="text"
          id="searchTerm"
          className="form-control input-shadow"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
        <div class="tbl-header table-responsive">
            <table id="listPatients1"  cellpadding="0" cellspacing="0" border="0">
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">name</th>
                  <th scope="col">Patient ID</th>
                  <th scope="col">Total Amount</th>
                  <th scope="col">Paid</th>
                  <th scope="col">Balance</th>
                  {/* <th scope="col">Description</th> */}
                  <th scope="col">Date</th>
                  {/* <th scope="col">Status</th> */}
                  <th scope="col">Edit</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
             
              <tbody>
                {expenses &&
                 currentItems.map((expense, i) => (
                    <tr key={i}>
                      <Fragment>
                      <th scope="row">{expense.expenseId}</th>
                      <td>{expense.PatientName}</td>
                      <td>{expense.patientId}</td>
                      <td>{expense.totalAmount}</td>

                      <td>{[expense.Paid , expense.paid]}</td>
                      <td>{[expense.Balance, expense.balance]}</td>
                      {/* <td>{expense.description}</td> */}
                      <td>{expense.date}</td>
                      <td>
                        <Link to={`/update-expenses/${expense.expenseId}`}>
                          <i className="bi bi-pencil-square" />
                        </Link>
                      </td>
                      <td>
                        <i
                          className="bi bi-trash"
                          onClick={() => deleteHandler(expense.expenseId)}
                        />
                      </td>
                      </Fragment>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
      
      <Pagination
        activePage={activePage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={expenses.length}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
        itemClass="page-item"
        linkClass="page-link"
      />
    </Layout>
  );
};

export default ListExpenses;
