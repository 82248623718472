import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { useParams } from 'react-router-dom';
import moment from "moment";
import Loader from "../frontpage/Loader";
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { format } from 'date-fns';

const UpdatePatientProfile = () => {
  const { patientId } = useParams();

  
  const[formData1, setFormData1]= useState({
    userId:"",
    HospitalName:"",
  })

  const[HospitalName,setHospitalName]=useState("");

  useEffect(()=>{
    const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;

        setFormData1((prevData) => ({
          ...prevData,
          userId: userId,
          HospitalName: HospitalName,
        }));

        try {
          const response = await axios.get(
            `https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getHospitalName/${userId}`
          );
          const addressData = response.data;
          setFormData1((prevData) => ({
            ...prevData,
            HospitalName: addressData.HospitalName || " ",
            HospitalId: addressData.HospitalId || " ",
          }));
        } catch (error) {
          console.error("Error fetching user data:", error);
         
        } finally {
          
        }
      } else {
       
      }
    });

    return () => unsubscribe();
  }, []);

  const [patientData, setPatientData] = useState({
    Name: "",
    patientId: "",
    DateofAdmission: "",
    Address: "",
    PhoneNo: "",
    Dateofbirth: "",
    Residence: "",
    Email: "",
    Guardian: "",
    Relation: "",
    Gender: "Male",
    PatientStatus: "Active",
    InPatientOutpatient: "Inpatient",
    doctorName: "",
    UploadPhoto: "",
    Age: "",
    FoodHabits: "",
    MaritalStatus: "",
    Height: "",
    Weight: "",
    BMI: "",
    Dominance: "",
    Occupation: "",
    City: "",
    Pincode: "",
    AadhaarNumber: "",
    ClinicReference: "",
    ReferenceSource: "",
    Diabetes: "",
    AnyAllergy: "",
    ContactPerson: "",
    ContactPersonNumber: "",


  });

  const [error, setError] = useState(null);

  const [occupationOptions, setOccupationOptions] = useState([]);
  const [inpatientOutpatientOptions, setInpatientOutpatientOptions] = useState([]);
  const [patientStatusOptions, setPatientStatusOptions] = useState([]);


  useEffect(() => {
    console.log("Extracted patientId:", patientId);
    if (patientId) {
      const fetchPatientData = async () => {
        try {
          const response = await axios.get(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/updatepatient/${patientId}`,{
            params: {
            HospitalId: formData1.HospitalId 
          }
        });
          const expenseData = response.data;

          setPatientData({
            ...expenseData,
            Name: expenseData.Name || "",
            DateofAdmission: expenseData.DateofAdmission ? moment(expenseData.DateofAdmission, "YYYY-MM-DD").toDate() : new Date(),
            Address: expenseData.Address || "",
            PhoneNo: expenseData.PhoneNo || "",
            Dateofbirth: expenseData.Dateofbirth ? moment(expenseData.Dateofbirth, "dd/MM/YYYY").toDate() : new Date(),
            Residence: expenseData.Residence || "",
            Email: expenseData.Email || "",
            Guardian: expenseData.Guardian || "",
            Relation: expenseData.Relation || "",
            Gender: expenseData.Gender || "",
            PatientStatus: expenseData.PatientStatus || "",
            InPatientOutpatient: expenseData.InPatientOutpatient || "",
            doctorName: expenseData.doctorName || "",
            UploadPhoto: expenseData.UploadPhoto || "",
            Age: expenseData.Age || "",
            FoodHabits: expenseData.FoodHabits || "",
            MaritalStatus: expenseData.MaritalStatus || "",
            Height: expenseData.Height || "",
            Weight: expenseData.Weight || "",
            BMI: expenseData.BMI || "",
            Dominance: expenseData.Dominance || "",
            Occupation: expenseData.Occupation || "",
            City: expenseData.City || "",
            Pincode: expenseData.Pincode || "",
            AadhaarNumber: expenseData.AadhaarNumber || "",
            ClinicReference: expenseData.ClinicReference || "",
            ReferenceSource: expenseData.ReferenceSource || "",
            Diabetes: expenseData.Diabetes || "",
            AnyAllergy: expenseData.AnyAllergy || "",
            ContactPerson: expenseData.ContactPerson || "",
            ContactPersonNumber: expenseData.ContactPersonNumber || "",
          });
        } catch (error) {
          setError(error);
          console.error("Error fetching patient data", error);
        }
      };

      const fetchOccupations = async () => {
        try {
          const response = await axios.get(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getOccupations/${formData1.HospitalId}`);
          const extraFields = response.data;

          setOccupationOptions(extraFields.Occupation || []);
          setInpatientOutpatientOptions(extraFields.InpatientOutpatient || []);
          setPatientStatusOptions(extraFields.PatientStatus || []);
        } catch (error) {
          console.error("Error fetching occupations:", error);
        }
      };
    
      if (patientId && formData1.HospitalId) {

      fetchPatientData();
      fetchOccupations();
      }
    }
  }, [patientId, formData1.HospitalId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPatientData({ ...patientData, [name]: value });
  };

  const handleDateChange = (date, name) => {
    setPatientData({ ...patientData, [name]: date });
  };


  const handleDoctorSelect = (doctorName) => {
    setPatientData((prevData) => ({
      ...prevData,
      doctorName
    }));
    setShowDropdown(false);
  };

  
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const submitHandler = async (e) => {
    e.preventDefault();
    try {

      const formattedDateofAdmission = format(new Date(patientData.DateofAdmission), 'yyyy-MM-dd');
      const formattedDateOfBirth = format(patientData.Dateofbirth, 'dd/MM/yyyy');
      const updatedPatientData = {
        ...patientData,
        DateofAdmission: formattedDateofAdmission,
        HospitalId: formData1.HospitalId,
        Dateofbirth: formattedDateOfBirth
      };

      await axios.put(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/putpatient/${patientId}`, updatedPatientData);

      
      setSuccessMessage('Patient updated successfully');
      setErrorMessage('');
    } catch (error) {
      setErrorMessage('Error updating patient');
      setSuccessMessage('');
      console.error("Error updating patient data", error);
    }
  };

  const [doctorName1, setDoctorName1] = useState('');
  const [doctors, setDoctors] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const [photo, setPhoto] = useState(null); // State for storing uploaded photo data
  const [photoName, setPhotoName] = useState(''); // State for storing uploaded photo name
  const [uploading, setUploading] = useState(false); // State for indicating if upload is in progress

  const handleFileUpload = (e) => {
    
    const file = e.target.files[0];
    setPhoto(file); 
    setPhotoName(file.name); 
   
  };

  const hospitalId= formData1.HospitalId;
  
  useEffect(() => {
    // Fetch doctor details from the backend API
    const fetchDoctors = async () => {
      try {
        
        const response = await axios.get(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getdoctorsname/${hospitalId}`);
        console.log("response for doctor name:", response);
        setDoctors(response.data);
      } catch (error) {
        console.error("Error fetching doctors:", error);
      }
    };

    if (hospitalId) {
      fetchDoctors();
    }
  }, [hospitalId]);


  return (
    <Layout>
    <Loader/>
    <h1 className="mt-4 color-bright">Edit Patient Page</h1>
      <div className="containerAddPatient">
        <form onSubmit={submitHandler} className="form-card">
          <div className="formtype">
            <div className="card Addpatientcard mb-4">
              <div className="card-header" style={{ backgroundColor: "lightblue" }}>
                <h5>Personal Details</h5>
              </div>
              <div className="card-body">
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold">Patient ID</label>
                    <input
                      type="text"
                      className="form-control input-p"
                      name="patientId"
                      value={patientData.patientId}
                      onChange={handleChange}
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold">Name</label>
                    <input
                      type="text"
                      className="form-control input-p"
                      name="Name"
                      value={patientData.Name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold">Address</label>
                    <input
                      type="text"
                      className="form-control input-p"
                      name="Address"
                      value={patientData.Address}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold">Gender</label>
                    <select
                      className="form-control"
                      name="Gender"
                      value={patientData.Gender}
                      onChange={handleChange}
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold">Phone No</label>
                    <input
                      type="text"
                      className="form-control input-p"
                      name="PhoneNo"
                      value={patientData.PhoneNo}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold">Email</label>
                    <input
                      type="email"
                      className="form-control input-p"
                      name="Email"
                      value={patientData.Email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold">Guardian</label>
                    <input
                      type="text"
                      className="form-control input-p"
                      name="Guardian"
                      value={patientData.Guardian}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold">Relation</label>
                    <input
                      type="text"
                      className="form-control input-p"
                      name="Relation"
                      value={patientData.Relation}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold">Age</label>
                    <input
                      type="text"
                      className="form-control input-p"
                      name="Relation"
                      value={patientData.Age}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold">Food Habits</label>
                    <div className="form-check">
                      <input 
                        className="form-check-input" 
                        type="radio" 
                        id="foodVeg" 
                        name="FoodHabits" 
                        value="Veg" 
                        checked={patientData.FoodHabits === 'Veg'} 
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="foodVeg">
                        Veg
                      </label>
                    </div>
                    <div className="form-check">
                      <input 
                        className="form-check-input" 
                        type="radio" 
                        id="foodNonVeg" 
                        name="FoodHabits" 
                        value="Non-veg" 
                        checked={patientData.FoodHabits === 'Non-veg'} 
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="foodNonVeg">
                        Non-veg
                      </label>
                    </div>
                    <div className="form-check">
                      <input 
                        className="form-check-input" 
                        type="radio" 
                        id="foodVegetarian" 
                        name="FoodHabits" 
                        value="Vegetarian" 
                        checked={patientData.FoodHabits === 'Vegetarian'} 
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="foodVegetarian">
                        Vegetarian
                      </label>
                    </div>
                  </div>
                
                <div className="form-group col-md-6">
                  <label className="font-weight-bold">Marital Status</label>
                  <div className="form-check">
                    <input 
                      type="radio" 
                      id="maritalStatusSingle" 
                      name="MaritalStatus" 
                      value="Single" 
                      checked={patientData.MaritalStatus === 'Single'} 
                      onChange={handleChange} 
                      className="form-check-input" 
                    />
                    <label className="form-check-label" htmlFor="maritalStatusSingle">Single</label>
                  </div>
                  <div className="form-check">
                    <input 
                      type="radio" 
                      id="maritalStatusMarried" 
                      name="MaritalStatus" 
                      value="Married" 
                      checked={patientData.MaritalStatus === 'Married'} 
                      onChange={handleChange} 
                      className="form-check-input" 
                    />
                    <label className="form-check-label" htmlFor="maritalStatusMarried">Married</label>
                  </div>
                  <div className="form-check">
                    <input 
                      type="radio" 
                      id="maritalStatusDivorced" 
                      name="MaritalStatus" 
                      value="Divorced" 
                      checked={patientData.MaritalStatus === 'Divorced'} 
                      onChange={handleChange} 
                      className="form-check-input" 
                    />
                    <label className="form-check-label" htmlFor="maritalStatusDivorced">Divorced</label>
                  </div>
                  <div className="form-check">
                    <input 
                      type="radio" 
                      id="maritalStatusWidowed" 
                      name="MaritalStatus" 
                      value="Widowed" 
                      checked={patientData.MaritalStatus === 'Widowed'} 
                      onChange={handleChange} 
                      className="form-check-input" 
                    />
                    <label className="form-check-label" htmlFor="maritalStatusWidowed">Widowed</label>
                  </div>
                </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputHeight">Height</label>
                    <input 
                      type="text" 
                      className="form-control input-shadow input-p" 
                      id="inputHeight" 
                      placeholder="Height" 
                      value={patientData.Height} 
                      onChange={handleChange}
                      name="Height"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputWeight">Weight (in Kg)</label>
                    <input 
                      type="text" 
                      className="form-control input-shadow input-p" 
                      id="inputWeight" 
                      placeholder="Weight"  
                      value={patientData.Weight} 
                      onChange={handleChange}
                      name="Weight"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputBMI">BMI</label>
                    <input 
                      type="text" 
                      className="form-control input-shadow input-p" 
                      id="inputBMI" 
                      placeholder="BMI"  
                      value={patientData.BMI} 
                      onChange={handleChange}
                      name="BMI"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputDominance">Dominance (R/L)</label>
                    <input 
                      type="text" 
                      className="form-control input-shadow input-p" 
                      id="inputDominance" 
                      placeholder="Dominance" 
                      value={patientData.Dominance} 
                      onChange={handleChange}
                      name="Dominance"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputOccupation">Occupation</label>
                    <select 
                      className="form-control styled-select drop-down-patient-form" 
                      id="inputOccupation" 
                      value={patientData.Occupation} 
                      onChange={handleChange}
                      name="Occupation"
                    >
                       <option value="">Select Occupation</option>
                    {occupationOptions.map((occupation, index) => (
                      <option key={index} value={occupation}>
                        {occupation}
                      </option>
                    ))}
                    </select>
                  </div>
                  <div className="form-group col-md-4">
                    <label className="font-weight-bold" htmlFor="inputCity">City</label>
                    <input 
                      type="text" 
                      className="form-control input-shadow input-p" 
                      id="inputCity" 
                      placeholder="City"  
                      value={patientData.City} 
                      onChange={handleChange}
                      name="City"
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label className="font-weight-bold" htmlFor="inputPincode">Pincode</label>
                    <input 
                      type="text" 
                      className="form-control input-shadow input-p" 
                      id="inputPincode" 
                      placeholder="Pincode"  
                      value={patientData.Pincode} 
                      onChange={handleChange}
                      name="Pincode"
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label className="font-weight-bold" htmlFor="inputAadhar">Aadhar Number</label>
                    <input 
                      type="text" 
                      className="form-control input-shadow input-p" 
                      id="inputAadhar" 
                      placeholder="Aadhar Number"  
                      value={patientData.AadharNumber} 
                      onChange={handleChange}
                      name="AadharNumber"
                    />
                  </div>
                </div>

              </div>
            </div>
            <div className="card mb-4" style={{ marginLeft: "20px" }}>
              <div className="card-header" style={{ backgroundColor: "lightblue" }}>
                <h5>Official Details</h5>
              </div>
              <div className="card-body">
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold">Registration Date</label>
                    <DatePicker
                      selected={patientData.DateofAdmission}
                      onChange={(date) => handleDateChange(date, "DateofAdmission")}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold">Date of Birth</label>
                    <DatePicker
                      selected={patientData.Dateofbirth}
                      onChange={(date) => handleDateChange(date, "Dateofbirth")}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold">Inpatient/Outpatient</label>
                    <select
                      className="form-control"
                      name="InPatientOutpatient"
                      value={patientData.InpatientOutpatient}
                      onChange={handleChange}
                    >
                      <option value="">Select Inpatient/Outpatient</option>
                      {inpatientOutpatientOptions.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                            <div className="form-group col-md-6">
                <label className="font-weight-bold">Doctor Name</label>
                <select
                  className="form-control input-p"
                  name="doctorName"
                  value={patientData.doctorName}
                  onChange={handleChange}
                >
                  <option value="">Select Doctor Name</option>
                  {doctors.map((doctor) => (
                    <option key={doctor.doctorId} value={doctor.Name}>
                      {doctor.Name}
                    </option>
                  ))}
                </select>
              </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold">Patient Status</label>
                    <select
                      className="form-control"
                      name="PatientStatus"
                      value={patientData.PatientStatus}
                      onChange={handleChange}
                    >
                      <option value="">Select Patient Status</option>
                        {patientStatusOptions.map((status, index) => (
                          <option key={index} value={status}>
                            {status}
                          </option>
                        ))}
                    </select>
                  </div>
                
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputPhoto">Upload Photo</label>
                    <div className="custom-file">
                      <input
                        type="file"
                        className="form-control"
                        id="inputPhoto"
                        onChange={handleFileUpload}
                        name="photo" // Assuming 'photo' is the key in your state for photo data
                      />
                      <small>{photoName}</small>
                    </div>
                    {patientData.UploadPhoto && ( // Check if UploadPhoto URL is present
                      <div style={{width:"100px"}}>
                        <img src={patientData.UploadPhoto} alt="Uploaded" style={{ maxWidth: '100%', marginTop: '10px' }} />
                      </div>
                    )}
                    {uploading && (
                      <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                  </div>

                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputClinicNumber">Clinic Reference Number</label>
                    <input 
                      type="text" 
                      className="form-control input-shadow input-p" 
                      id="inputClinicNumber" 
                      placeholder="Clinic Number" 
                      value={patientData.ClinicReference} 
                      onChange={handleChange}
                      name="ClinicReference"
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label className="font-weight-bold" htmlFor="inputReferenceSource">Reference Source</label>
                    <select 
                      className="form-control styled-select drop-down-patient-form" 
                      id="inputReferenceSource" 
                      value={patientData.ReferenceSource} 
                      onChange={handleChange}
                      name="ReferenceSource"
                    >
                      <option value="">Select Reference Source</option>
                      <option value="Doctor">Doctor</option>
                      <option value="Patient">Patient</option>
                      <option value="Website">Website</option>
                      <option value="Advertisement">Advertisement</option>
                      <option value="Others">Others</option>
                      <option value="Insurance">Insurance</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputDiabetes">Diabetes</label>
                    <input 
                      type="text" 
                      className="form-control input-shadow input-p" 
                      id="inputDiabetes" 
                      placeholder="Diabetes" 
                      value={patientData.Diabetes} 
                      onChange={handleChange}
                      name="Diabetes"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputAllergy">Any Allergy</label>
                    <input 
                      type="text" 
                      className="form-control input-shadow input-p" 
                      id="inputAllergy" 
                      placeholder="Allergy" 
                      value={patientData.AnyAllergy} 
                      onChange={handleChange}
                      name="AnyAllergy"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputContactPerson">Contact Person</label>
                    <input 
                      type="text" 
                      className="form-control input-shadow input-p" 
                      id="inputContactPerson" 
                      placeholder="Person Name" 
                      value={patientData.ContactPerson} 
                      onChange={handleChange}
                      name="ContactPerson"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputContactPersonNumber">Contact Person Number</label>
                    <input 
                      type="text" 
                      className="form-control input-shadow input-p" 
                      id="inputContactPersonNumber" 
                      placeholder="Person Number" 
                      value={patientData.ContactPersonNumber} 
                      onChange={handleChange}
                      name="ContactPersonNumber"
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
          <button type="submit" className="btn btn-primary save-p" style={{ width: "20%", textAlign: "center", marginLeft: "35%" }}>Save</button>
        </form>
        {successMessage && (
        <div className="alert alert-success mt-3">
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="alert alert-danger mt-3">
          {errorMessage}
        </div>
      )}
      </div>
    </Layout>
  );
};

export default UpdatePatientProfile;
