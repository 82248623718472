import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import { OAuthProvider } from "firebase/auth";
import 'firebase/compat/database'; 
import firebase from 'firebase/compat/app';
import { Link , useParams, useNavigate} from "react-router-dom";
import Select from 'react-select';
import Loader from "../frontpage/Loader";

const firebaseConfig = {
  apiKey: "AIzaSyCI59gnHA9AZAANjoy082vht3fCgatchEg",
  authDomain: "hospital-management-syst-7e35d.firebaseapp.com",
  databaseURL: "https://hospital-management-syst-7e35d-default-rtdb.firebaseio.com",
  projectId: "hospital-management-syst-7e35d",
  storageBucket: "hospital-management-syst-7e35d.firebasestorage.app",
  messagingSenderId: "59517358381",
  appId: "1:59517358381:web:96f502e3832ddf1a8fc3a4",
  measurementId: "G-EYLWMBT9SP"
};

const roleMapping = {
  "Super Admin": 1,
  "Admin": 2,
  "Doctor": 3,
  "Accountant": 4,
  "Receptionist": 5,
  "Analyst": 6,
  "Patient": 7
};

const  Profile = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [age, setAge] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [gender, setGender] = useState('');
  const [location, setLocation] = useState('');
  const [hospital, setHospital] = useState('');
  const [role, setRole] = useState('');
  const [roleId, setRoleId] = useState('');
  const [message, setMessage] = useState('');
  const [hospitals, setHospitals] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [status, setStatus] = useState('');
  const [initialRole, setInitialRole] = useState('');
  const navigate = useNavigate(); 

  useEffect(() => {
    // Check if user is already logged in
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
        setTimeout(() => {
          navigate('/signin'); 
      }, 100);
      }
    });
  }, [navigate]);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;
        fetch(`https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/getUserData/${userId}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('User data not found');
          }
          return response.json();
        })
        .then(userData => {
          setFirstName(userData.firstName || '');
          setLastName(userData.lastName || '');
          setEmail(userData.email || '');
          setAge(userData.age || '');
          setPhoneNumber(userData.phoneNumber || '');
          setGender(userData.gender || '');
          setLocation(userData.location || '');
          setHospital({
            value: userData.hospital,
            label: `${userData.hospital.HospitalId} - ${userData.hospital.HospitalName}`
          });
          setRole(userData.role || '');
          setRoleId(roleMapping[userData.role] || '');
          setInitialRole(userData.role || '');
          setStatus(userData.status || 'Failed');
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
          setMessage('Kindly Please Update Your Profile');
        });
    } else {
      setMessage('User not authenticated');
    }
  });

  fetch('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/hospitalname')
  .then(response => response.json())
  .then(data => {
    const hospitalOptions = data
    .filter(hospital => hospital !== undefined && hospital.HospitalId && hospital.HospitalName)
    .map(hospital => ({
      value: hospital,
      label: `${hospital.HospitalId} - ${hospital.HospitalName}`
    }));
    setHospitals(hospitalOptions);
  })
  .catch(error => console.error('Error fetching hospitals:', error));

return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Store form data in localStorage
    localStorage.setItem('profileFormData', JSON.stringify({
      firstName,
      lastName,
      email,
      age,
      phoneNumber,
      gender,
      location,
      hospital,
      role
    }));
  }, [firstName, lastName, email, age, phoneNumber, gender, location, hospital, role]);


  useEffect(() => {
    // Update roleId whenever role changes
    setRoleId(roleMapping[role] || '');
  }, [role]);

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    const userId = firebase.auth().currentUser.uid;

    const updatedStatus = role !== initialRole ? "Failed" : (status || "Failed");


    fetch('https://us-central1-hospital-management-syst-7e35d.cloudfunctions.net/api/updateUserData', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId,
        firstName,
        lastName,
        email,
        age,
        phoneNumber,
        gender,
        location,
        hospital: hospital.value,
        role,
        roleId,
        status: updatedStatus
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Error updating profile');
        }
        return response.text();
      })
      .then(data => {
        setMessage(data);
      })
      .catch(error => {
        console.error('Error updating profile:', error);
        setMessage('Error updating profile');
      });
  };

  return (
    <Layout
      title="Profile"
      description="Update your profile"
      className="container"
    >
    <Loader/>
      <section className="ftco-section">
        <div className="container4">
          <div className="row justify-content-center  pb-5">
            <div className="col-md-7 text-center heading-section ftco-animate">
              <h2 className="mb-2" style={{color: "#007bff"}}>My Profile</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-7">
              <div className="form_container4">
                <form onSubmit={handleUpdateProfile}>
                  <div className="form-group1">
                    <label htmlFor="firstName">First Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group1">
                    <label htmlFor="lastName">Last Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group1">
                    <label htmlFor="email">Email:</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group1">
                    <label htmlFor="age">Age:</label>
                    <input
                      type="number"
                      className="form-control"
                      id="age"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group1">
                    <label htmlFor="phoneNumber">Phone Number:</label>
                    <input
                      type="tel"
                      className="form-control"
                      id="phoneNumber"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group1">
                    <label>Gender:</label>
                    <div className="radio-container">
                      <input
                        type="radio"
                        id="male"
                        name="gender"
                        value="male"
                        checked={gender === 'male'}
                        onChange={(e) => setGender(e.target.value)}
                        required
                      />
                      <label htmlFor="male" className="radio-label">Male</label>
                    </div>
                    <div className="radio-container">
                      <input
                        type="radio"
                        id="female"
                        name="gender"
                        value="female"
                        checked={gender === 'female'}
                        onChange={(e) => setGender(e.target.value)}
                        required
                      />
                      <label htmlFor="female" className="radio-label">Female</label>
                    </div>
                  </div>
                  <div className="form-group1">
                    <label htmlFor="location">Location:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="location"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group1">
                    <label htmlFor="hospital">Hospital:</label>
                    <Select
                      id="hospital"
                      value={hospital}
                      onChange={setHospital}
                      options={hospitals}
                      placeholder="Hospital Name"
                      isSearchable
                      required
                    />
                  </div>
                  <div className="form-group1">
                    <label htmlFor="role">Role:</label>
                    <select
                      className="form-control"
                      id="role"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      required
                    >
                      <option value="">Select Role</option>
                      <option value="Super Admin" disabled>Super Admin</option>
                      <option value="Patient">Patient</option>
                      <option value="Admin">Admin</option>
                      <option value="Accountant">Accountant</option>
                      <option value="Doctor">Doctor</option>
                      <option value="Receptionist">Receptionist</option>
                      <option value="Analyst">Analyst</option>
                    </select>
                  </div><br></br>
                  <button type="submit" className="btn btn-primary">Update Profile</button>
                </form>
                <p>{message}</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="img-box">
                {/* You can place an image here if needed */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Profile;
